@import 'src/modules/shared/_colors.sass'


$module-name: 'referral-practice-info'


.#{$module-name}
    padding-right: 12px

    &__location-container
        padding-right: 6px

    &__chatter-container
        padding-left: 6px

        .chatter-label
            color: $label-gray
            font-size: 12px

        .chatter
            color: $very-soft-black
            margin: 10px 0 0
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap


.phone-custom-field__container
    display: block
    margin-right: 13px
    position: relative
    width: auto
