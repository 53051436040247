@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'


$module-name: scheduling-existing-patient-form

.#{$module-name}
    &--hidden
        display: none

    &__progress
        align-items: center
        display: flex
        height: 145px
        justify-content: center
        padding: 70px
        text-align: center
