// consts
$black: #000000
$lighter-black: #4a4a4a
$medium-black: #242424
$ghostwhite: #f8f8ff
$gray: #808080
$grey: $gray
$dark-gray: #cccccc
$dark-grey: $dark-gray
$lightcoral: #f08080
$lightgray: #d3d3d3
$lightgrey: $lightgray
$lightgreen: #90ee90
$primary-lime: #91c73d
$lightskyblue: #87cefa
$charcoal: #0e1115 // was rgba(0, 0, 0, 0.54), looks like charcoal to me, hex from a color picker used on PST
$red: #ff0000
$white: #ffffff
$dark-white: #efefef
$darker-white: #eaeaea
$cyan: #77cccb

// app specific
$base-blue: #346bc7
$main-blue: #448aff
$main-blue: $base-blue
$main-blue-hover: #5a88d4
$light-alert-blue: #bfd7ff
$dark-alert-blue: #1569f7
$alert-red: #c82323
$new-alert-red: #f5222d
$primary-red: #ca061a
$brand-teal: #47e0cd
$dark-brand-teal: #009688
$dark-gray: #4a4a4a
$dark-grey: $dark-gray
$dark-gray-43: #434656
$dark-grey-43: $dark-gray-43
$darkest-gray: #9b9b9b
$darkest-grey: $darkest-gray
$header-gray: #ebebeb
$border-gray: #dbdbdb
$blue-gray: #61839e
$gray-da: #dadada
$gray-33: #333333
$error-red: #d0021b
$warning-orange: #da7832
$header-gray: #ebebeb
$blue-gray: #61839e
$label-gray: #737373
$last-message: #747474
$light-teal: #eafffc
$missing-item: #cacaca
$sky-blue: #3498db
$sky-blue-light: #c6dcff
$gray-blue: #61839e
$grey-blue: $gray-blue
$text-blue: $base-blue
$soft-black: #303030
$very-soft-black: #4a4a4a
$soft-gray: #8f8f8f
$grey-d9: #d9d9d9
$soft-grey: $soft-gray
$softer-gray: #a9a9a9
$softer-grey: $softer-gray
$light-gray: #d8d8d8
$light-grey: $light-gray
$light-greyish-orange: #fef7e5
$light-gray-blue-ting: #f5f7fa
$grass-green: #6ad128
$green-2a: #2a8500
$softer-gray: #a9a9a9
$softer-grey: $softer-gray
$light-grey: $light-gray
$very-soft-gray: #f0efed
$very-soft-grey: $very-soft-gray
$modal-gray: rgba(151, 151, 151, 0.7)
$modal-grey: $modal-gray
$success-green: #64dd17
$clear-green: #3da50c
$light-green: #7ed321
$very-light-green: #b8e986
$tab-border: #d7d7d7
$tab-fill-light: #fafafa
$tab-fill: #f4f4f4
$shortcuts-white: #f9f9f9
$shortcut-hover: #f2f2f2
$shadow-gray: #778ea8
$shadow-grey: $shadow-gray
$shadow-gray-c7: #c7c4c4
$shadow-grey-c7: $shadow-gray-c7
$pagination-number: $base-blue
$input-border: #cfdae9
$link-blue: $base-blue
$disabled: #999999
$warning: #cca03a
$grey-f5: #f5f5f5
$grey-55: #555555
$grey-57: #575757
$grey-97: #979797
$grey-b9: #b9b9b9
$grey-e8: #e8e8e8
$grey-66: #666666
$grey-69: #696969
$grey-8b: #8b8b8b
$grey-54: #545454
$grey-90: #909090
$grey-47: #474747
$grey-37: #373737
$grey-b0: #b0b0b0

// table
$table-row-hover: #f5f7fa

// fields
$field-fill: #f4f4f4
$field-fill-hover: rgba(240, 240, 240, 0.4)
$field-border: #cfdae9
$label-gray: #737373
$outline-blue: rgba(50, 177, 223, 0.5)

// The shadows are ordered by opacity. From the most transparent one to the most opaque one.
// shadows
$black-shade-6: rgba(0, 0, 0, 0.06)
$black-shade-8: rgba(0, 0, 0, 0.08)
$black-shade-12: rgba(0, 0, 0, 0.12)
$black-shade-14: rgba(0, 0, 0, 0.14)
$black-shade-16: rgba(0, 0, 0, 0.16)
$black-shade-17: rgba(0, 0, 0, 0.17)
$black-shade-20: rgba(0, 0, 0, 0.2)
$black-shade-24: rgba(0, 0, 0, 0.24)
$black-shade-25: rgba(0, 0, 0, 0.25)
$black-shade-26: rgba(0, 0, 0, 0.26)
$black-shade-38: rgba(0, 0, 0, 0.38)
$black-shade-40: rgba(0, 0, 0, 0.4)
$black-shade-50: rgba(0, 0, 0, 0.5)
$black-shade-54: rgba(0, 0, 0, 0.54)
$black-shade-60: rgba(0, 0, 0, 0.6)
$black-shade-62: rgba(0, 0, 0, 0.62)
$black-shade-66: rgba(0, 0, 0, 0.66)
$black-shade-70: rgba(0, 0, 0, 0.7)
$black-shade-80: rgba(0, 0, 0, 0.8)
$black-shade-87: rgba(0, 0, 0, 0.87)
$black-transparent: rgba(255, 255, 255, 0)
$white-shade-50: rgba(255, 255, 255, 0.5)
$white-shade-70: rgba(255, 255, 255, 0.7)
$white-shade-80: rgba(255, 255, 255, 0.8)
$white-shade-70: rgba(255, 255, 255, 0.7)
$white-shade-90: rgba(255, 255, 255, 0.9)
$gray-shade-40: rgba($tab-border, 0.4)
$hover-blue-button-shade: rgba(52, 107, 199, 0.04)
$primary-hover-bg: darken($main-blue, 10)

// gradients
$grey-gradient: linear-gradient(180deg, $shadow-gray-c7 0%, $black-transparent 100%)
$blue-gradient: linear-gradient(329deg, $cyan 0%, $text-blue 100%)

// scheduling-configuration
$free-slot-color: #b8e986
$booked-slot-color: #f0efed
$selected-slot-color: #eee8a9
$invalid-slot-color: #ffcb00
$no-data-red: #c82323
$weekly-view-dark-blue: #142850
$unavailable-slot-color: #de8d8e

// vyne-colors
$primary-lime: #91c73d
$grey-primary: #333333
$primary-background: #f9fcff
$character-primary: #4e4e4f
$character-secondary: #87878a
$character-warning: #faad14
$character-disabled: #bfbfbf
$secondary-tint: #d1dbe9
$foundation-border: #d9d9d9
$foundation-border-2: #f0f0f0
$foundation-background: #f5f5f5
$foundation-background-2: #fafcff
$foundation-background-3: #f2f2f2
$gray-77: #777777
