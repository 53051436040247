@import 'src/modules/shared/_colors.sass'


.referral-header
    background-color: $very-soft-gray
    padding: 40px 0 10px 40px

    .title-wrapper
        display: flex
        flex-direction: column
        justify-content: flex-end

    .title
        font-size: 24px

    .datetime-status
        align-items: baseline
        display: flex

        .referral-date
            font-size: 14px
            margin: 3px 90px 0 0

        .referral-status
            color: $very-soft-black
