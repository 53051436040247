@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_widget.sass'


.amplify-metrics
    .actions
        margin-bottom: 20px

        .chat-alert-manager,
        .chat-history,
        .details,
        .new-referral,
        .notification-manager,
        .test-referral,
        .web-code-manager,
        .chat-alert-manager
            background: $soft-black
            border-radius: 2px
            color: $white
            font-size: 14px
            letter-spacing: 0.5px
            margin: 2px
            padding: 4px 12px
            text-align: center
            text-transform: uppercase

            .icon
                color: $white
                font-size: 18px
                padding-right: 8px

            .circular-progress
                color: $white
                display: flex
                padding-right: 8px

            &:hover
                background: $dark-gray

        .disabled
            color: $soft-grey

            .icon
                color: $soft-grey

        .inactive
            background: $gray-da

            &:hover
                background: $gray-da

    .snackbar
        border-radius: 0
        margin-bottom: 10px

    .webcode-icon
        align-items: center
        display: flex
        height: 25px
        padding-right: 8px

.circular-progress-loader-wrapper
    height: 50px

.circular-progress-loader
    left: calc(50% - 25px)
    position: absolute
    top: calc(50% - 25px)

    .circular-progress-spinner
        color: $main-blue
