@import 'src/modules/shared/_colors.sass'
$softened-black: rgba($black, 0.45)

.pst-password-reset
    background-color: $white
    background-size: auto 100%
    bottom: 0
    display: flex
    flex: 1
    left: 0
    max-width: initial !important // sass-lint:disable-line no-important
    position: absolute
    right: 0
    top: 0

    .container
        display: flex
        flex: 1
        flex-direction: column
        margin: 32px 32px 0

        .content
            display: flex
            flex-direction: column
            margin: 0 auto
            max-width: 450px
            width: 60%

        .banner
            align-items: center
            display: flex
            justify-content: center
            margin-bottom: 20px
            margin-top: 30px

    .error
        align-items: center
        color: $error-red
        display: flex
        font-size: 13px
        height: fit-content
        justify-content: center
        margin-bottom: 10px
        min-height: 30px
        text-align: center

    .form
        background: $white
        display: flex
        flex: 1 1 0%
        flex-direction: column
        flex-grow: 1
        max-height: 350px

        .header
            background-color: $main-blue
            border-radius: 6px 6px 0 0
            padding: 16px 30px

            .text
                color: $white
                font-family: Ubuntu
                font-size: 18px
                font-weight: bold

        .fields
            border: 1px solid $black-shade-24
            border-radius: 0 0 6px 6px
            border-top: 0

        .field
            padding: 15px 30px 0

            .text
                color: $soft-black
                font-family: Roboto
                font-size: 16px
                font-weight: 300
                line-height: 1.38

        .submit-field
            display: flex
            flex-direction: row
            justify-content: space-around
            margin: 42px 0 20px

            .submit-button
                align-items: center
                background-color: $main-blue
                border: 0
                border-radius: 2px
                cursor: pointer
                display: flex
                flex-shrink: 0
                height: 36px
                justify-content: center
                max-height: 80px
                width: 192px

                &:hover
                    background-color: $primary-hover-bg

                &:disabled
                    background-color: $lightgray
                    cursor: not-allowed

                .text
                    color: $white
                    flex: 1
                    font-family: Roboto
                    font-size: 16px
                    font-weight: 500
                    text-align: center
                    text-transform: uppercase

    .version-text
        bottom: 5px
        color: $soft-gray
        font-size: 80%
        left: 50%
        position: absolute
        transform: translateX(-50%)

        &:hover
            color: $soft-black

    .copyright-notice
        bottom: 44px
        color: $character-primary
        font-size: 12px
        left: 0
        position: fixed
        right: 0
        text-align: center
