@import 'src/modules/shared/_core.sass'

.sdr-and-ae-content
    border-bottom: 1px solid $gray

    h2
        color: $dark-gray
        font-weight: 500
        margin-bottom: 15px

    .sdr-ae-input
        display: flex
        justify-content: space-between
        margin-top: 24px

        .sdr-input
            width: 40%

    .sdr-ae-save
        display: flex
        justify-content: center
        margin: 24px 0

    .loading-wrapper
        align-items: center
        display: flex
        justify-content: center

    .ae-sdr-alert
        align-items: center
        box-sizing: border-box
        color: $alert-red
        display: flex
        font-size: 16px
        padding: 16px 20px
        width: 100%

        &.ae-sdr-error
            border: 1px $alert-red solid
            color: $alert-red

        &.ae-sdr-success
            border: 1px $base-blue solid
            color: $base-blue

        .info-payment-icon
            font-weight: 900
            margin-right: 12px
