@import 'src/modules/shared/_core.sass'

$input-icon-padding: 42px
$input-icon-border-radius: 6px

.payments-main-wrapper
    display: flex
    flex-direction: row
    flex-wrap: wrap
    grid-row-gap: 10px

    .payments-updated-successfully,
    .payments-processing-error,
    .payments-notice-info
        border: 1px $clear-green solid
        box-sizing: border-box
        color: $dark-gray
        display: flex
        font-size: 16px
        margin-bottom: -5px
        margin-top: 5px
        padding: 16px 20px
        width: 100%

        .info-payment-icon
            color: $clear-green
            margin-right: 12px
            vertical-align: middle

        span
            line-height: 26px

    .payments-processing-error
        border-color: $alert-red
        color: $alert-red

        .info-payment-icon
            color: $alert-red
            font-weight: 900

    .payments-notice-info
        border-color: $text-blue
        color: $black

        .info-payment-icon
            color: $text-blue
            font-weight: 400

    .payments-action-btn
        background-color: $white
        border: 1px $input-border solid
        border-radius: $default-border-radius
        color: $link-blue
        cursor: pointer
        font-size: 16px
        height: 72px
        line-height: 23px
        margin-right: 10px
        outline: 0
        padding: 12px 15px
        transition: box-shadow 0.05s
        transition-property: box-shadow, background-color, background, color
        width: 200px

        &:hover
            box-shadow: 0 2px 5px 0 $input-border
