@import 'src/modules/shared/_colors.sass'

.all-claimed-chats
    display: flex
    flex-direction: column
    max-height: calc(100% - 55px)

    &__filter
        display: block

        > :not(:first-child)
            margin-top: 4px

    &__list
        display: block
        overflow-y: auto
