@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: 'survey-activity-log-list'

.#{$module-name}
    &__title
        color: $black
        font-size: 24px
        font-weight: 400
        margin-bottom: 8px
        margin-top: 8px

    &__table
        border-collapse: collapse
        color: $alert-red
        table-layout: fixed


    &__table-head
        border-bottom: 1px solid $light-gray
        color: $soft-gray
        display: flex
        font-size: 12px
        font-weight: 500
        inset-block-start: 0
        padding: 0
        position: sticky
        top: 0

        &--with-margin-right
            margin-right: 16px

    &__th-cell
        color: $soft-gray
        font-weight: 400
        line-height: 1.5rem
        padding: 4px 0
        width: 36%

    &__th-cell-1
        width: 28%

    &__table-body
        display: block
        max-height: 300px
        overflow-y: scroll
        table-layout: fixed
        width: 300%

    & &__table-row
        display: block
        width: 100%

    & &__table-cell,
    & &__middle-table-cell,
    & &__last-table-cell
        border-bottom: 1px solid $light-gray
        color: $black-shade-87
        font-size: 13px
        padding: 14px 0
        width: 400px

        @media screen and (max-width: '1200px')
            width: 31vw


    & &__table-cell-with-scroll
        width: 384px

    & &__middle-with-scroll
        width: 384px

    & &__last-with-scroll
        width: 369px


    & &__table-cell-1
        width: 28%

    &__activityDetails
        overflow-wrap: 'break-word'
        white-space: 'pre-wrap'

        p
            margin: 0
