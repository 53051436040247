@import 'src/modules/shared/_colors.sass'

$module-name: 'claimed-chats-list'

.#{$module-name}
    display: flex
    flex-direction: column
    height: 50%
    justify-content: flex-start

    &__no-results
        padding: 20px 0
        text-align: center

    .#{$module-name}__tab
        color: $dark-gray
        flex: 1 1 0
        font-size: 14px
        font-weight: 400
        min-height: 30px
        min-width: 0
        padding: 0

        &:first-child
            margin-left: 0

        &--label-container
            padding: 0

        &--selected
            color: $link-blue
            font-weight: 500

    .#{$module-name}__tab-wrapper
        flex-grow: 0
        flex-shrink: 0
        min-height: auto
        padding: 5px 0 1px


