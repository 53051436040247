@import 'src/modules/shared/_colors.sass'

.production-values
    margin-bottom: 16px

    &__title
        margin-bottom: 2px
        margin-top: 0

    &__table
        display: flex
        padding-bottom: 4px
        padding-top: 4px

    &__cell
        width: 18%

    &__cell-title
        color: $label-gray
        font-size: 12px
        font-weight: 500
        padding-left: 0

    &__cell-value,
    &__cell-value-bottom
        color: $dark-gray
        font-size: 16px
        font-weight: 400
        padding-bottom: 16px
        padding-left: 0
        padding-top: 2px

    &__cell-value-bottom
        padding-bottom: 0

    &--bold
        font-weight: bold

    .circular-progress-loader
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 25px)

    .circular-progress-spinner
        color: $main-blue
