// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'

$module-name: widget-button-style

.#{$module-name}
    display: flex
    margin: 0 20px 8px

    &__title
        align-items: center
        color: $grey-47
        display: flex
        font-size: 18px
        font-weight: 500
        margin-left: 11px

    &__radio-wrapper,
    &__wrapper,
    label
        align-items: center
        display: flex

    &__radio-wrapper
        margin-left: 12px

    label
        cursor: pointer

    &__text
        font-size: 14px
        font-weight: 500
        line-height: 29px
        margin-left: 7px
        margin-right: 10px

    &__radio-root
        height: 30px
        width: 30px
