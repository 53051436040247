@import 'src/modules/dexvoice/_common.sass'
@import 'src/modules/shared/_widget.sass'


%ie-fixes
    max-height: 400px

.practice-quickview-dexvoice-tab
    display: flex
    max-height: 400px

    .agent-metrics
        @extend %ie-fixes
        display: flex
        flex: 0 260px
        flex-direction: column
        margin-right: 8px

    .agent-list
        @extend %metric-card
        @extend %ie-fixes
        flex: 1
        overflow-y: auto

        .subheader
            background: $tab-fill-light
            border-bottom: 1px solid $item-border
            font-size: 150%
            padding: 16px 0 20px 20px
