@import 'src/modules/dexvoice/_common.sass'
@import 'src/modules/shared/_widget.sass'
@import 'src/modules/shared/_colors.sass'


.manage-agents
    .back-button
        border: 0
        color: $dark-alert-blue
        padding-left: 15px

        &:hover
            cursor: pointer

    .footer
        background: $tab-fill
        border-top: solid thin $tab-border
        height: 45px

        .button
            line-height: 45px
            text-transform: uppercase

            .add-icon
                display: inline-flex
                padding-left: 20px
                padding-right: 5px
                position: relative
                top: -2px
                vertical-align: middle


        &:hover
            background: $main-blue
            cursor: pointer

    .table
        .data
            text-align: center

        .header
            padding-right: 15px
            text-transform: uppercase

        .location
            max-width: 200px
            width: 200px

    .actions
        align-items: center
        display: flex
        flex-direction: row
        justify-content: space-between

        .integration-types
            align-items: center
            display: flex
            flex-direction: row
            justify-content: center

            .integration-types-label
                padding-right: 8px

            .select-integration-type
                width: 100px

        .confirm-button
            background: $white
            border: 0
            color: $main-blue

            &:hover
                cursor: pointer

        .cancel-button
            background: $white
            border: 0
            color: $soft-black

            &:hover
                cursor: pointer
