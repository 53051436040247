$module-name: 'unclaimed-chats-list'

.#{$module-name}
    &__section-title
        display: flex
        font-weight: bold
        justify-content: space-between
        text-transform: uppercase

    &__section-title-counter
        min-width: 30px
