@import 'src/modules/shared/_colors.sass'

$module-name: chat-alerts-list

.#{$module-name}
    flex: 1 1
    flex-basis: 0
    overflow-y: auto

    i
        font-style: normal
        letter-spacing: 0.5px

    &__alert
        align-items: center
        color: $gray
        display: flex
        margin: 5px 0

    &__icon
        color: $lightcoral
        font-size: 23px
        margin: 0 10px

    &__alert-text
        color: $dark-gray
        white-space: pre-line

        p
            margin: 0

    &__no-records
        padding: 8px

.chat-alerts-loading
    align-items: center
    display: flex
    justify-content: center
    padding: 10px 0
