@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_mixins.sass'

$module-name: nav-chat-columns-controller

.#{$module-name}
    display: flex

    &__columns
        align-items: center
        background: $white
        border: 1px solid $grey-97
        border-radius: 17px
        display: flex
        height: 34px
        justify-content: space-between
        padding: 1px 2px

    &__button
        align-items: center
        border: 1px solid $grey-97
        border-radius: 50%
        color: $link-blue
        cursor: pointer
        display: flex
        font-size: 14px
        height: 22px
        justify-content: center
        margin: 0 4px
        padding: 0
        width: 22px

        &:focus
            background: $light-gray
            outline: 0

        &:disabled
            background: $clear-green
            color: $white
            cursor: default
            height: 30px
            margin: 0 1px
            width: 29px

    &__nav
        background: $white
        border: 1px solid $grey-97
        border-radius: 17px
        display: flex
        height: 34px
        margin-left: 27px
        overflow: hidden
        width: 66px

    &__left,
    &__right
        height: 34px
        overflow: hidden
        position: relative
        width: 33px

        > div
            background: $white
            position: relative
            z-index: 2

    &__nav-button
        background: none
        border: 0
        color: $link-blue
        cursor: pointer
        height: 34px
        margin: 0
        overflow: hidden
        padding: 0
        width: 100%

        &:disabled
            color: $soft-grey
            cursor: default

        &:focus
            background: $light-gray
            outline: none

        &--placeholder
            opacity: 0.5
            position: absolute
            z-index: 1

        .material-icons
            font-size: 1.5em
            padding-top: 5px
            text-align: center

    &__left
        border-right: 1px solid $grey-97

        .material-icons
            width: 0.5em
