@import '../_colors.sass'

$module-name: custom-progress-bar

.#{$module-name}
    &__filler
        background: $main-blue
        height: 2px
        left: 0
        position: relative
        top: -3px
        transition: width 0.2s ease-in
