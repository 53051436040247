@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-additional-features-and-fields-options

.#{$module-name}
    margin-left: 32px

    &__insurance-and-add-info,
    &__enable-custom-confirmation-page
        display: flex
        height: 100%
        justify-content: left
        padding: 4px 18px 0 22px

    &__title
        font-size: 20px
        line-height: 28px

    &__left,
    &__right
        display: flex
        flex-direction: column
        height: 100%

    &__left
        width: 58%

    &__right
        width: 42%

    &__bottom
        width: 100%

