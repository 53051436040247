@import 'src/modules/shared/_colors.sass'


.dexvoice-command
    border-bottom: 1px solid $black-shade-12
    padding: 12px 14px 16px
    position: relative

    &:last-child
        border-bottom: 0

    .table
        table-layout: fixed

        .command-data-item
            .value
                padding-left: 80px

    .device
        border: 1px solid $black-shade-25
        box-sizing: border-box
        cursor: pointer
        display: inline-block
        margin: 4px 0 0
        padding: 4px 8px

        &:hover
            box-shadow: 0 0 1px 1px $black-shade-40

    .status-badge
        font-size: 24px
        position: absolute
        right: 20px
        top: 20px

        .success
            color: $success-green

        .error
            color: $error-red
