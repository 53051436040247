@import '../../shared/_colors.sass'

$module-name: 'referral-activity-log'

.#{$module-name}
    &__title
        font-size: 24px
        margin-bottom: 8px
        margin-top: 8px

    &__table-view
        display: 'flex'

    &__table
        color: $alert-red

    & &__table-cell
        border-bottom: 1px solid $light-gray
        font-size: 0.8125em
        padding: 0

    &__activityDetails
        overflow-wrap: 'break-word'
        white-space: 'pre-wrap'

        p
            margin: 0
