@import 'src/modules/shared/_colors.sass'

$module-name: 'chat-list-navbar'

.#{$module-name}
    &__nav-top
        padding-top: 5px
        position: relative

    &__search
        background: $white
        margin-bottom: 10px

    &__infolabel
        padding-bottom: 16px

    &__unread-count
        align-items: center
        display: flex
        justify-content: center
        position: absolute
        right: 2px
        top: 4px

        label
            color: $white
            font-size: 12px
            position: absolute
            text-align: center
            width: 14px

    &__notifications
        color: $alert-red
        font-size: 36px
