@import 'src/modules/shared/_colors.sass'

$hover-error-bg: darken($error-red, 10)

.referral-review-flag__container
    margin: 10px 0

.referral-review-flag
    margin-bottom: 15px

    .custom-field__container
        margin-bottom: 15px

    .custom-field
        margin-right: 15px

    .referral-review-flag__field
        align-items: center
        display: flex

    .referral-review-flag__cancel-review
        color: $text-blue
        font-size: 14px

    .flag-icon
        font-size: 16px

    & &__flag-button
        background-color: $error-red
        border-radius: 4px
        color: $white
        padding: 6px 16px

        &:hover
            background-color: $hover-error-bg

