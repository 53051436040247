@import 'src/modules/shared/_core.sass'

.payments-monthly-statements
    width: 100%

    .flex-container
        margin: 0 auto
        width: 100%

.payments-monthly-statements-content
    margin: 0 auto
    width: 100%

    .payments-monthly-statements-loading
        position: relative
        width: 100%

        .loading
            display: block
            left: auto
            margin: 25px auto 15px
            position: relative
            right: auto

    .payments-back-button
        align-items: center
        background-color: $white
        border: 0
        color: $link-blue
        cursor: pointer
        display: flex
        font-size: 16px
        outline: 0
        padding: 0

        &:hover
            text-decoration: underline

    h2
        color: $dark-gray
        font-weight: 500
        margin: 20px 0 15px

    .loading-months
        display: block
        margin: 25px 0 0

    .monthly-statement-error
        align-items: center
        border: 1px $alert-red solid
        box-sizing: border-box
        color: $alert-red
        display: flex
        font-size: 16px
        padding: 16px 20px
        width: 100%

        .info-payment-icon
            color: $alert-red
            font-weight: 900
            margin-right: 12px

    .monthly-statement-filters
        display: flex

        .monthly-statement-empty
            font-size: 18px
            margin-top: 10px

        .monthly-statement-dropdown
            margin-right: 30px

            label
                color: $dark-gray
                display: block
                font-size: rem(12px)
                margin-bottom: 6px

            .select
                max-width: 400px

            &:last-child
                .select
                    width: 250px

    .monthly-statements-list
        padding-left: 19px

        .monthly-statement-link
            background-color: transparent
            border: 0
            color: $link-blue
            cursor: pointer
            font-size: 18px
            margin-bottom: 3px
            outline: none
            padding: 0
            text-decoration: none

            &:hover
                text-decoration: underline
