@import 'src/modules/shared/_colors.sass'


.staff-form-locations-container
    padding-top: 15px

    .locations-column-left
        padding-right: 25px

        @media screen and (max-width: 900px)
            padding-right: 0


    .locations-column-title
        border-bottom: 1px solid $border-gray
        color: $lighter-black
        font-size: 12px
        font-weight: 500
        padding-bottom: 3px

        &.right
            @media screen and (max-width: 900px)
                display: none


        .permissions-title-container
            display: flex

        .permissions-title
            text-align: left
            width: 90px

    .permissions-checkbox-container
        display: flex
        justify-content: flex-end

    .permissions-checkbox
        text-align: left
        width: 90px

    .locations-row
        border-bottom: 1px solid $border-gray

        .location-checkbox
            height: 35px
            min-width: 200px

