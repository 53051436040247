@import 'src/modules/shared/_colors.sass'


.app-root > .referrals-dashboard-container
    max-width: 100%
    overflow-x: hidden

.referrals-dashboard-header
    background-color: $header-gray
    height: 96px

    .referrals-dashboard-refresh
        background: $white
        border: 1px solid $main-blue
        color: $main-blue
        float: right
        font-size: 16px
        letter-spacing: 0.5px
        padding: 3px 14px
        right: 38px
        top: 11px

        .material-icons
            font-size: 18px
            margin-bottom: 1px
            margin-left: 7px

        &:hover
            background-color: $hover-blue-button-shade

.referrals-dashboard-title
    left: 38px
    margin: 0 auto
    position: relative
    top: 42px

.referrals-dashboard-columns
    display: flex

.referrals-dashboard-columns-loading
    opacity: 0.5
    pointer-events: none

.referral-status-modal
    background-color: $modal-gray

    .modal-contents
        background-color: $white
        box-shadow: 0 4px 12px 0 $shadow-gray
        margin: auto
        position: relative
        top: 10%
        width: 50%

    .close-modal-button
        float: right
        position: relative
        right: 15px
        top: 10px

        &:hover
            cursor: pointer

    .button-container
        display: flex
        justify-content: center
        margin-top: 18px
        padding-bottom: 20px

        .primary
            border-color: $main-blue

        button
            box-shadow: none
            margin: 10px

.referral-form
    .referral-actions
        display: flex
        justify-content: flex-end
        padding-right: 32px

        .delete
            color: $main-blue
            letter-spacing: 0.5px
            padding: 6px 8px
