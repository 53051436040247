@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.phone-custom-field__container
    display: flex
    position: relative
    width: calc(100% + 2px)

    &:hover
        .phone-custom-field__label
            color: $main-blue

    ::placeholder
        color: $label-gray
        opacity: 1

.phone-custom-field
    position: relative
    width: 100%

    .PhoneInputCountry //sass-lint:disable-line class-name-format
        padding-left: 2px

.phone-custom-field__label
    color: $label-gray
    font-size: 12px
    margin: 5px

.phone-custom-field__helper-text
    color: $label-gray
    display: flex
    font-size: 12px
    margin: 5px

.phone-custom-field--active
    .PhoneInputInput //sass-lint:disable-line class-name-format
        border: 1px solid $main-blue
        outline: none

    .phone-custom-field__label
        color: $main-blue

.phone-custom-field--error
    .PhoneInputInput //sass-lint:disable-line class-name-format
        border-color: $error-red

    .phone-custom-field__label,
    .phone-custom-field__helper-text
        color: $error-red

.phone-custom-field--warning
    .PhoneInputInput //sass-lint:disable-line class-name-format
        border-color: $warning-orange

    .phone-custom-field__label,
    .phone-custom-field__helper-text
        color: $warning-orange

.phone-custom-field--disabled
    .PhoneInputInput //sass-lint:disable-line class-name-format
        background-color: $white
        border-color: $very-soft-gray
        color: $label-gray

.PhoneInputInput //sass-lint:disable-line class-name-format
    border: 1px solid $field-border
    border-radius: $default-border-radius
    height: 36px

    &:hover
        background-color: $shortcuts-white
        border-color: $main-blue
