@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'

$module-name: 'referral-patient-info'

// sass-lint:disable no-important
// sass-lint:disable class-name-format
.#{$module-name}
    &__header
        align-items: center
        background-color: $gray-blue
        color: $white
        display: flex
        height: 47px
        justify-content: space-between
        margin: 16px 13px 20px 0
        padding-left: 23px

    &__header-title
        font-size: 18px
        margin: 0

    &__header-checkbox-label
        font-size: 16px

    &__header-title,
    &__header-checkbox-label,
    &__header-checkbox-icon
        color: $white !important
        letter-spacing: normal

    &__header-checkbox-icon
        padding: 9px !important

        &:hover
            border-radius: 50%

    &__datepicker-wrapper,
    &__datepicker-wrapper-patient-dob
        margin-right: 13px

        &:hover
            .MuiInputLabel-root
                color: $main-blue

            .Mui-error
                color: $error-red

            .MuiInput-formControl
                border: 1px solid $main-blue

                &.Mui-error
                    border: 1px solid $error-red

            .masked-datepicker
                &__masked-input
                    border-color: $main-blue

                    &--error
                        border-color: $error-red

    &__datepicker-wrapper-patient-dob

        .masked-datepicker__masked-input
            padding: 2px 0 2px 10px

    &__field
        margin-bottom: 15px !important

    &__field-message
        margin-bottom: 15px !important
        padding-right: 14px

        .general-error-message__message
            font-weight: 400

    &__field-static
        margin-bottom: 12px !important
        padding-left: 5px

        .#{$module-name}__field-label
            padding-left: 0

    &__field-label
        color: $label-gray
        font-size: 12px
        line-height: 18px
        padding-left: 4px

    &__field-value
        color: $label-gray
        padding-left: 4px

    &__field-text
        +long-word-break

        color: $dark-gray
        font-size: 16px
        min-height: 16px

    .datepicker-test
        border: 1px solid $red !important
        border-radius: 6px
        height: 40px
        max-width: 100%
        width: 100%

    &__section-title
        align-items: center
        color: $grey-55
        display: flex
        flex-wrap: wrap
        font-size: 20px
        margin: 16px 0 8px

        .MuiButtonBase-root //sass-lint:disable-line class-name-format
            border-radius: 50%
            padding: 9px
            position: absolute

    &__title
        padding-right: 14px

    &__form-control-label
        font-size: 0.875rem
        padding-left: 4px

    .custom__input-error-icon
        top: 9px

    &__switch-base
        color: $white

