@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_modals.sass'

$input-icon-padding: 42px
$input-icon-border-radius: 6px

.location-payments-modal
    @extend %new-item-modal
    margin-top: 5vh
    padding: 0
    width: 700px

    .update-button
        background: $tab-fill
        line-height: 45px
        text-align: center
        text-transform: uppercase
        vertical-align: middle
        width: 100%

        &.can-save
            background: $main-blue
            color: $black
            cursor: pointer

.payments-modal-wrapper
    box-sizing: border-box
    margin-bottom: 0
    max-height: 80vh
    overflow: auto
    position: relative

    .payments-main-inner
        padding: 54px 34px 23px

    h2
        font-size: 32px
        margin: 18px 0 0

    &.payments-modal-wrapper-loading
        align-items: center
        display: flex
        height: 200px
        justify-content: center
        padding: 25px 0
        text-align: center
        width: 100%

    .payments-stripe-input
        display: block
        max-width: 300px

    .payments-processing-input
        display: block
        position: relative
        width: 135px

    .payments-processing-wrapper
        align-items: center
        display: flex
        margin-bottom: 10px

        label
            align-items: center
            cursor: pointer
            display: flex

    .payments-rate-wrapper
        align-items: center
        display: flex

    .custom-field__container
        input
            box-sizing: border-box
            height: 40px
            padding-right: $input-icon-padding
            width: 100%

    .pay-process-icon
        background: $very-soft-gray
        border: 1px $input-border solid
        border-radius: 0 $input-icon-border-radius $input-icon-border-radius 0
        border-width: 0 0 0 1px
        color: $label-gray
        font-size: 22px
        height: calc(100% - 2px)
        line-height: 40px
        position: absolute
        right: 1px
        text-align: center
        top: 1px
        width: 34px

    .pay-process-icon--start
        border-radius: $input-icon-border-radius 0 0 $input-icon-border-radius
        border-width: 0 1px 0 0
        font-size: 18px
        left: 1px
        right: auto
        z-index: 1

        ~ .custom-field__container
            input
                padding-left: $input-icon-padding
                padding-right: 4px

    .payments-processing-add
        font-size: 20px
        margin: 0 14px

    .payments-updated-successfully,
    .payments-processing-error
        border: 1px $clear-green solid
        box-sizing: border-box
        color: $dark-gray
        display: flex
        font-size: 16px
        margin-bottom: 20px
        padding: 16px 20px
        width: 100%

        .info-payment-icon
            color: $clear-green
            margin-right: 12px
            vertical-align: middle

        span
            line-height: 26px

    .payments-processing-error
        border-color: $alert-red
        color: $alert-red

        .info-payment-icon
            color: $alert-red
            font-weight: 900

    .payments-processing-error-blocking
        margin-bottom: 0

    .payments-section-wrapper
        margin-top: 20px

        &--multiple
            display: flex
            justify-content: space-between

        .payments-section-item
            flex-basis: 40%

    h3
        color: $grey-55
        font-size: 19px
        font-weight: 500
        margin: 0 0 10px

    .radio-root
        height: 24px
        margin-right: 8px
        width: 24px

        svg,
        *
            color: $text-blue

    .pay-processing-button
        background-color: $text-blue
        box-shadow: none
        display: block
        font-size: 16px
        font-weight: 400
        height: 52px
        margin: 10px auto 0
        width: 158px

        span
            margin-top: 3px

        svg
            color: $white

    .location-payments-apply-all-wrapper
        display: flex
        justify-content: center

        .location-payments-apply-all-checkbox
            color: $main-blue
            height: 36px
            width: 36px

            &  + span
                font-size: 16px
                user-select: none
