@import 'src/modules/shared/_colors.sass'


$main-blue-transparent: rgba($main-blue, 0.5)

@keyframes flash-alert
    50%
        background-color: $main-blue

@keyframes flash-alert-red
    50%
        background-color: $error-red

@keyframes ring
    0%
        transform: rotate(0)

    1%
        transform: rotate(30deg)

    3%
        transform: rotate(-28deg)

    5%
        transform: rotate(34deg)

    7%
        transform: rotate(-32deg)

    9%
        transform: rotate(30deg)

    11%
        transform: rotate(-28deg)

    13%
        transform: rotate(26deg)

    15%
        transform: rotate(-24deg)

    17%
        transform: rotate(22deg)

    19%
        transform: rotate(-20deg)

    21%
        transform: rotate(18deg)

    23%
        transform: rotate(-16deg)

    25%
        transform: rotate(14deg)

    27%
        transform: rotate(-12deg)

    29%
        transform: rotate(10deg)

    31%
        transform: rotate(-8deg)

    33%
        transform: rotate(6deg)

    35%
        transform: rotate(-4deg)

    37%
        transform: rotate(2deg)

    39%
        transform: rotate(-1deg)

    41%
        transform: rotate(1deg)

    43%
        transform: rotate(0)

    100%
        transform: rotate(0)


.chat-card
    align-items: center
    border-bottom: solid 1px $tab-fill
    border-left: 5px solid $white
    color: $dark-gray
    font-size: 14px
    overflow: hidden
    padding: 8px
    position: relative

    &:first-child
        border-top: solid 1px $tab-fill

    &:hover
        background: $light-alert-blue
        border-left-color: $main-blue
        cursor: pointer

    .card-icon
        position: absolute
        right: 2px
        top: 4px

    .unread-notification
        animation: ring 2s 0.9s ease-in-out infinite
        color: $alert-red
        font-size: 15px
        transform-origin: 50% 4px

    .alarm-icon-idle
        color: $main-blue

    .alarm-icon-inactive
        color: $alert-red

    .chat-info-wrapper
        display: flex
        flex-direction: row
        justify-content: flex-start

        .chat-info
            margin-top: -2px
            width: 100%

    .patient
        align-items: center
        display: flex
        flex-direction: row
        justify-content: flex-start

    .patient-flag
        height: 20px
        padding-right: 8px

    .patient-name
        color: $black
        flex: 1 1 0
        font-weight: bolder
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .notification-icons
        flex: 0 0 24px

    .practice
        color: $main-blue
        font-weight: bold
        margin-bottom: 7px

    .created
        color: $grey-b9
        font-size: 12px
        padding-bottom: 5px

    .chat-claimee
        color: $black
        font-size: 0.875em
        text-transform: uppercase

        .record-voice-over
            font-size: 1em
            margin-right: 3px
            position: relative
            top: 2px

    &.unclaimed
        animation: flash-alert 2s step-end infinite
        background-color: $main-blue-transparent
        color: $white
        cursor: default

        .patient-name,
        .practice,
        .created,
        .last-message
            color: $white

        .practice
            font-weight: normal

        .claim-chat
            margin-top: 6px

            .claim-button-root
                background-color: $white
                border-radius: 4px
                box-shadow: 0 1px 3px 0 $lightskyblue
                color: $main-blue
                padding: 4px 5px
