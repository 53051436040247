@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/shared/_mixins.sass'

$module-name: scheduling-appointment-picker

.#{$module-name}
    position: relative
    user-select: none
    width: 100%

    &__refresh
        align-items: center
        color: $link-blue
        cursor: pointer
        display: flex
        justify-content: center
        padding: 6px 10px 10px 16px
        text-align: center

    &__header
        background: $link-blue
        color: $white
        padding: 10px

    &__month
        font-weight: 500
        padding-bottom: 14px
        text-align: center
        text-shadow: 1px 1px 1px $black-shade-25
        text-transform: uppercase

    &__days-picker
        align-items: center
        display: flex
        justify-content: space-between
        padding-bottom: 7px

    &__days
        display: flex
        justify-content: space-between
        width: 85%

    &__day
        background: none
        border: 0
        color: $black
        cursor: pointer
        margin: 0
        outline: none
        padding: 0
        position: relative
        text-align: center

        &::before
            background: $white
            border: 1px solid $clear-green
            border-radius: 50%
            content: ''
            height: 34px
            left: 50%
            position: absolute
            top: 50%
            transform: translate(-50%, -50%)
            width: 34px
            z-index: 1

        &:disabled
            color: $lightgray
            cursor: default
            opacity: 0.7

            &::before
                display: none

        &--selected
            color: $white
            cursor: default

            &::before
                background: $clear-green
                border: 1px solid $white

    &__date
        font-size: 14px
        position: relative
        z-index: 2

    &__date-progress
        height: 34px
        left: calc(50% - 3px)
        position: absolute
        top: calc(50% - 2px)
        transform: translate(-50%, -50%)
        width: 34px
        z-index: 2

    &__weekday
        font-size: 10px
        position: relative
        text-transform: uppercase
        z-index: 2

    &__back
        cursor: pointer

    &__forward
        cursor: pointer

    &__appointments
        background: $grey-f5
        display: grid
        grid-gap: 8px
        grid-template-columns: repeat(auto-fit, minmax(77px, 1fr))
        padding: 12px 8px

    &__time
        border-radius: 4px
        box-shadow: 0 1px 4px 0 $black-shade-25
        color: $link-blue
        cursor: pointer
        font-size: 12px
        height: 30px
        line-height: 30px
        text-align: center

        &--selected
            background: $clear-green
            box-shadow: none
            color: $white

    &__progress
        padding: 20px
        text-align: center

    &__timezone
        font-size: 0.75em
        font-weight: normal
        padding-left: 8px

    &__submit
        align-items: center
        display: flex
        justify-content: center
        margin: 24px 0
        padding-left: 0

    & &__buttons-wrapper
        display: flex
        justify-content: center

    & &__buttons-wrapper-narrow
        display: block
        justify-content: center

    & &__submit-button
        +primary-button(100px)
        background-color: $main-blue
        font-size: 15px
        height: 36px
        letter-spacing: 0
        line-height: 1.4em
        margin-left: 16px
        max-width: 224px
        padding: 8px 18px
        white-space: normal

    & &__submit-button-narrow
        +primary-button(100px)
        font-size: 14px
        height: 36px
        line-height: 1.4em
        margin-left: 0
        max-width: 100%
        white-space: normal

    & &__back-icon
        font-size: 18px
        padding-right: 4px

    & &__back-button
        border: 1px solid $link-blue
        border-radius: 6px
        color: $link-blue
        font-size: 16px
        height: 36px
        max-width: 93px
        padding: 8px 16px

    & &__make-changes-button
        background-color: $white
        border: 1px solid $link-blue
        border-radius: 6px
        color: $link-blue
        font-size: 14px
        height: 36px
        padding: 8px 12px

    & &__make-changes-button-narrow
        background-color: $white
        border: 1px solid $link-blue
        border-radius: 6px
        color: $link-blue
        font-size: 14px
        height: 36px
        max-width: 120px
        padding: 8px


    & &__preview-appointment-overlay
        background-color: $black-shade-80
        color: $white
        height: max-content
        position: absolute
        top: 0
        width: 100%
        z-index: 1000

    & &__preview-appointment
        padding: 15px
        text-align: center

    & &__info-icon
        font-size: 48px

    & &__preview-appointment-header
        font-size: 22px
        line-height: 28px

    & &__preview-appointment-sub-header
        padding-top: 8px

    & &__preview-appointment-body
        font-size: 15px
        line-height: 24px
        padding-top: 8px

    & &--bold
        font-weight: bold

    & &__preview-appointment-action-buttons
        display: flex
        justify-content: space-between
        padding: 0 16px

    & &__preview-appointment-action-buttons-narrow
        display: block
        justify-content: center
