@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/shared/_mixins.sass'

$header-height: 25px

$module-name: chat-center-alerts

.#{$module-name}
    background-color: $light-greyish-orange
    font-size: 14px
    max-height: 100%
    overflow-y: auto
    user-select: text

    &__header
        display: flex
        flex: 0
        flex-direction: column
        font-weight: bold
        line-height: calc(#{$header-height} - 4px)
        padding: 4px 10px 0

    &__loader
        align-items: center
        display: flex
        justify-content: center
        padding: 10px 0

    &__shadow-divider
        background: linear-gradient(180deg, $shadow-gray-c7 0%, $black-transparent 100%)
        border: 0
        flex-shrink: 0
        height: 7px
        margin-top: -7px
        transform: rotate(180deg)
        width: 100%

    &__body-container
        +long-word-break

        display: flex
        flex: 1 1 auto
        flex-direction: column
        min-height: 50px
        overflow-x: hidden

    &__fixed-alerts
        font-weight: 500
        list-style-type: none
        margin: 4px 8px 4px -28px


    &__connect
        background: $clear-green
        color: $white
        display: flex
        margin: 8px
        padding: 10px

    &__connect-icon
        margin-right: 10px

    &__connect-label
        padding-top: 4px

    &__resize-handle
        background-color: $soft-gray
        border-radius: 6px 6px 0 0
        bottom: 8px
        cursor: grab
        height: 16px
        left: 49%
        position: absolute
        right: 49%
        width: min-content

    &__resize-handle-icon
        color: $white
        line-height: 0.6
