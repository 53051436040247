// sass-lint:disable class-name-format
@import 'src/modules/shared/_colors.sass'

$default-btn-font-size: 16px
$pay-disabled-background: #f5f5f5
$pay-disabled-color: #bfbfbf
$pay-disabled-border: #d9d9d9


%vyne-button-blue-default
    background-color: $base-blue
    border: 0
    border-radius: 6px
    box-shadow: none
    box-sizing: border-box
    color: $white
    cursor: pointer
    font-size: $default-btn-font-size
    height: 36px
    padding: 3px 16px
    text-transform: uppercase
    width: auto

    .MuiTouchRipple-root
        display: none

    &:hover
        background-color: $main-blue-hover
        border: 0
        box-shadow: none

    &:disabled
        background-color: $pay-disabled-background
        border: 1px $pay-disabled-border solid
        color: $pay-disabled-color
        cursor: default

        span
            color: $pay-disabled-color

    span
        font-size: $default-btn-font-size
        padding: 0

%vyne-button-white-background
    background-color: $white
    border: 1px $light-gray solid
    border-radius: 6px
    box-shadow: none
    box-sizing: border-box
    color: $main-blue
    cursor: pointer
    display: inline-block
    font-size: $default-btn-font-size
    height: 36px
    padding: 3px 16px
    text-transform: uppercase
    width: auto

    .MuiTouchRipple-root
        display: none

    &:hover
        background-color: $tab-fill-light
        border: 1px $light-gray solid
        box-shadow: none

    &:disabled
        background-color: $pay-disabled-background
        border-color: $pay-disabled-border
        color: $pay-disabled-color
        cursor: default

        .icon-left
            color: $pay-disabled-color

        .content-wrapper
            color: $pay-disabled-color

    .content-wrapper
        align-items: center
        color: $base-blue
        display: flex
        flex-direction: row
        font-size: $default-btn-font-size

    .icon-left
        color: $base-blue
        display: inline-block
        font-size: 16px
        margin-left: -2px
        margin-right: 5px
        position: relative
        top: -1px

        &--close
            font-size: 20px
            top: 0
