@use 'sass:math'
@import '../../shared/_colors.sass'
@import '../../shared/_constants.sass'

$module-name: calendar-picker

.#{$module-name}
    background: $blue-gradient
    color: $white
    min-width: 160px
    overflow: auto
    padding: 10px 4px
    position: relative
    user-select: none

    &__refresh
        align-items: center
        background: none
        border: 0
        color: $link-blue
        cursor: pointer
        display: flex
        font-size: 1em
        justify-content: center
        margin: 0
        outline: none
        padding: 6px 10px 10px 16px
        text-align: center
        width: 100%

        &:disabled
            color: $lightgrey
            cursor: defauly

    &__header
        align-items: center
        display: flex
        justify-content: center
        padding-bottom: 10px
        text-transform: uppercase

    &__header-icon
        height: 25px

    &__header-arrow
        border: 1px solid $white
        border-radius: 50%
        cursor: pointer

        .material-icons
            font-size: 1.6em

        &--disabled
            border: 0
            cursor: default
            opacity: 0.8

    &__header-label
        padding: 0 5px

    &__weekdays
        display: flex
        font-weight: 500
        justify-content: space-around
        margin-bottom: 6px
        text-align: center
        width: 100%

    &__row
        display: flex
        justify-content: space-around
        margin-bottom: 6px
        text-align: center
        width: 100%

    &__col,
    &__weekday
        max-width: math.div(100%, 7)
        min-width: math.div(100%, 7)
        width: math.div(100%, 7)

    &__day
        background: $shortcuts-white
        border: 1px solid $clear-green
        border-radius: 50%
        box-sizing: border-box
        color: $black
        cursor: pointer
        font-size: 0.9em
        font-weight: 300
        height: 29px
        margin: 0
        outline: none
        padding: 0
        width: 29px

        &--selected
            background-color: $clear-green
            border: 1px solid $white
            color: $white
            font-weight: 400
            height: 30px
            width: 30px

        &:disabled
            background: none
            border: 0
            color: $lightgray
            cursor: default
            opacity: 0.7

    &__footer
        padding-top: 10px
        text-align: center

        & button
            background: $white
            border-radius: 4px
            color: $link-blue
            height: 36px
            min-width: 88px
            padding: 8px 16px

            &:hover
                background: $white

    &__loader
        left: 50%
        position: absolute
        top: 50%
        transform: translate(-50%, -50%)
        z-index: 10

    &__message
        background: $white-shade-80
        color: $dark-grey
        left: 50%
        max-width: 80%
        min-width: 65%
        padding: 8px
        position: absolute
        top: 50%
        transform: translate(-50%, -50%)
        z-index: 10

        &--error
            background: $alert-red
            color: $white

        em
            border-bottom: 1px solid $white
            cursor: pointer
            font-style: normal
