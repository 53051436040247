@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/shared/_mixins.sass'


$module-name: connect-booking

.#{$module-name}
    box-sizing: border-box
    min-height: 100%
    overflow-x: auto
    padding: 7px 8px 12px
    position: relative
    width: 100%

    &--has-error
        overflow: hidden

    &__pending
        background: $white-shade-50
        bottom: 0
        display: flex
        justify-content: center
        left: 0
        padding-top: 120px
        position: absolute
        right: 0
        top: 0
        z-index: 5

    &__restricted-patient-type
        color: $alert-red
        font-size: 24px
        font-weight: normal
        margin-bottom: 10px

    &__section-title
        align-items: center
        color: $grey-55
        display: flex
        flex-wrap: wrap
        font-size: 20px
        margin: 16px 0 8px

    &__title
        padding-right: 14px

    &__required-red
        color: $alert-red

    &__tabs
        display: flex
        margin: 5px 0 15px

    &__tab
        border: 1px solid $input-border
        color: $link-blue
        cursor: pointer
        display: flex
        font-size: 1em
        height: 40px
        justify-content: center
        line-height: 40px
        overflow: hidden
        padding: 0 8px
        text-align: center
        text-transform: none
        width: 50%

        &:last-child
            border-radius: 0 $default-border-radius $default-border-radius 0

        &:first-child
            border-radius: $default-border-radius 0 0 $default-border-radius

        &:disabled
            color: $disabled

        &--selected
            background: $link-blue
            color: $white

            &:disabled
                background: $light-grey
                color: $white

    &__field-columns
        display: grid
        grid-gap: 0 9px
        grid-template-columns: repeat(auto-fit, minmax(152px, 1fr))
        width: 100%

        .#{$module-name}--default &,
        .#{$module-name}--large &
            grid-template-columns: repeat(auto-fit, minmax(40%, 1fr))

    &__field
        margin-bottom: 6px

        &--datepicker
            position: relative

    &__label
        color: $label-gray
        font-size: 12px
        margin: 5px

    &__value
        margin: 5px

    &__error
        color: $alert-red
        padding: 16px 8px 0
        text-align: center

    &__submit
        align-items: center
        display: flex
        justify-content: center
        margin: 20px 0

    & &__submit-button
        +primary-button(100px)
        max-width: 98%
        padding-left: 12px
        padding-right: 12px
        white-space: normal
        width: 320px

    &__booked
        display: flex
        flex-direction: column
        justify-content: center
        margin: 0 auto
        max-width: 410px
        min-height: 100%
        text-align: center
        width: 98%

        dl
            font-size: 18px
            margin: 0
            text-align: left
            word-break: break-word

        dt
            color: $grey-8b
            font-weight: bold

        dd
            color: $dark-gray
            margin: 0 0 16px
            padding: 0

            a
                color: $sky-blue

    &__booked-icon
        margin: 0 auto 17px
        width: 94px

        svg
            height: 91px
            width: 94px

    &__booked-title
        color: $grey-69
        font-size: 32px
        font-weight: bold
        margin: 0 0 12px
        padding: 0

    &__payment-process
        align-items: center
        display: flex
        flex-direction: column
        min-height: 300px
        padding-top: 33px
        text-align: center

    &__payment-process-icon
        margin-bottom: 10px

        > svg
            height: 3.8em
            width: 3.8em

    &__payment-process-label
        color: $lighter-black
        font-size: 32px
        font-weight: bold
        margin-bottom: 9px

        .#{$module-name}--default &,
        .#{$module-name}--small &
            font-size: 20px

    &__backdrop
        background: $white-shade-50
        bottom: 107px
        left: 50%
        position: absolute
        right: 0
        top: 0
        z-index: 10

    &__error-modal
        background: $error-red
        border-radius: $default-border-radius
        box-sizing: border-box
        color: $white
        left: 50%
        max-width: 95%
        padding: 20px
        position: absolute
        top: 50%
        transform: translate(-50%, -50%)
        width: 400px
        word-break: break-word

        p
            margin: 0
            padding: 0

    &__close-error
        cursor: pointer
        position: absolute
        right: 16px
        top: 16px

    &__error-icon
        font-size: 3em
        text-align: center

        .material-icons
            font-size: 48px

    &__switch-base
        color: $white

        .MuiButtonBase-root //sass-lint:disable-line class-name-format
            padding: 9px
            position: absolute
