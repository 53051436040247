@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


$module-name: buttongroup-selector

.#{$module-name}
    display: block

    &--error
        color: $error-red

    &__container
        width: 100%

    &__list
        display: flex

    & &__button
        align-self: center
        background: $white
        border: 1px solid $input-border
        color: $text-blue
        display: inline-flex
        font-size: 16px
        font-weight: 400
        height: 40px
        justify-content: center
        margin-left: -1px
        min-width: 44px
        width: 44px

        &:first-child
            border-radius: $default-border-radius 0 0 $default-border-radius
            margin-left: 0

        &:last-child
            border-radius: 0 $default-border-radius $default-border-radius 0

        &--disabled
            border-color: $very-soft-gray
            color: $label-gray

        &--active
            background: $text-blue
            color: $white

            &:hover
                background: $text-blue
                color: $white

            &.#{$module-name}__button--disabled
                background: $disabled

    & &__label
        color: $label-gray
        font-size: 12px
        margin: 5px

    & &__helper-text
        color: $label-gray
        display: flex
        font-size: 12px
        margin: 5px

        &--error
            color: $error-red
