@import 'src/modules/shared/_colors.sass'

.actions-container
    display: flex
    margin-top: 0
    text-align: center
    width: 100%

    .line-before,
    .line-after
        border-bottom: 1px solid $input-border
        content: ''
        flex: 1 1
        margin: auto

    .line-before
        margin-left: 6px

    .line-after
        margin-right: 6px

    .message
        color: $grey
        font-style: italic
