// sass-lint:disable no-important

@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'
@import '../../shared/_constants.sass'

$module-name: alert-modal

.#{$module-name}
    &__container
        background-color: $white
        border-radius: $default-border-radius
        color: $dark-gray
        display: flex
        flex-direction: column
        left: 50%
        outline: none
        padding: 10px 10px 32px
        position: absolute
        top: 50%
        transform: translate(-50%, -50%) !important
        width: 678px

    &__header
        align-items: center
        display: flex
        justify-content: flex-end
        padding: 0

    &__header &__close-button
        cursor: pointer

    &__content
        display: flex
        flex-direction: column
        margin: 0 auto
        padding: 6px 0 2px
        text-align: center

    &__content &__icon
        color: $error-red
        margin-right: 2px

        .material-icons
            font-size: 48px

    &__content &__title
        color: $grey-47
        font-size: 24px
        font-weight: bold
        line-height: 32px
        padding-top: 2px
        white-space: pre-line

    &__content &__notification
        font-size: 16px
        line-height: 20px
        padding: 0 16px
        text-align: center

    &__footer
        align-items: center
        display: flex
        justify-content: center
        padding-top: 4px

    &__footer &__yes_btn
        background-color: $main-blue
        border: 1px solid $main-blue
        border-radius: 4px
        box-shadow: none
        color: $white
        font-size: 16px
        font-weight: 500
        height: 36px
        margin: 10px
        padding: 6px 21px

        &:hover
            background-color: $main-blue

@media screen and (max-width: $small-width)
    .#{$module-name}
        &__container
            max-width: 482px
            width: calc(97% - 38px)
