@import 'src/modules/shared/_colors.sass'

.app-root > .practice-diagnostics
    margin-top: 30px
    padding: 0 70px

.practice-diagnostics
    .detached-button
        color: $black
        cursor: pointer
        height: 24px
        position: absolute
        transform: translateX(-200%)

.circular-progress-loader
    left: 50%
    position: absolute
    top: 50%
