@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-web-code-customization-form

.#{$module-name}
    display: flex
    flex-direction: column
    height: 100%

    &__container
        height: calc(100% - 56px)
        overflow-x: auto
        padding: 25px 51px

    &__header
        align-items: center
        flex-direction: row
        font-weight: bold
        justify-content: space-between

    &__header-name
        font-size: 32px
        line-height: 42px
        width: 100%

    &__header-url
        font-size: 20px
        line-height: 32px
        overflow-wrap: break-word
        overflow-y: auto
        padding-bottom: 16px
        width: 100%

    &__title
        margin-bottom: 8px

    &__table-action
        background: $tab-fill
        border-top: solid thin $tab-border

    &__option
        align-items: center
        display: flex
        font-weight: 500
        line-height: 45px
        text-transform: uppercase

        &:hover
            box-shadow: 0 0 0 1px $main-blue inset
            cursor: pointer

        &.disabled
            &:hover
                box-shadow: 0
                pointer-events: none

        &--open
            background-color: $white

            .#{$module-name}__option-add-icon
                display: none

            .#{$module-name}__option-close-icon
                display: inline-flex

            &:hover
                background: $white

    &__option-add-icon,
    &__option-close-icon
        font-size: 24px
        padding: 0 10px 0 20px

    &__option-add-icon
        display: inline-flex

    &__option-close-icon
        display: none

    .webcode-section-title
        color: $grey-47
        font-size: 18px
        font-weight: 500
        margin-bottom: 16px

    .webcode-button
        background: $tab-fill
        color: $black
        cursor: pointer
        display: block
        font-size: 14px
        font-weight: bold
        padding: 20px
        text-align: center
        text-transform: uppercase
        width: 100%

        &:not(:disabled)
            &:hover
                background: $primary-hover-bg
                color: $white

        &:disabled
            cursor: default

        &.can-save
            background: $main-blue
            color: $white
            cursor: pointer
