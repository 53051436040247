// sass-lint:disable variable-for-property
@import 'src/modules/shared/_colors.sass'


.paginator
    color: $pagination-number
    display: inline-flex
    margin-top: 30px
    user-select: none

    ul
        display: flex
        list-style-type: none
        margin: 0
        padding: 0

    li
        align-items: center
        border: 1px solid $tab-border
        display: flex
        height: 40px
        justify-content: center
        width: 36px

        &:hover
            &:not(.disabled)
                background-color: lighten($main-blue, 10)
                border-color: lighten($main-blue, 10)
                color: $white
                cursor: pointer

        &:first-child
            border-bottom-left-radius: 6px
            border-top-left-radius: 6px

        &:last-child
            border-bottom-right-radius: 6px
            border-top-right-radius: 6px

    .disabled
        color: $dark-gray

    .selected
        background-color: $main-blue
        border-color: $main-blue
        color: $white
