// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'

$app-header-height: 67px

.chat-center
    font-family: 'Roboto', sans-serif
    font-size: 16px
    height: calc(100vh - #{$app-header-height})
    max-width: calc(100% - 50px) !important
    padding: 5px

    &__grid-container,
    &__grid-item
        height: 100%

    &__toolbar
        align-items: center
        display: flex
        justify-content: flex-end

    &__tiles-container
        display: block
        height: 100%

    &__tile
        flex: 1
        margin: 5px
        max-width: calc(100% - 10px)
        outline: none

    .col
        height: 90vh

    .header
        align-items: center
        background: $main-blue
        color: $white
        display: flex
        height: rem(44px)
        padding: rem(0 11px)

    .practice-info
        display: flex
        flex-direction: column

        .action-alerts
            display: flex
            flex: 1 1 0
            flex-direction: column
            height: 100%
            margin-bottom: 20px

        .survey
            .response
                background: $tab-fill
                height: 12vh
                margin: 1vh

            .title
                flex-grow: 4

    .list-header
        align-items: center
        background: $tab-fill
        display: flex
        flex-grow: 0
        flex-shrink: 0
        height: rem(44px)
        justify-content: center

.alert-modal
    &__close-button
        height: 24px

        &:hover
            box-shadow: 0 0 2px 0 $black-shade-50


