@import 'src/modules/shared/_colors.sass'


$module-name: error-notification-card

.#{$module-name}
    &__container
        background-color: $error-red
        border-radius: 6px
        box-shadow: 0 0 2px 0 $black-shade-12, 0 2px 2px 0 $black-shade-24
        display: flex
        flex-direction: column
        left: 50%
        outline: none
        position: absolute
        top: 50%
        transform: translate(-50%, -50%)
        width: 378px

    &__header
        align-items: center
        display: flex
        justify-content: flex-end
        padding: 12px 11px 0

    &__close-button
        color: $white
        cursor: pointer
        z-index: 1

    &__content
        color: $white
        font-weight: bold
        margin: -18px auto 21px
        text-align: center
        width: 90%

    &__icon
        color: $white
        margin-bottom: 8px

        .material-icons
            font-size: 48px

    &__content &__notification
        color: $white
        font-size: 16px
        line-height: 21px
        text-align: left
        width: 90%

