@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_buttons.sass'


.s700-registration-wrapper
    button
        @extend %vyne-button-blue-default
        display: block
        margin: 15px auto 0
        text-transform: none
