@import 'src/modules/shared/_colors.sass'

$module-name: 'nav-session-timer'

.#{$module-name}
    &__container
        background: $error-red
        border-radius: 25px
        display: flex
        font-size: 14px
        padding: 10px 15px

    &__msg
        display: inline-block
        line-height: 25px
        padding-left: 6px
