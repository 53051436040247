@import 'src/modules/dexvoice/_common.sass'
@import 'src/modules/shared/_widget.sass'
@import 'src/modules/shared/_colors.sass'


.agent-metrics
    display: flex

    .column
        flex: 0 1 auto
        margin-right: 10px

        &:last-child
            margin-right: 0

        .metrics
            @extend %metric-card
            box-sizing: border-box
            padding: 14px 18px
            position: relative
            width: 260px

            .appointment-count
                font-size: 24px
                margin-bottom: 8px
                margin-top: 8px

                &.empty
                    color: $black-shade-70
                    font-size: 16px

            .appointment-date
                .date
                    color: $black-shade-62
                    font-size: 16px
                    margin-bottom: 8px

                .title
                    font-size: 10px
                    margin-bottom: 4px
                    text-transform: uppercase

            .heading
                font-size: 24px
                margin-bottom: 8px

            .graphs
                display: flex
                flex-direction: row
                margin-top: 16px

                .healthy,
                .unhealthy
                    color: $black-shade-87
                    flex: 1

                    .bar
                        border-top: 4px solid $black

                    .count
                        font-size: 16px

                    .label
                        font-size: 10px
                        padding: 4px 0
                        text-transform: uppercase

                .healthy
                    .bar
                        border-color: $success-green

                .unhealthy
                    .bar
                        border-color: $error-red

            .manage
                color: $base-blue
                position: absolute
                right: 0
                top: 0

            .range-select
                color: $black-shade-66
                font-size: 16px
