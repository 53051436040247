@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$hover-btn-bg: darken($link-blue, 10)
$hover-red-btn-bg: darken($alert-red, 10)

.practice-logo-tab
    .practice-logo-updated-successfully,
    .practice-logo-error
        border: 1px $clear-green solid
        box-sizing: border-box
        color: $dark-gray
        display: flex
        font-size: 16px
        margin-bottom: -5px
        margin-top: 5px
        padding: 16px 20px
        width: 100%

        .info-payment-icon
            color: $clear-green
            margin-right: 12px
            vertical-align: middle

        span
            line-height: 26px

    .practice-logo-spinner
        align-items: center
        display: flex
        flex-direction: column
        height: 100%
        justify-content: center
        min-height: 144px
        position: relative
        width: 100%

        &__overlay
            background-color: $black-shade-12
            bottom: -22px
            left: -15px
            position: absolute
            right: -16px
            top: -17px
            z-index: 10

        &__circural-bar
            color: $main-blue
            left: 50%
            position: absolute
            top: 28%
            z-index: 10000

    .practice-logo-info-text
        display: block
        line-height: 1.6
        margin-bottom: 16px

        .practice-logo-tooltip
            display: inline-block
            line-height: 1.24
            margin: 0
            padding: 0

            .custom-tooltip
                display: inline-block

            .custom-tooltip__children
                display: inline-block

            .custom-tooltip__popover--top-left
                transform: translateX(-74%)

            .tooltip-icon
                display: inline-block
                font-size: 21px
                left: 2px
                position: absolute
                top: -2px

    .practice-logo-error
        border-color: $alert-red
        color: $alert-red

        .info-payment-icon
            color: $alert-red
            font-weight: 900

    .practice-logo-section
        p
            color: $dark-gray

    .practice-logo-helper-text
        align-items: center
        display: flex
        flex-direction: row

    .practice-logo-header
        color: $soft-black
        font-size: 24px
        font-weight: 500

    h2
        color: $dark-gray
        font-weight: 400

    h3
        color: $dark-gray
        font-size: 18px
        font-weight: 600
        margin: 25px 0 10px

    .practice-logo-bg-text
        margin-top: 5px

    .practice-logo-option
        background: transparent
        border: 1px $link-blue solid
        border-radius: 6px
        color: $link-blue
        cursor: pointer
        font-size: 16px
        font-weight: 400
        outline: 0
        padding: 6px 19px
        text-transform: uppercase

        &:hover
            background-color: $shortcuts-white

        &--selected
            background-color: $link-blue
            color: $white

            &:hover
                background-color: $hover-btn-bg

    .practice-logo-bg-wrapper
        display: flex
        justify-content: flex-start

    .practice-logo-bg-item
        margin-right: 24px
        text-align: center

    .practice-logo-bg-top
        align-items: center
        display: flex
        height: 105px
        justify-content: center
        margin-bottom: 16px
        width: 160px

        img
            height: auto
            max-height: 80%
            max-width: 80%
            width: auto

    .practice-logo-bg-top-dark
        background-color: $soft-black

        h2
            color: $white

    .practice-logo-bg-top-neutral
        background-color: $grey-66

        h2
            color: $white

    .practice-logo-bg-top-light
        background-color: $white

    .save-logo-bg-button
        background-color: $text-blue
        box-shadow: none
        display: block
        font-size: 16px
        font-weight: 400
        height: 52px
        margin: 30px auto 0
        width: 158px

        &:hover
            background-color: $hover-btn-bg

        span
            margin-top: 3px

        svg
            color: $white

    .save-logo-bg-button--disabled
        background-color: $black-shade-12

.practice-logo-wrapper
    align-items: center
    color: $lighter-black
    display: flex
    height: 100%
    justify-content: center
    padding: 20px 10px 10px

    .practice-logo-info-text
        display: block
        font-size: 16px
        line-height: 1.6
        padding: 8px 24px

        .practice-logo-tooltip
            display: inline-block
            line-height: 1.24
            margin: 0
            padding: 0

            .custom-tooltip
                display: inline-block

            .custom-tooltip__children
                display: inline-block

            .custom-tooltip__popover--bottom-left
                transform: translateX(-74%)

            .tooltip-icon
                display: inline-block
                font-size: 21px
                left: 2px
                position: absolute
                top: -2px

    .practice-logo
        background: $white
        border-radius: 6px
        max-height: 90%
        overflow: auto
        position: relative
        width: 644px

        &__modal-container
            width: 644px

        &--message
            border-radius: $default-border-radius
            padding: 10px 10px 24px
            width: 678px

        &__close-button
            position: absolute
            right: 7px
            top: 8px

            button
                background-color: transparent
                border-radius: 0

                &:hover
                    box-shadow: 0 0 2px 0 $black-shade-50

            &-icon
                color: $dark-gray
                padding: 0

            .material-icons
                font-size: 24px

        &__header
            border-bottom: 1px solid $tab-border
            color: $dark-gray
            font-size: 22px
            font-weight: bold
            padding: 8px 36px 10px 24px

        &__modal-title
            color: $label-gray
            margin-bottom: 14px
            margin-top: 30px
            text-align: center

        &__modal-caption
            font-size: 24px

        &__progress
            margin: 20px auto 45px
            position: relative
            width: 162px

        &__button-container
            display: flex
            justify-content: center
            margin-bottom: 22px

            &--message
                margin-bottom: 0
                margin-top: 4px

        &__save-button
            background-color: $link-blue
            border: 1px solid $link-blue
            color: $white
            font-size: 16px
            font-weight: 400
            margin: 4px 12px
            overflow: hidden
            padding: 4px 20px
            position: relative

            &:hover
                background-color: $hover-btn-bg

        &__button
            background-color: $white
            border: 1px solid $link-blue
            color: $link-blue
            font-size: 16px
            font-weight: 400
            margin: 4px 12px
            overflow: hidden
            padding: 4px 20px

            &:hover
                background-color: $shortcuts-white

        &__delete-button
            background-color: $error-red
            border: 1px solid $error-red
            color: $white
            font-size: 16px
            font-weight: 400
            margin: 4px 12px
            overflow: hidden
            padding: 4px 20px
            position: relative

            &:hover
                background-color: $error-red
                border: 1px solid $error-red
                color: $white

        &__button-spinner
            color: $white
            position: absolute

        &__message-header
            align-items: center
            color: $dark-gray
            display: flex
            flex-direction: column
            margin: 0 auto
            text-align: center

        &__message-header-icon
            font-size: 48px

            &--success
                color: $clear-green

            &--error
                color: $error-red

        &__message-header-title
            color: $grey-47
            font-size: 24px
            font-weight: bold
            line-height: 32px
            padding-top: 2px
            white-space: pre-line

        &__message-header-subtitle
            font-size: 16px
            line-height: 32px

        &__error-message
            align-items: center
            border: 1px solid $error-red
            color: $dark-grey
            display: flex
            font-weight: bold
            margin: 54px 23px 72px
            padding: 17px 15px

        &__error-icon
            color: $error-red
            margin-right: 8px

        &__error-text
            font-weight: bold
            line-height: 1.8em

        &__uploded-image
            margin: 45px 0 12px
            text-align: center

            & img
                max-height: 200px
                max-width: 90%

        &__current-logo

            &--hidden
                display: none

            h3
                font-size: 1.25em
                font-weight: bold
                margin-bottom: 16px
                margin-top: 13px

            p
                margin-bottom: 20px
                margin-top: 3px

        &__filedrop
            align-items: center
            background-color: $grey-f5
            border: 1px dashed $grey-97
            cursor: pointer
            display: flex
            height: 88px
            justify-content: center
            margin: 20px auto 36px
            width: 245px

            &--image
                text-align: center

            img
                max-height: 88px

        &__placeholder
            align-items: center
            color: $link-blue
            display: flex
            justify-content: center

            &:hover
                border-color: $link-blue

            &--hover
                border-color: $link-blue

            .material-icons
                font-size: 1em
                margin-right: 5px

        &__placeholder-action
            align-items: center
            display: flex
            justify-content: center

            &:hover
                color: lighten($link-blue, 10)

        &__logo-actions
            border-top: 1px solid $tab-border
            display: flex
            justify-content: center
            margin: 16px 0 0
            padding: 8px 24px

        &__cancel-btn
            border: 1px solid $grey-97
            border-radius: 4px
            color: $main-blue
            font-size: 16px
            font-weight: 500
            height: 36px
            margin: 10px 12px
            padding: 6px 21px

        &__yes-btn
            background-color: $alert-red
            border: 1px solid $alert-red
            border-radius: 4px
            box-shadow: none
            color: $white
            font-size: 16px
            font-weight: 500
            height: 36px
            margin: 10px 12px
            padding: 6px 21px

            &:hover
                background-color: $hover-red-btn-bg

        &__logo-action
            align-items: center
            color: $link-blue
            cursor: pointer
            display: inline-flex
            font-size: 13px
            margin-right: 39px

            &:hover
                color: lighten($link-blue, 10)

            .material-icons
                font-size: 16px
                margin-right: 4px

        &__notification
            align-items: center
            border: 1px solid $light-green
            display: flex
            font-weight: bold
            margin: 54px 20px 110px
            padding: 32px 21px

            .material-icons
                color: $light-green
                font-size: 20px
                margin-right: 9px

        &__attention
            padding: 60px 20px 10px
            text-align: center

        &__attention-icon
            color: $error-red
            font-size: 48px
            text-align: center

        &__await
            align-items: center
            display: flex
            height: 220px
            justify-content: center
            text-align: center
            width: 100%

        .custom-tooltip__popover
            font-size: 14px
