@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'

$module-name: scheduling-error-message

.#{$module-name}
    width: 100%

    &__wrapper
        align-items: flex-start
        background: $error-red
        color: $white
        display: flex
        font-size: 16px
        font-weight: bold
        padding: 10px

        &--warning
            background: $alert-red

    &__message
        max-width: 85%
        padding-left: 5px

    &__submit
        align-items: center
        display: flex
        justify-content: center
        margin: 15px 0

    & &__submit-button
        +secondary-button(155px)

        .material-icons
            font-size: 1.1em
            margin-left: 8px

    & &__share-appointment-button
        +primary-button(100px)
        line-height: 1.4em
        white-space: normal
        width: 215px
