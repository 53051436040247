@import '../../shared/_colors.sass'
@import '../../shared/_constants.sass'

$module-name: direct-scheduling-appointment

.#{$module-name}
    box-sizing: border-box
    height: 100%
    overflow-x: auto
    padding: 7px 8px 12px
    width: 100%

    &__heading
        color: $label-gray
        font-size: 1.2em
        font-weight: bold
        margin: 5px 0 10px

    &__tabs
        display: flex
        margin-bottom: 15px

    &__tab
        align-items: center
        border: 1px solid $input-border
        color: $link-blue
        cursor: pointer
        display: flex
        height: 40px
        justify-content: center
        text-align: center
        text-transform: none
        width: 50%

        &:last-child
            border-radius: 0 $default-border-radius $default-border-radius 0

        &:first-child
            border-radius: $default-border-radius 0 0 $default-border-radius

        &--selected
            background: $link-blue
            color: $white
