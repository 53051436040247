@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_mixins.sass'
@import 'src/modules/shared/_constants.sass'


$module-name: chat-tile-header

.#{$module-name}
    align-items: flex-start
    background-color: $text-blue
    box-sizing: border-box
    color: $white
    display: flex
    flex: 1
    height: rem(64px)
    justify-content: space-between
    max-height: rem(64px)
    min-height: rem(64px)
    padding: rem(8px 11px 0)

    &__header-left
        max-width: calc(100% - 30px)

    &__header-line
        align-items: center
        display: flex

    &__practice
        align-items: center
        display: flex

    &__chat-menu
        background: $white
        border-radius: $default-border-radius
        box-shadow: $box-shadow
        padding: 8px 8px 16px
        width: 244px

    .#{$module-name}__chat-menu &__end-button
        background-color: $error-red
        border-radius: 4px
        color: $white
        padding: 8px 16px
        width: 100%

        &:hover,
        &:focus
            background-color: $error-red

    .#{$module-name}__chat-menu &__leave-button
        background-color: $main-blue
        border-radius: 4px
        color: $white
        padding: 6px 8px
        width: 100%

        &:hover,
        &:focus
            background-color: $main-blue-hover

    &__patient-name
        align-items: center
        display: inline-flex
        flex-direction: row
        max-width: calc(100% - 24px)

        .editable-inline-text__text-input
            min-width: 40px
            width: auto

    &__info-wrapper
        display: inline-block

        > button
            height: auto
            width: auto

        .icon
            color: $white
            font-size: 19px

    &__online-status-wrapper
        align-items: center
        display: flex
        margin-left: 8px

    &__online-status
        font-size: 12px

    &--online
        color: $success-green

    &--offline
        color: $tab-border

    &__line
        &:not(:first-child)
            margin-top: 2px

    & &__menu-button
        height: auto
        width: auto

        .icon
            color: $white

    &__footer-button
        align-items: center
        cursor: pointer
        display: flex
        flex-direction: row

        &--ban
            color: $error-red
            margin-bottom: 24px

        .material-icons
            font-size: 1em
            margin-left: 4px

    &__alert
        top: 25%
        @media screen and (min-width: $small-width)
            width: 490px


    &__alert-footer
        justify-content: center
        margin-bottom: 10px
        padding: 5px 17px 42px

    &__alert-content
        p
            font-size: 16px
            font-weight: normal
            margin: 0

        h4
            font-size: 24px
            font-weight: bold
            margin: 0.5em 0

    &__location-info-empty
        padding: 20px

    &__location-list
        min-width: 196px
        padding: 16px 20px

    &__location-item
        display: flex
        justify-content: space-between
        line-height: 24px
        padding: 4px

    &__location-item-key
        font-size: 0.875rem
        padding-right: 4px

    &__location-item-value
        font-size: 0.875rem
