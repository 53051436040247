@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'

$module-name: referral-production-values

.#{$module-name}
    &__body
        border-bottom: 1px solid $input-border
        border-top: 1px solid $input-border
        max-width: 99%
        padding-bottom: 10px
        padding-top: 14px

    &__field-static
        margin-bottom: 12px

    &__field-label
        color: $label-gray
        font-size: 12px
        line-height: 18px

    &__field-value
        color: $lighter-black
        font-size: 16px

    &__title
        color: $grey-55
        font-size: 20px
        margin-bottom: 12px

    &__table
        border-bottom: 1px solid $input-border
        border-top: 1px solid $input-border
        display: flex
        margin-right: 20px
        padding-bottom: 16px
        padding-top: 16px

    &__cell
        width: 14.2%

    &__cell-title
        color: $label-gray
        font-size: 12px
        font-weight: 500
        padding-left: 0

    &__cell-value,
    &__cell-value-bottom
        color: $dark-gray
        font-size: 16px
        font-weight: 400
        padding-bottom: 16px
        padding-left: 0
        padding-top: 2px

    &__cell-value-bottom
        padding-bottom: 0

    &--bold
        font-weight: bold
