// sass-lint:disable no-important, class-name-format, pseudo-element
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: custom-datepicker

.#{$module-name}
    .MuiFormControl-root
        font-size: 12px
        margin-top: 18px
        width: 100%

    .MuiIconButton-root
        width: 32px

        &:hover
            background-color: transparent

    .MuiTouchRipple-root
        display: none

    .MuiInput-root
        background-color: $white
        border: 1px solid $input-border
        border-radius: 6px
        height: 40px
        margin-top: 0

    .MuiInput-formControl
        align-items: center !important
        display: flex
        padding-left: 10px

    .Mui-error
        border-color: $error-red

    .MuiInput-underline
        &:after
            display: none

        &:before
            display: none

    .MuiInput-error
        border-color: $error-red

    .MuiInputLabel-root
        color: $label-gray
        margin-left: 5px
        margin-top: -15px

    .MuiSvgIcon-root
        color: $black

    .MuiInputLabel-formControl
        font-size: 12px
        transform: none

    .MuiInputLabel-shrink
        transform: none

    .MuiFormLabel-root
        &:focused
            color: $label-gray
            font-size: 12px

        &:error
            color: $error-red

    .MuiFormHelperText-root
        color: $error-red
        margin-left: 5px
        margin-top: 5px

        &:error
            color: $error-red

    .MuiInputAdornment-positionEnd
        margin-left: 0


.MuiPickersDay-daySelected,
.MuiPickersToolbar-toolbar
    background-color: $main-blue !important

.MuiPickersModal-dialogRoot
    .MuiButton-root
        color: $main-blue

