@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-additional-comments

.#{$module-name}

    .radio-root
        height: 30px
        padding: 0
        width: 30px

    &__title
        color: $grey-47
        font-size: 18px
        font-weight: 500
        margin-bottom: 0
        margin-top: 0

    &__radio-buttons-wrapper
        display: flex
        padding: 4px 0 0

    &__option-wrapper
        display: flex
        flex-direction: row
        padding-right: 12px

    &__label-wrapper,
    label
        align-items: center
        display: flex
        flex-direction: row
        padding-left: 2px

    label
        cursor: pointer

    &__label-text
        font-size: 16px
        line-height: 16px
