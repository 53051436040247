@import 'src/modules/shared/_colors.sass'

$module-name: 'ban-user-modal'

.#{$module-name}
    margin-top: 0
    padding: 25px

    &__body
        margin-top: 0
        padding: 25px

    &__modal-contents
        background-color: $white
        box-shadow: 0 4px 12px 0 $shadow-gray
        margin: auto
        position: relative
        top: 10%
        width: 50%

    &__ban-info
        align-items: center
        display: flex
        flex-direction: row
        margin-bottom: 24px
        margin-top: 40px

    &__ip-address-title
        float: left
        font-size: 18px
        font-weight: 500
        margin-left: 40%
        margin-top: 18px

    &__info
        color: $error-red

    &__message-container
        display: flex

        h1
            margin-top: 5px

    &__icon
        cursor: pointer
        margin-right: 0

    &__ban-chatter-button-container
        color: $error-red
        display: flex
        margin-bottom: 10px
        margin-left: 16px
        margin-top: 18px

    & &__cancel-button
        border: 1px solid $black-shade-24
        border-radius: 4px
        color: $main-blue
        height: 38px
        margin-left: auto
        padding: 8px 16px

    & &__ban-chatter-button
        background-color: $error-red
        border-radius: 4px
        color: $white
        height: 38px
        margin-left: 16px
        margin-right: auto
        padding: 8px 16px

        &:disabled
            background-color: $black-shade-12
            color: $black-shade-24

    &__close-modal-button
        float: right
        height: 24px
        position: relative
        right: 15px
        top: 15px

        &:hover
            box-shadow: 0 0 2px 0 $black-shade-50

    &__custom-field__container
        display: flex
        position: relative
        width: 100%

    &__info-field
        font-size: 14px
        width: 30%

    &__info-span
        color: $last-message


