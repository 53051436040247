
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: global-settings-modal

.#{$module-name}
    background-color: $white
    border-radius: $default-border-radius
    color: $dark-gray
    display: flex
    flex-direction: column
    left: 50%
    outline: none
    padding: 0
    position: absolute
    top: 50%
    transform: translate(-50%, -50%) !important // sass-lint:disable-line no-important
    width: 848px

    &__close-modal-button
        cursor: pointer
        height: 24px
        position: absolute
        right: 10px
        top: 10px
        width: 24px
        z-index: 1000

    &__title
        border-bottom: 1px solid $black-shade-12
        color: $lighter-black
        font-size: 24px
        font-weight: bold
        line-height: 28px
        padding: 10px 22px 8px

    &__contents
        background-color: $light-gray-blue-ting
        padding: 10px 16px 8px

    &--bold
        font-weight: bold

    &__subtitle
        font-size: 16px
        line-height: 22px

    &__subtitle-2
        font-style: italic
        line-height: 22px

    &__footer
        padding: 22px 0 24px
        text-align: center

    &__save-button
        box-shadow: none
        height: 36px
        padding: 6px 18px

        &:hover
            background-color: $primary-hover-bg
            box-shadow: none

    &__table-item,
    &__table-item-first
        font-size: 16px
        font-weight: normal
        line-height: 19px
        padding: 12px 16px 22px 0

        .custom-multiselect-v2__label,
        .custom-multiselect__label
            font-weight: 500
            line-height: 11px
            margin: 0

    &__table-item-first
        color: $main-blue
        font-size: 18px
        font-weight: 500
        padding: 12px 24px 12px 0

    & &__size30
        flex-basis: 30%
        max-width: 30%

        @media (max-width: $medium-width)
            flex-basis: 50%
            max-width: 50%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    & &__size22
        flex-basis: 22%
        max-width: 22%

        @media (max-width: $medium-width)
            flex-basis: 50%
            max-width: 50%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    & &__size20
        flex-basis: 20%
        max-width: 20%

        @media (max-width: $medium-width)
            flex-basis: 50%
            max-width: 50%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    & &__size18
        flex-basis: 17%
        max-width: 18%

        @media (max-width: $medium-width)
            flex-basis: 50%
            max-width: 50%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    & &__size15
        flex-basis: 14%
        max-width: 14%

        @media (max-width: $medium-width)
            flex-basis: 46%
            max-width: 46%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%



    &__table-item-before-last
        font-size: 16px
        font-weight: normal
        line-height: 19px
        padding: 12px 0 16px

    &__table-item-last
        font-size: 16px
        font-weight: normal
        line-height: 19px
        padding: 12px 0 16px 16px

    &__table-item-checkbox
        padding-left: 24px
        padding-top: 30px

    &__table-header-item
        color: $label-gray
        font-size: 14px
        font-weight: 500
        line-height: 14px
        padding: 14px 0 14px 2px

    &__table-header-item-details
        display: flex
        justify-content: space-between

    &__table-row,
    &__list-row
        border-bottom: 0

    &__checkbox
        align-items: center
        display: flex
        flex-direction: column
        padding: 18px 4px 22px 0

        .custom-multiselect__label-hovered
            color: $main-blue

        @media (max-width: $medium-width)
            align-items: flex-start


@media screen and (max-width: $medium-width)
    .#{$module-name}
        width: 90%

        &__table-item,
        &__table-item-first,
        &__table-item-before-last,
        &__table-item-last
            padding: 12px 24px 12px 0

        &__table-item-checkbox
            padding-left: 16px
            padding-top: 30px
