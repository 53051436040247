@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'


.editable-inline-text
    display: inline-block
    padding-right: 6px

    &.editing
        color: $soft-grey
        outline: 2px solid $outline-blue
        outline-offset: 1px

    .icon-wrapper
        cursor: pointer
        display: inline-block

        .icon-button
            font-size: rem(16px)
            padding-left: rem(8px)
            padding-top: rem(3px)

            &--primary
                color: $link-blue

                &:hover
                    color: lighten($link-blue, 10)

.editable-inline-text__text-input
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 185px
    word-break: break-all

    &.editing
        background-color: $white
        color: $soft-grey
        outline: 2px solid $outline-blue
        outline-offset: 1px
        white-space: normal
