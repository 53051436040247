// sass-lint:disable no-important

@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: shortcut-modal-body

.#{$module-name}
    &__container
        background-color: $white
        border-radius: $default-border-radius
        color: $dark-gray
        display: flex
        flex-direction: column
        left: 50%
        outline: none
        padding: 10px 10px 24px
        position: absolute
        top: 50%
        transform: translate(-50%, -50%) !important
        width: 678px

    &__header
        align-items: center
        display: flex
        justify-content: flex-end
        padding: 0
        position: absolute
        right: 10px

    &__header &__close-button
        cursor: pointer
        height: 24px

        &:hover
            box-shadow: 0 0 2px 0 $black-shade-50

    &__content
        display: flex
        flex-direction: column
        margin: 0 auto
        text-align: center

    &__content-edit
        padding: 8px 16px 4px

        .custom-field__container
            margin: 8px 0 0

    &__content &__icon
        color: $error-red
        margin-right: 2px
        padding-top: 16px

        .material-icons
            font-size: 48px

    &__content &__title
        color: $grey-47
        font-size: 24px
        font-weight: bold
        line-height: 32px
        padding-top: 2px

    &__content-edit &__main-title
        font-size: 28px
        font-weight: bold
        margin: 8px 0

    &__content &__notification
        font-size: 16px
        line-height: 32px
        text-align: center
        width: 100%

    &__footer,
    &__footer-edit
        align-items: center
        display: flex
        justify-content: center
        padding-top: 4px

    &__footer-edit
        justify-content: space-between
        padding: 6px 6px 0

    &__footer &__cancel_btn,
    &__footer-edit &__cancel_btn
        border: 1px solid $main-blue
        color: $main-blue
        font-size: 16px
        font-weight: 500
        height: 36px
        margin: 10px
        padding: 6px
        width: 88px

    &__footer &__yes_btn,
    &__footer-edit &__yes_btn
        background-color: $alert-red
        border: 1px solid $alert-red
        box-shadow: none
        color: $white
        font-size: 16px
        font-weight: 500
        height: 36px
        margin: 10px
        padding: 6px 21px

    &__footer-edit &__yes_btn
        background-color: $main-blue
        border: 1px solid $main-blue

        &:not(:disabled)
            &:hover
                background-color: $primary-hover-bg

        &:disabled
            background-color: $black-shade-12
            border: 1px solid $black-shade-12
            color: $black-shade-26


@media screen and (max-width: $small-width)
    .#{$module-name}
        &__container
            max-width: 482px
            width: calc(97% - 38px)
