@import 'src/modules/shared/_core.sass'
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_mixins.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: survey-location-information

.#{$module-name}
    padding-top: 48px

    .survey-section__wrapper
        padding: 0 0 24px

    p
        margin-top: 1px
        white-space: pre-line

    h4
        font-weight: bold
        margin-bottom: 6px
        margin-top: 1px

    &__position
        align-items: flex-end
        display: flex

        .custom-field__container
            flex-grow: 1
            margin-right: 15px
            min-height: 40px

    &__position-helper
        align-self: flex-start
        display: inline-block
        font-size: 1.2em
        padding-bottom: 8px
        padding-top: 28px
        width: 23px

.location-information-country
    margin-top: 0
