@import 'src/modules/dexvoice/_common.sass'
@import 'src/modules/shared/_widget.sass'
@import 'src/modules/shared/_colors.sass'


.dexvoice-agents-health
    @extend %metric-card

    flex: 1
    margin-bottom: 12px
    padding: 14px 18px

    .agent-count
        font-size: 24px
        margin-bottom: 16px

    .graphs
        display: flex
        flex-direction: row

        .healthy,
        .unhealthy
            color: $black-shade-87
            flex: 1

            .bar
                border-top: 4px solid $black

            .count
                font-size: 16px

            .label
                font-size: 10px
                padding: 4px 0
                text-transform: uppercase

        .healthy
            .bar
                border-color: $success-green

        .unhealthy
            .bar
                border-color: $error-red
