@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$tooltip-deviation: 100px
$module-name: simplifeye-custom-tooltip

.#{$module-name}
    position: relative
    white-space: normal
    width: min-content

    & &__icon-wrapper
        padding: 0

    & &__info-icon
        color: $main-blue
        padding: 0

    & &__question-icon
        color: $error-red
        padding: 0 0 0 4px

    &__close-icon
        cursor: pointer
        opacity: 0.65
        position: absolute
        right: 6px
        top: 6px
        transition: opacity 0.3s


