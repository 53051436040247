@import 'src/modules/shared/_colors.sass'

$module-name: self-scheduling-modal-background-color-style

.#{$module-name}

    &__wrapper
        margin-bottom: 5px
        width: 200px

    &__toggle
        width: 100%
