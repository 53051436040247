// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'

$hover-btn-bg: darken($link-blue, 10)

$padding: 26px
$horizontal-padding: 30px
@mixin pad-content
    padding-left: $horizontal-padding
    padding-right: $horizontal-padding


.manage-products-modal
    width: 400px !important

    .manage-products
        .form
            padding-bottom: $padding
            padding-top: $padding

            .selector
                display: flex
                margin-bottom: 20px
                margin-top: 12px

                .custom-multiselect__dropdown
                    position: static

                .product-select-form-control
                    padding-bottom: 2px

                .add-product
                    align-self: flex-start
                    margin-left: 12px
                    margin-top: 19px
                    min-width: 64px

                    &.can-add
                        background: $main-blue
                        box-shadow: none

                        &:hover
                            background: $hover-btn-bg

                .product-select
                    min-width: 8em

                    > div > div
                        background: $field-fill-hover

                @include pad-content

            .products
                &:last-child
                    border-bottom: 1px solid $tab-border

                .product
                    @include pad-content
                    align-items: center
                    border-top: 1px solid $tab-border
                    display: flex

                    .active
                        flex: 0 1 auto

                    .name
                        flex: 1 1 auto
                        line-height: 48px

                    .remove
                        flex: 0 1 auto

            .title
                @include pad-content
                color: $dark-gray
                font-size: 24px

    .circular-progress-loader
        bottom: 50%
        left: 40%
        position: absolute

    .circular-progress-spinner
        color: $main-blue

    .manage-products__salesforce-type
        align-content: center
        align-items: center
        display: flex
        padding-left: 20px
        padding-top: 8px

    .manage-products__radio-wrapper
        display: flex

.manage-products__wrapper
    align-items: center
    display: flex

