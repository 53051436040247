@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: survey-surveys
$question: survey-question
$section: survey-section
$procedures-section: survey-procedures-section

.#{$module-name}

    .#{$section}
        padding-top: 48px

    & .#{$procedures-section}
        padding-top: 5px

    .#{$section}__wrapper
        .#{$section}__subheading-wrapper
            padding-bottom: 0

    .#{$section}__subheading
        font-size: 18px
        font-weight: bold
        margin: 0

    & .#{$section} &__no-padding-top
        padding-top: 0

    & .#{$section} &__no-padding-bottom
        padding-bottom: 0

    .#{$question}
        padding: 8px 0

    .#{$question}__label
        align-items: center
        color: $dark-grey-43
        display: flex
        font-size: 14px
        font-weight: bold
        padding-bottom: 8px

    .#{$question}__label-invalid
        color: $red

    .#{$question}__label--big
        font-size: 18px

    .#{$question}__location-select
        padding-left: 12px

    .#{$question}__label-optional
        display: inline-block
        font-weight: normal
        padding-left: 4px

    .#{$question}__value
        color: $dark-gray
        font-size: 16px
        padding: 10px 0
        white-space: pre-line

    .#{$question}--input_field_multi_values

        .#{$question}__label
            border-bottom: 1px solid $tab-border

        .multi-value-input__value
            border-bottom: 1px solid $tab-border
            height: 46px
            padding-top: 8px

    & &__header-underline
        border-bottom: 1px solid $tab-border
        margin: 8px 0 12px
        padding-bottom: 8px

        h4
            margin-left: -8px

    & &__section-header
        color: $dark-gray
        font-size: 18px
        font-weight: 600
        margin: 8px 0
        padding: 0

        &--big
            color: $dark-gray
            font-size: 32px
            font-weight: 600
            margin: 0
            padding: 0
            text-transform: capitalize

    & &__section-subheader
        color: $dark-gray
        font-size: 20px
        font-weight: 600
        margin: 8px 0
        padding: 0 12px

    &__grid-item
        padding-bottom: 0

