@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


*
    font-family: 'Roboto', sans-serif

html,
body
    margin: 0
    padding: 0

button
    border: 0

.app-root
    > div
        margin: 0 auto
        max-width: 80%

        @media screen and (max-width: $critical-width)
            max-width: 90%


.pst-modal
    > .contents
        @media screen and (max-width: $critical-width)
            max-width: 90%

        background: $white
        border-radius: $default-border-radius
        left: 0
        margin: 0 auto
        max-height: calc(100vh - 50px)
        min-height: 100px
        outline: none
        overflow-x: hidden
        overflow-y: auto
        position: absolute
        right: 0
        top: 40px
        width: 80%

        .bottom-save-button
            background: $tab-fill
            color: $soft-black
            display: block
            font-size: 14px
            font-weight: bold
            padding: 20px
            text-align: center
            text-transform: uppercase
            width: 100%

            &.can-save
                background: $main-blue
                color: $white
                cursor: pointer

            &:not(:disabled)
                &:hover
                    background: $primary-hover-bg
                    color: $white

        > .close-modal-button
            cursor: pointer
            font-size: 20px
            height: 24px
            position: absolute
            right: 10px
            top: 10px
            width: 24px
            z-index: 1000

            &:hover
                box-shadow: 0 0 2px 0 $black-shade-50
