@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: custom-multiselect

.#{$module-name}
    position: relative
    width: 100%

    &:hover
        .#{$module-name}__dropdown-button
            background-color: $shortcuts-white
            border-color: $main-blue

        .#{$module-name}__label
            color: $main-blue

    &--active
        .#{$module-name}__dropdown-button
            border-color: $main-blue

        .#{$module-name}__label
            color: $main-blue

    &--error
        .#{$module-name}__dropdown-button
            border-color: $error-red

        .#{$module-name}__label,
        .#{$module-name}__helper-text
            color: $error-red

    &--disabled
        pointer-events: none

        .#{$module-name}__dropdown-button
            background-color: $white
            border-color: $very-soft-gray
            color: $label-gray

        .#{$module-name}__dropdown-arrow
            color: $very-soft-gray

    &__container
        display: flex
        position: relative
        width: 100%

    &__label
        color: $label-gray
        font-size: 12px
        margin: 5px

    &__label-required
        color: $label-gray

    &__helper-text
        color: $label-gray
        display: flex
        font-size: 12px
        margin: 5px

    &__dropdown-button
        align-items: center
        background: $white
        border: 1px solid $input-border
        border-radius: $default-border-radius
        box-sizing: border-box
        cursor: pointer
        display: flex
        font-size: 16px
        height: 40px
        justify-content: space-between
        outline: none
        padding: 0 0 0 13px
        white-space: nowrap
        width: 100%

    &__dropdown-text
        overflow: hidden
        text-align: left
        text-overflow: ellipsis
        width: calc(100% - 38px)

    &__dropdown-placeholder
        color: $label-gray
        display: block
        max-width: calc(100% - 35px)
        opacity: 1
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    &__dropdown-arrow
        font-size: 38px

    &__dropdown
        background: $white
        border: 1px solid $input-border
        border-radius: $default-border-radius
        border-width: 1px
        box-sizing: border-box
        display: block
        list-style: none
        margin: 3px 0 0
        max-height: 320px
        overflow: auto
        padding: 5px 3px
        position: absolute
        width: 100%
        z-index: 1003

    &__dropdown-item
        font-size: 14px
        min-height: 30px
        word-break: break-word

    &__dropdown-option-label
        border-radius: 3px
        cursor: pointer
        display: block
        padding: 4px 8px

        &:first-child
            margin-top: 4px

        &:hover
            background: $black-shade-8

        &--checked
            background: $black-shade-12

            &:hover
                background: $black-shade-12

        &--disabled
            cursor: default
            opacity: 0.5

            &:hover
                background: none

    &__search
        align-items: center
        background-color: $table-row-hover
        border: 1px solid $input-border
        border-radius: $default-border-radius
        display: flex
        font-size: 1rem
        height: 36px
        margin: 2px 4px
        padding: 0 4px
        width: auto

        .material-icons
            max-width: 24px
            min-width: 24px

    &__search-field
        background-color: transparent
        border: 0
        color: $soft-black
        display: block
        font-size: 0.9rem
        height: 36px
        margin: 0
        outline: none
        padding: 0 4px
        width: calc(100% - 50px)

    &__search-clear
        color: $soft-gray
        cursor: pointer
        margin-top: 2px

    &__loading
        margin-top: 12px
        position: absolute
        right: 34px
