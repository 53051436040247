@import 'src/modules/shared/_colors.sass'


.referral-status-modal-header
    font-size: 18px
    padding: 11px 0 10px 23px

    .referral-status-modal-title
        margin: 0

.referral-status-modal-body
    .message-container
        background-color: $shortcuts-white
        padding: 25px

        h1
            margin-top: 5px

    .message-container-heading
        display: flex

    .message-information
        margin: 0 0 0 65px

    .material-icons
        font-size: 48px
        margin-right: 16px

    .success
        color: $grass-green


