// sass-lint:disable pseudo-element
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/shared/_mixins.sass'

$module-name: integrations
$primary-button-hover-bg: darken($main-blue, 10)

.#{$module-name}
    &__module
        color: $black-shade-87
        font-size: 14px
        max-height: 160px
        overflow-y: auto

        &:not(:last-child)
            margin-bottom: 14px

    &__module-name
        font-weight: bold

    &__last-sync
        color: $grey-69
        font-size: 12px
        margin-bottom: 8px
        margin-top: auto

    &__name-header
        margin: 0 0 6px

    .name-header
        margin-top: 4px

    &__field-wrapper
        display: flex
        max-width: 650px

    &__field
        margin-bottom: 11px
        max-width: 600px
        width: 90%

    &__action-icon
        color: $main-blue
        cursor: pointer
        padding-left: 16px
        position: relative
        top: 25px

        &:hover
            color: lighten($main-blue, 10)

    &__modules
        max-width: 472px

    &__add
        color: $text-blue
        cursor: pointer
        font-size: 16px

        &:hover
            color: lighten($text-blue, 10)

    &__modal-body
        box-sizing: border-box
        display: flex
        flex-direction: column
        max-height: 80vh
        min-height: 35vh
        overflow: auto
        padding: 22px 28px 23px

        p
            color: $label-gray
            font-size: 16px
            line-height: 24px

    &__footer
        align-items: center
        display: flex
        justify-content: center
        margin-top: auto
        padding: 20px 0 0

    &__footer &__submit-button
        +primary-button(100px)
        max-width: 98%
        padding-left: 12px
        padding-right: 12px
        white-space: normal

        &:hover
            &:not(:disabled)
                background-color: $primary-hover-bg

        &.can-save
            background: $main-blue
            color: $white
            cursor: pointer

    &__download
        background: $table-row-hover
        border: 1px solid $grey-97
        box-sizing: border-box
        color: $grey-69
        line-height: 30px
        margin: 20px auto
        max-width: 90%
        padding: 22px 32px
        width: 500px

    &__pairing-module
        margin-bottom: 33px

    &__pairing-title
        color: $dark-gray
        font-size: 24px
        margin: 11px 0 8px

        & + p
            color: $dark-gray
            font-size: 16px
            margin: 0 0 8px

    &__pair
        display: flex
        width: 100%

    &__pair-container
        margin-bottom: 11px
        position: relative

    &__pair-external,
    &__pair-internal
        flex: 1

    &__pair-internal
        margin-top: 8px

    &__pair-link
        align-items: center
        display: flex
        padding: 26px 17px 0

    &__secrets
        p
            margin: 5px 0
            padding: 0

    &__checkboxes
        display: flex

    &__checkbox
        display: inline-block

        .MuiFormControlLabel-label
            font-size: 0.8rem

    &__allow-writeback-loading
        padding: 10px

    &__allow-writeback
        align-items: center
        display: flex
        margin-top: -16px
        position: absolute

        label
            color: $lighter-black
            font-size: 14px
            white-space: nowrap


    &__loading-overlay
        align-items: center
        display: flex
        justify-content: center
        min-height: 360px

    &__agent-name-edit-icon
        color: $main-blue
        cursor: pointer
        font-size: 24px
        padding-left: 2px

        &:hover
            color: $main-blue-hover

    &__loading-agents-container
        align-items: center
        display: flex
        flex-direction: column
        height: 100%
        justify-content: center
        min-height: 148px
        position: relative
        width: 100%

    &__agents-spinner-overlay
        background-color: $black-shade-12
        bottom: -22px
        left: -15px
        position: absolute
        right: -16px
        top: -17px
        z-index: 10

    & &__agents-spinner
        color: $main-blue
        left: 50%
        position: absolute
        top: 28%
        z-index: 10000

    &__add-button-footer
        background: $tab-fill
        border-top: solid thin $tab-border
        display: flex
        height: 62px
        justify-content: center
        margin: 0 -20px -8px

        .button
            font-weight: bold
            line-height: 62px
            text-transform: uppercase

            .add-icon
                display: inline-flex
                padding-right: 2px
                position: relative
                top: -2px
                vertical-align: middle


        &:hover
            background: $main-blue
            cursor: pointer

            .button
                color: $white

    &__paginator-wrapper
        display: flex
        justify-content: center
        padding-bottom: 18px
        width: 100%

        .paginator
            margin-top: 24px


.integrations-tab
    .card-container
        display: flex
        flex-wrap: wrap
        margin-bottom: 12px
        margin-top: 10px
        min-height: 24px
        position: relative

        .card
            &.#{$module-name}__card
                background: $tab-fill-light
                border: 0
                display: flex
                flex-direction: column
                margin: 6px
                min-height: 300px
                position: relative
                width: 23.8%

                @media (max-width: $large-width)
                    width: 23.6%

                @media (max-width: $medium-width)
                    width: 47%


                &--pending
                    position: relative

                    &::before
                        background: $white-shade-50
                        bottom: 0
                        content: ''
                        left: 0
                        position: absolute
                        right: 0
                        top: 0
                        z-index: 1

                .buttons
                    bottom: 0
                    padding: 4px 8px 8px 0
                    position: absolute
                    right: 0

                    .button
                        font-size: 12px

                    .empty-button
                        width: 64px

                    .diagnostics-button-wrapper
                        display: flex

                    .custom-tooltip__popover--top-right
                        &:after
                            right: calc(80% - 12px)

                .buttons-space-between
                    display: flex
                    justify-content: space-between
                    width: calc(100% - 18px)

                .header
                    align-items: baseline
                    color: $black-shade-87
                    font-size: 28px
                    height: auto
                    line-height: 33px
                    overflow-y: auto
                    padding: 14px 16px 0
                    word-break: break-word

                    & + div
                        display: flex
                        flex-direction: column
                        flex-grow: 1

            &.#{$module-name}__card--active
                border-top: 5px solid $primary-lime

            &.#{$module-name}__card--inactive
                border-top: 5px solid $primary-red

    .integrations-search-bar-wrapper
        padding-bottom: 8px
        padding-top: 8px

    .custom__input
        height: 36px

    .custom-field__adornment
        height: 40px
        width: 53px
