@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: locations-list
$web-code-location: web-code-location

.#{$module-name}
    display: flex
    flex-direction: column

    &__search
        align-items: center
        background-color: $table-row-hover
        border: 1px solid $input-border
        border-radius: $default-border-radius
        display: flex
        font-size: 1rem
        height: 48px
        margin: 8px 0 12px
        padding: 0 8px 0 14px
        width: auto

    & &__search-icon
        color: $soft-grey
        font-size: 24px

        .material-icons
            max-width: 24px
            min-width: 24px

    &__search-field
        background-color: transparent
        border: 0
        color: $soft-black
        display: block
        font-size: 0.9rem
        height: 36px
        margin: 0
        outline: none
        padding: 0 4px
        width: calc(100% - 50px)

    &__search-clear
        color: $soft-grey
        cursor: pointer
        display: flex
        font-size: 16px

    & &__clear-icon
        font-size: 21px

    &__locations-container
        margin-bottom: 10px
        max-height: 245px
        overflow-y: auto

    & &__not-found
        color: $error-red
        font-size: 16px
        font-style: italic
        font-weight: bold
        line-height: 26px

    & &__loading-list
        font-size: 16px
        font-style: italic
        line-height: 26px

.#{$module-name}__transition-container
    margin-bottom: 20px
    width: 100%

    &.exit
        opacity: 1

    &.exit-active
        opacity: 0
        transition: opacity 3000ms

    &.exit-done
        display: none

.#{$web-code-location}
    border-bottom: 1px solid $input-border
    display: flex

    &__location-section
        display: flex
        width: calc(100% - 120px)

    & &__form-control-root
        align-items: flex-start
        margin: 0 4px 0 -8px

        .#{$module-name}__checkbox-root
            color: $text-blue
            height: 24px
            width: 24px

    & &__form-control-label
        +long-word-break
        padding-top: 1px

    & &__checkbox-root
        color: $link-blue
        height: 24px
        width: 24px

    &__label
        padding-top: 4px

    &__text
        color: $dark-gray
        margin: 0 2px
        white-space: normal

    &--text-bold
        font-weight: 500
