// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'


$module-name: staff-form-unstop-sms-notifications-modal

.#{$module-name}
    &__container
        background-color: $white
        color: $dark-gray
        display: flex
        flex-direction: column
        left: 50%
        outline: none
        position: absolute
        top: 50%
        transform: translate(-50%, -50%) !important
        width: 700px

    &__header
        align-items: center
        display: flex
        justify-content: flex-end
        padding: 15px

    &__header &__close-button
        cursor: pointer

    &__content
        display: flex
        margin: 0 auto
        text-align: center
        width: 75%

    &__content &__icon
        color: $error-red
        margin-right: 10px

        .material-icons
            font-size: 48px

    &__content &__title
        color: $dark-gray
        font-size: 24px
        line-height: 32px
        margin-bottom: 10px
        text-align: left

    &__content &__notification
        line-height: 24px
        text-align: left
        width: 90%

    &__footer
        align-items: center
        display: flex
        justify-content: center
        padding: 11px 6px 10px 24px

    &__footer &__cancel_btn
        border: 1px solid $link-blue
        color: $link-blue
        font-size: 16px
        font-weight: 500
        margin: 10px
