.analytics-frame
    height: 900px
    padding: 14px 18px
    position: relative
    width: 900px

.analytics-loader
    align-items: center
    display: flex
    justify-content: center
