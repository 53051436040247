.widget-style
    margin-left: 32px
    margin-top: 20px

    .widget-lower-left
        svg
            transform: scale(-1, 1)

    .radio-wrapper,
    .widget-style-wrapper,
    label
        align-items: center
        display: flex

    .radio-wrapper
        margin-bottom: 10px

    label
        cursor: pointer

    .widget-style-text
        font-size: 14px
        font-weight: 500
        line-height: 29px
        margin-left: 7px
        margin-right: 10px
        width: 77px

    .radio-root
        height: 30px
        width: 30px
