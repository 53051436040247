// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'


.staff-form-notification-email-modal
    max-width: 500px

    &.contents
        padding: 20px 20px 30px

    .buttons-container
        margin-top: 40px

    .title
        color: $black-shade-87
        font-size: 24px
        font-weight: 600
        margin-top: 12px
        padding: 12px 0

    .instruction
        font-size: 14px
        margin-bottom: 20px

    .custom-field__container
        margin-bottom: 10px
