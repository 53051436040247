@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-modal-customization-options

.#{$module-name}
    display: flex
    height: 100%
    justify-content: left
    margin-left: 50px
    padding: 15px 4px

    h3
        color: $grey-47
        font-size: 18px
        font-weight: 500
        margin-bottom: 16px

    &__left
        display: flex
        flex-basis: 60%
        flex-direction: column
        height: 100%

    &__right
        display: flex
        flex-basis: 40%
        flex-direction: row
        height: 100%

    &__restore-text
        color: $dark-gray
        font-size: 16px
        line-height: 47px

    & &__switch
        color: $dark-gray
        margin-right: -8px

    & &__switch &__switch--checked
        color: $text-blue


