// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'

$module-name: survey-procedures-edit

.#{$module-name}
    display: flex

    &__body
        background: $light-gray-blue-ting

    &__container
        margin: 7% auto
        width: 50%

    &__question-text
        color: $dark-grey-43
        font-size: 16px
        font-weight: bold
        line-height: 21px
        margin-bottom: 8px

    &__input-field
        width: 17%

    &__text-area
        width: 50%

    &__atom-toggle
        width: 40%

    &__other-option
        background: $light-gray-blue-ting
        padding-top: 20px

    &__other-option-tile
        align-items: center
        background-color: $white
        border: 1px solid $grey-97
        border-radius: 6px
        color: $dark-gray
        display: flex
        font-size: 12px
        height: 51px
        justify-content: center
        line-height: 16px
        padding: 0 8px
        text-align: center
        width: calc(100% - 38px)

    &--clickable
        cursor: pointer

        &:hover
            background-color: $very-light-green
            color: $dark-gray !important
            font-weight: 400 !important

    &--selected
        background-color: $clear-green
        color: $white
        font-weight: bold

    &__other-option-input-label
        font-size: 16px
        padding: 8px 0

    &__other-option-input
        width: 60%

        .custom
            &__input-error-icon
                top: 8px

    &__other-option-value
        align-items: center
        display: flex
        height: 100%






