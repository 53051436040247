@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'


.general-error-message
    width: 100%

    &__wrapper
        align-items: center
        background: $error-red
        color: $white
        display: flex
        font-size: 16px
        font-weight: bold
        padding: 18px 23px

        &--warning
            background: $warning

    &__wrapper-vertical
        align-items: left
        background: $error-red
        color: $white
        display: flex
        flex-direction: column
        font-size: 16px
        font-weight: bold
        padding: 18px 23px

    &__link
        background: none
        border: 0
        color: $white
        cursor: pointer
        padding: 0
        text-align: left
        text-decoration: underline

    &__message
        max-width: 85%
        padding-left: 5px

    &__title
        display: flex
        line-height: 30px
        padding: 5px

    &__description
        font-weight: normal
        margin-left: 2rem

    &__list
        list-style-type: none
        padding-left: 32px

        ul
            font-weight: 200
            list-style-type: disc
            padding-left: 18px

            li
                margin-top: 0.1rem

        li
            margin-top: 1rem

    &__submit
        align-items: center
        display: flex
        justify-content: center
        margin: 15px 0

    & &__submit-button
        +secondary-button(155px)

        .material-icons
            font-size: 1.1em
            margin-left: 8px
