@import 'src/modules/shared/_colors.sass'

.patients-table
    position: relative

    .table-item
        font-size: 16px
        font-weight: 500

    .table-header-item
        color: $label-gray
        font-size: 14px
        font-weight: 500

    .table-first-item
        padding-left: 0

    .table-row
        border-bottom: 1px solid $border-gray

    .table-row-clickable
        cursor: pointer

        &:hover
            background-color: $table-row-hover

    .table-pagination
        align-items: center
        display: flex
        justify-content: center
        width: 100%

    .circular-progress-loader
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 25px)

    .circular-progress-spinner
        color: $main-blue
