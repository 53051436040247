@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'


.practice-quickview
    margin: 2em auto

    .card
        border-top: 8px solid

    .card-root
        color: $black

    .card-content-root
        padding-left: 24px
        padding-right: 24px

    .tabs-container
        +safari-horizontal-scroll

    .tabs-scroller
        position: inherit

    .tabs-indicator
        display: none

    .tab-icon-wrapper
        margin-top: 6px

    .tab-bar
        border-bottom: solid thin $tab-border

        .inactive
            color: $gray

            .label
                text-decoration: line-through

        .tab-wrapper
            flex-direction: row

        .tab-icon
            height: auto

        .tab-label-container
            padding: 2px 12px 0

        .tab
            background: $tab-fill
            border-right: solid thin $tab-border
            border-top: solid thin $tab-border
            display: flex
            flex-direction: row
            font-size: 0.8125rem
            min-height: 48px
            min-width: 160px
            opacity: 0.7
            padding: 0

            &.selected
                background: $white
                opacity: 1
