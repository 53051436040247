@import 'src/modules/shared/_colors.sass'

.staff-form-notification-item
    border-bottom: 1px solid $border-gray
    padding: 5px 0

    .button
        color: $text-blue
        cursor: pointer

        &:hover
            color: lighten($text-blue, 10)

    .label
        display: inline-block

.confirm-modal-v2
    &__close-button
        height: 24px
        width: 24px

        &:hover
            box-shadow: 0 0 2px 0 $black-shade-50
