@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$hover-blue: lighten($main-blue, 10)

.custom-toggle-wrapper
    .custom-toggle
        background: $white
        border: 1px solid $input-border
        border-left-width: 0
        color: $main-blue
        cursor: pointer
        font-size: rem(16px)
        height: 40px
        line-height: 38px
        min-width: 92px
        outline: none
        text-transform: uppercase
        white-space: nowrap
        width: auto

        &:first-child
            border-left-width: 1px
            border-radius: $default-border-radius 0 0 $default-border-radius

        &:last-child
            border-radius: 0 $default-border-radius $default-border-radius 0

        &:hover
            background-color: $hover-blue
            border-color: $hover-blue
            color: $white

        &.custom-toggle-selected
            background-color: $main-blue
            border-color: $main-blue
            color: $white

            &:hover
                background-color: $hover-blue
                border-color: $hover-blue

        &:disabled
            background: $light-alert-blue
            color: $label-gray
            cursor: default

            &:hover
                background-color: $light-alert-blue
                border-color: $input-border
