@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


$module-name: masked-datepicker

.#{$module-name}
    position: relative

    &__masked-input
        background-color: $white
        border: 1px solid $input-border
        border-radius: $default-border-radius 0 0 $default-border-radius
        border-right: 0
        color: $soft-black
        display: flex
        font-size: 1rem
        height: 34px
        outline: none
        padding: 2px 0 2px 10px
        position: absolute
        top: 18px
        width: calc(100% - 39px)
        z-index: 2

        &:disabled
            color: $disabled

        &::placeholder
            color: $label-gray
            opacity: 1

        &--error
            border-color: $error-red

    &__calendar-icon
        background: $white
        cursor: pointer
        pointer-events: none
        position: absolute
        right: 7px
        top: 26px
        z-index: 2

        &--disabled
            opacity: 0.5

    &__error
        color: $error-red
        font-size: 12px
        margin: 5px
