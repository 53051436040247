@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_constants.sass'


$card-color: rgb(247, 247, 247)
$button-color: rgb(118, 214, 255)
$survey-header-height: 64px

$module-name: survey-component

.#{$module-name}
    display: flex
    flex: 2 1 auto
    flex-direction: column
    max-height: 100%
    min-height: 25%
    user-select: text

    &__header
        align-items: center
        background-color: $white
        color: $white
        display: flex
        flex-direction: row
        height: rem(44px)
        justify-content: space-between
        min-height: rem(44px)
        padding: rem(8px 11px)

    &--wrapped
        background-color: $main-blue
        padding: 0

    &__search-bar
        width: 100%

        .custom__input
            border-right-color: $very-soft-gray
            height: 32px

        .custom-field__adornment
            background-color: $very-soft-gray
            border: 1px solid $input-border
            height: 34px
            width: 58px

        .custom-field__adornment-icon
            color: $label-gray

    &__content
        border: 20px
        flex-direction: column
        flex-wrap: nowrap
        margin: 0
        overflow-y: auto
        width: 100%

    &__survey-wrapped
        height: 100%

        .survey-component--wrapped
            min-height: 40px
            padding: 0 8px

    &__content-wrapped
        flex-basis: 0
        height: calc(100% - #{$survey-header-height})
        overflow-y: auto

    &__item
        width: 100%

    &__loading
        align-items: center
        display: flex
        flex: 1
        height: 100%
        justify-content: center

    &__qa-card
        align-items: flex-start
        background-color: $white
        display: flex
        justify-content: space-between
        padding: 4px 8px 0

        &--darker
            background-color: $grey-e8

    &__qa-content
        color: $lighter-black
        display: flex
        flex-direction: column
        font-size: 14px
        white-space: pre-line

    &__copy-button
        align-items: center
        border-radius: $default-border-radius
        display: flex
        justify-content: center
        min-height: 36px
        min-width: 36px

        &:hover
            background-color: $black-shade-8
            cursor: pointer

        &--disabled
            background-color: $softer-gray
            opacity: 0.5
            pointer-events: none

    &__custom-icon
        color: $lighter-black
        font-size: 16px

    ul
        list-style-type: none
        margin: 10px 0
        padding: 0

    li
        margin-bottom: 5px
