@import 'src/modules/shared/_modals.sass'
@import 'src/modules/shared/_constants.sass'

$blue-color: #0000ff
$hover-blue: darken($main-blue, 10)

.locations-tab

    &__training-container
        color: $blue-color

    &__training-slider
        color: $clear-green
        font-size: 12px
        left: 5px
        position: absolute
        top: -25px

    &__pending
        position: absolute

    &__survey-error-icon
        font-size: 24px
        left: 20px
        position: absolute
        top: -16px

    &__survey-error-container
        color: $error-red

    &__small-loader
        position: absolute
        right: 5%

    &__address-wrapper
        color: $grey-55
        height: 80px

    &__copy-id
        color: $black-shade-87
        font-weight: 400

    &__transition-container
        width: 100%

    &__products
        cursor: pointer
        margin-top: 4px

    &--not-clickable
        cursor: default

    &__product
        border-bottom: 1px solid $gray-da
        color: $main-blue
        font-weight: 700
        line-height: 28px
        margin-right: 16px

    &--product-inactive
        color: $grey-b0

    &--not-active
        color: $gray-33

    &__facility-id
        color: $black-shade-87
        font-weight: 400

    &__loader-container
        height: 100%
        position: absolute
        width: 100%

        .loader
            align-items: center
            background-color: transparent
            display: flex
            height: 100%
            justify-content: center
            width: 100%

        &--global
            height: 148px

        &--list
            position: absolute

        .loader-spinner
            color: $main-blue

    &__footer
        background: $tab-fill
        border-top: solid thin $tab-border
        display: flex
        height: 62px
        justify-content: center
        margin: 0 -24px -24px

        .button
            font-weight: bold
            line-height: 62px
            text-transform: uppercase

            .add-icon
                display: inline-flex
                padding-right: 2px
                position: relative
                top: -2px
                vertical-align: middle


        &:hover
            background: $main-blue
            cursor: pointer

            .button
                color: $white

    &__paginator-wrapper
        display: flex
        justify-content: center
        margin-bottom: 18px
        width: 100%

        .paginator
            margin-top: 24px

    .payments-updated-successfully,
    .payments-processing-error
        border: 1px $clear-green solid
        box-sizing: border-box
        color: $dark-gray
        display: flex
        font-size: 16px
        margin-bottom: -5px
        margin-top: 5px
        padding: 16px 20px
        width: 100%

        .info-payment-icon
            color: $clear-green
            margin-right: 12px
            vertical-align: middle

        span
            line-height: 26px

    .payments-processing-error
        border-color: $alert-red
        color: $alert-red

        .info-payment-icon
            color: $alert-red
            font-weight: 900

    .card-container
        display: flex
        flex-wrap: wrap
        margin-top: 10px
        min-height: 146px
        position: relative

        .card
            background: $tab-fill-light
            border: 0
            border-top: 5px solid $primary-lime
            display: inline-block
            margin: 6px
            min-height: 359px
            position: relative
            width: 23.8%

            @media (max-width: $large-width)
                width: 23.6%

            @media (max-width: $medium-width)
                width: 47%


            &--expanded
                height: auto
                min-height: 288px

            .address
                font-size: 14px
                font-weight: 100
                line-height: 20px
                padding: 16px 0 18px 18px

                .id
                    color: $black-shade-87
                    font-weight: 400
                    margin-right: 2px

                .label
                    font-weight: bold

            .buttons-wrapper-one-row
                height: 24px

            .buttons-wrapper-one-row-warning
                height: 24px

            .buttons-wrapper-two-rows
                height: 36px

            .buttons-wrapper-two-rows-warning
                height: 48px

            .buttons
                bottom: 0
                display: flex
                justify-content: space-between
                padding: 4px 6px 8px
                position: absolute
                width: calc(100% - 16px)

                .button
                    font-size: 12px

            .header
                align-items: baseline
                font-size: 24px
                height: 80px
                line-height: 32px
                overflow-y: auto
                padding: 18px
                padding-top: 14px
                word-break: break-word

                .vyne-controlled-icon
                    position: absolute
                    right: 8px
                    top: 3px

            &.new-practice-button
                font-size: 28px
                height: 266px
                line-height: 288px
                min-height: 266px
                padding-left: 0
                text-align: center
                vertical-align: middle

                &:hover
                    background: $main-blue
                    cursor: pointer

                .add-icon
                    color: $black
                    font-size: 50px
                    opacity: 0.54

        .inactive-card
            border-top: 5px solid $primary-red

.location-modal,
.integrations-location-modal
    @extend %new-item-modal
    margin-top: 5vh
    padding: 0
    width: 626px

    &__content
        box-sizing: border-box
        margin-bottom: 47px
        max-height: 80vh
        overflow: auto
        padding: 54px 34px 23px
        position: relative

        .custom-field__helper-text
            display: none

    .facility-id

        .custom-field__helper-text
            display: block

    .address-heading
        font-size: 18px
        font-weight: 500
        margin-bottom: 4px
        margin-top: 16px

        &.terminal-color-heading
            margin-bottom: 0

    .terminal-radio
        span
            &:first-of-type
                color: $base-blue

                // sass-lint:disable class-name-format
                &.Mui-disabled
                    color: $softer-gray

                    span
                        color: $softer-gray

    .connect-info-heading
        font-size: 18px
        font-weight: 500
        line-height: 26px
        margin-top: 12px

    .connect-info-question-heading
        color: $grey-69
        font-size: 16px
        line-height: 24px

    .connect-info-question
        display: flex
        margin: 0 0 16px

    .payment-information-toggle
        width: 366px

        .custom-toggle-wrapper
            button
                font-size: 16px
                width: 33.3%

    .payment-amount
        margin: 6px 0 0 24px

    .payment-amount-field
        width: 153px

    .custom-field
        margin-right: 15px
        margin-top: 8px

    .content
        align-items: flex-end
        display: flex
        flex-wrap: wrap

        .custom-multiselect
            &__container
                margin-right: 10px
                margin-top: 8px

        .field
            flex-grow: 1
            margin-right: 15px
            min-height: 40px

            &.city
                width: 40%

            &.state
                width: 15%

            &.facility-id
                max-width: 60%

                .custom__input
                    width: 50%

                .custom__input-error-icon
                    right: calc(50% - 5px)

            &.street
                width: 60%

            &.unit
                margin-right: 0
                width: 15%

            &.zip
                margin-right: 0
                width: 19%

            &.optional
                line-height: 40px

            &.phone-number
                margin-top: 8px
                max-width: 50%
                width: 40%

            &.country
                width: 100%

            &--position
                align-items: flex-end
                display: flex

        .street
            &:hover
                .search-location-input
                    &__label
                        color: $main-blue

                    &__field
                        background-color: $shortcuts-white
                        border-color: $main-blue

    .field-position__helper
        display: inline-block
        font-size: 1.2em
        padding-bottom: 8px
        width: 23px

    .error-wrapper
        padding-top: 8px

    .delete-button
        color: $soft-black
        position: absolute
        right: 40px
        top: 50px

        &:hover
            background: $main-blue

    .upper-delete-button
        color: $main-blue
        position: absolute
        right: 40px
        top: 50px

        &:hover
            color: $main-blue-hover

    .name-header
        margin: 0
        margin-top: 10px
        max-width: 490px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .update-button
        background: $tab-fill
        bottom: 0
        left: 0
        line-height: 45px
        position: absolute
        text-align: center
        text-transform: uppercase
        vertical-align: middle
        width: 100%

        &.can-save
            background: $main-blue
            color: $white
            cursor: pointer

            &:hover
                background: $hover-blue

    .terminal-modal-wrapper
        padding: 50px

    .stripe-modal-wrapper
        padding: 50px

        .stripe-modal-content
            margin-bottom: 56px

            .stripe-input-label
                color: $label-gray
                display: block
                font-size: 12px
                margin-bottom: 5px

    span
        &.location-stripe-apply-all-checkbox
            color: $main-blue
            height: 36px
            margin-left: 25px
            width: 36px

            &  + span
                font-size: 16px
                user-select: none

.terminal-modal
    .terminal-header
        margin: 0

.terminal-color-field
    width: 100%

.terminal-color-item-wrapper
    align-items: center
    display: flex
    width: 100%

    .terminal-color-display
        display: inline-block
        height: 21px
        margin-right: 10px
        width: 21px

    .terminal-color-name
        text-transform: capitalize

    .error-msg
        color: $red
        font-size: 12px

.scheduling-fields
    display: flex
    padding-bottom: 6px

    .scheduling-field,
    .custom-field__container
        width: 50%

        &:first-child
            margin-left: 0
            width: 100%

    .scheduling-field
        & > div
            height: 40px

    .ipaddress
        width: 100%

    .error-msg
        color: $red
        font-size: 12px

.card-scheduling
    &__link
        align-items: center
        cursor: pointer
        display: flex
        justify-content: space-between
        margin-bottom: 10px
        margin-top: 4px
        user-select: none

        &--payments
            padding: 4px 0

    &__link-title
        color: $text-blue
        font-size: 14px
        font-weight: bold

    .material-icons
        font-size: 1.7em
        margin-right: 10px

    &__data
        background-color: $light-gray-blue-ting
        box-shadow: inset 0 7px 5px -5px $black-shade-12, inset 0 -7px 5px -5px $black-shade-12
        margin-bottom: 20px
        margin-left: -18px
        padding: 10px 18px

        dl
            color: $grey-55
            font-size: 14px
            margin: 0

        dt
            font-weight: bold
            margin: 0

        dd
            margin: 0 0 6px
            word-break: break-all

            .material-icons
                font-size: inherit
                padding-left: 4px
                vertical-align: text-top

    .card-payments-info
        &__row-terminal
            border-bottom: 1px $gray solid
            padding: 8px 0

        &__row-add
            padding: 8px 0

        &__link
            align-items: center
            background-color: transparent
            color: $text-blue
            cursor: pointer
            display: flex
            font-size: 14px
            font-weight: bold
            justify-content: space-between
            outline: none
            padding: 0
            width: 100%

            &:hover
                span
                    &:first-child
                        text-decoration: underline

            span
                font-size: 14px
                margin-right: 0

        &__delete
            background-color: transparent
            cursor: pointer
            float: right
            outline: none
            text-align: center

            > .material-icons
                color: $text-blue
                font-size: 18px
                margin: 0

        &__stripe-title
            display: block
            font-weight: 500
            margin-top: 10px

        &__stripe-link,
        &__external-link
            align-items: center
            background-color: transparent
            color: $text-blue
            cursor: pointer
            display: flex
            font-size: 14px
            font-weight: 500
            justify-content: space-between
            margin: 0 0 8px
            outline: none
            padding: 5px 0
            width: 100%

            &:hover
                span
                    &:first-child
                        text-decoration: underline

            span
                font-size: 14px
                margin-right: 0

        &__external-link
            > .copy-text-inner-span
                align-items: center
                display: flex
                justify-content: space-between
                width: 100%

                span
                    display: block
                    overflow: hidden
                    text-overflow: ellipsis
                    white-space: nowrap
                    width: 70%

                i
                    color: $text-blue
                    font-size: 16px
                    margin: 0

                &:hover
                    span
                        text-decoration: underline

        &__loading
            display: flex
            justify-content: center
            padding: 15px
            text-align: center

        .card-payments-loading
            height: 25px
            width: 25px

.terminal-nickname
    width: 48%

.payments-terminal-data-entry-row
    display: flex
    justify-content: space-between
    padding-bottom: 20px

    > div
        flex-basis: 48%

        .terminal-reader-serial,
        .terminal-setup-id
            width: 100%

.payments-stripe-modal-error
    border: 1px $alert-red solid
    box-sizing: border-box
    color: $alert-red
    display: flex
    font-size: 16px
    margin-bottom: 15px
    margin-top: 0
    padding: 16px 20px
    width: 100%

    .info-payment-icon
        color: $alert-red
        font-weight: 900
        margin-right: 12px
        vertical-align: middle

    span
        line-height: 26px

.payments-stripe-modal-success
    border: 1px $clear-green solid
    box-sizing: border-box
    color: $clear-green
    display: flex
    font-size: 16px
    margin-bottom: 15px
    margin-top: 0
    padding: 16px 20px
    width: 100%

    .info-payment-icon
        color: $clear-green
        font-weight: 900
        margin-right: 12px
        vertical-align: middle

    span
        line-height: 26px

.search-bar-container
    padding: 8px 4px

.integrations-location-modal
    width: 700px

    .name-header
        margin-top: 4px
