@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: question-select-one

.#{$module-name}
    &__error
        color: $error-red
        font-size: 12px

    &__wrapper
        display: flex
        position: relative

    &__other-label
        color: $dark-gray-43
        font-size: 14px
        font-weight: bold
        position: absolute
        top: -24px

    .custom__input-error-icon
        top: 8px

    &--half
        max-width: 50%

    &--third
        max-width: 33%

    &--sixth
        max-width: 17%

    &--inline-with-other
        width: 50%

    &--right
        padding-left: 24px
        padding-top: 0


@media screen and (max-width: $small-width)
    .#{$module-name}
        &__wrapper
            display: block

        &__other-label
            top: 78px

        &--half
            max-width: 100%

        &--third
            max-width: 100%

        &--sixth
            max-width: 33%

        &--right
            padding-left: 0
            padding-top: 64px

        &--inline-with-other
            width: 100%
