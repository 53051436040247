@import 'src/modules/shared/_colors.sass'

.agent
    .button
        height: 50px

    .cell
        padding-right: 0

        .data
            height: 50px

        .future-days
            align-items: center
            display: flex
            width: 90px

            .input
                padding-right: 5px
                width: 35px

            .icon
                color: $soft-grey

                &:hover
                    cursor: pointer
