@import 'src/modules/shared/_colors.sass'

%new-item-modal
    background: $white
    border: 1px solid $dark-gray
    border-radius: 3px
    box-shadow: 0 0 2px 0 $black-shade-12, 0 2px 2px 0 $black-shade-24
    margin: 0 auto
    margin-top: 72px
    min-height: 200px
    outline: none
    overflow: auto
    position: relative
    width: 600px

    .close-modal
        color: $black
        cursor: pointer
        font-size: 24px
        position: absolute
        right: 10px
        top: 10px

        &:hover
            box-shadow: 0 0 2px 0 $black-shade-50
