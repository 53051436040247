@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-modal-colors

.#{$module-name}

    &__background-colors-wrapper
        display: flex
        justify-content: space-between
        width: 265px

        .color-picker-button
            &:hover
                border: 1px solid $main-blue

    &__reset-colors-wrapper
        padding-bottom: 0

    &__reset-colors
        color: $text-blue
        line-height: 32px
        text-decoration: none

        &:hover
            opacity: 0.75
