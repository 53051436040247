// sass-lint:disable no-url-domains no-url-protocols no-ids
@import 'src/modules/shared/_colors.sass'


.pst-login
    align-items: center
    color: $tab-fill-light
    display: flex
    flex-direction: column
    height: 100vh
    justify-content: space-between

    .login-form
        background: $white
        border-radius: 2px
        box-shadow: 0 0 2px 0 $black-shade-12, 0 2px 2px 0 $black-shade-24
        display: block
        flex: 1
        margin: 48px auto 0
        max-height: 432px
        max-width: 350px
        padding: 26px 28px
        width: 350px

        .email
            margin-bottom: 30px

        .logo
            align-items: center
            display: flex
            justify-content: center
            margin-bottom: 12px

            #nav-viewport-1
                fill: $soft-black

        .password
            margin-bottom: 60px

        .submit
            text-align: right

        .button
            background: $main-blue
            color: $white
            cursor: pointer
            height: 36px
            width: 82px

        .title
            color: $black-shade-87
            font-size: 24px
            text-align: center

    .version-wrapper
        margin-bottom: 20px
        margin-top: 20px

    .version-text
        color: $medium-black
        font-size: 8px
        text-align: center

    .copyright-notice
        color: $character-primary
        font-size: 12px
        margin-bottom: 4px
        text-align: center

.login__error-wrapper
    height: 80px
    padding: 30px 0 10px
    position: relative

.login__error-notification
    align-items: center
    border: 1px solid $main-blue
    display: flex
    padding: 5px 0
    position: absolute
    top: 50%
    transform: translate(0, -50%)
    width: 100%

    .material-icons-container
        display: flex
        justify-content: center
        min-width: 48px

    .material-icons
        color: $main-blue
        padding: 0 0 0 2px
        width: 24px

.login__error-message
    color: $very-soft-black
    padding: 5px
