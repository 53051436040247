// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'

$lighten-link-blue: lighten($main-blue, 10)

$module-name: pms-writeback-settings

.app-root > .#{$module-name}
    margin-top: 30px
    padding: 0 70px

.#{$module-name}
    .detached-button
        color: $black
        cursor: pointer
        height: 24px
        position: absolute
        transform: translateX(-200%)

    &__title
        color: $dark-gray
        font-size: 24px
        font-weight: 500
        letter-spacing: 0
        line-height: 28px
        margin-top: 20px

    &__subtitle-wrapper
        border-bottom: 1px solid $foundation-border
        display: flex
        justify-content: space-between
        margin-top: 8px
        padding-bottom: 28px

    &__subtitle
        color: $lighter-black
        font-size: 16px
        line-height: 24px

    &__content
        padding: 24px 0

    &__practice-name-header
        align-items: center
        background-color: $foundation-background-2
        border-bottom: 1px solid $foundation-background
        border-top: 1px solid $foundation-background
        display: flex
        height: 46px
        position: relative

    &__practice-header-left
        align-items: center
        border-right: 1px solid $foundation-background
        display: flex
        height: 32px
        justify-content: center
        position: relative
        width: 60px

    &__practice-header-text
        color: $grey-primary
        font-size: 14px
        font-weight: 500
        margin-left: 16px

    &__practice-location-row
        align-items: center
        border-bottom: 1px solid $foundation-background
        display: flex

        &:nth-of-type(odd)
            background-color: $foundation-background-2

    &__practice-location-switch
        align-items: center
        display: flex
        height: 46px
        justify-content: center
        width: 60px

        .ant-switch-disabled
            background: $character-secondary
            opacity: 0.25

    &__practice-location-name
        color: $grey-primary
        font-size: 14px
        font-weight: 400
        margin-left: 16px

        &--disabled
            color: $gray-77

    &__circular-progress-loader-body
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 28px)

    &__circular-progress-spinner
        color: $main-blue
