.chat-center-alert-tasks-list

    &__loader
        align-items: center
        display: flex
        justify-content: center
        padding: 10px 0

    &__list
        list-style: none
        margin-left: -25px
        margin-top: 0
