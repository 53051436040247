// sass-lint:disable no-important

@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.staff-member-modal
    padding: 10px 10px 24px
    width: 80vw

    > .close-modal-button
        right: 10px

.edit-btn
    color: $main-blue
    cursor: pointer
    display: flex

    .settings-icon
        font-size: 28px

    &:hover
        color: lighten($main-blue, 10)

.more-icon
    color: $main-blue
    font-size: 28px

.staff-member-confirmation-modal,
.staff-member-confirmation-modal-wide
    background-color: $white
    border-radius: $default-border-radius
    color: $dark-gray
    display: flex
    flex-direction: column
    left: 50%
    outline: none
    padding: 10px 10px 24px
    position: absolute
    top: 50%
    transform: translate(-50%, -50%) !important
    width: 678px

    > .close-modal-button
        cursor: pointer
        display: flex
        justify-content: flex-end
        z-index: 1

        .material-icons
            &:hover
                box-shadow: 0 0 2px 0 $black-shade-50

.staff-member-confirmation-modal-wide
    width: 80%

    .close-staff-form-modal-button-container
        display: flex
        justify-content: flex-end

.staff-tab-list-member
    border-bottom: 1px solid $border-gray

    &:hover
        background-color: $table-row-hover

    .resend-invitation-btn-container
        padding: 0
        text-align: center


    .resend-invitation-btn
        color: $link-blue
        cursor: pointer
        font-size: 16px

        &:hover
            color: lighten($link-blue, 10)

    .simplifeye-icon
        padding-top: 3px

    .invited
        font-style: italic

    .deleteddeactivated
        color: $gray

    .custom-icon
        align-items: center
        display: flex
        padding-bottom: 2px
        padding-left: 2px

    .employee-name,
    .employee-username
        max-width: 360px
        overflow: hidden
        padding-right: 2px
        text-overflow: ellipsis
        white-space: nowrap

    .employee-last-name-wrapper,
    .employee-first-name-wrapper,
    .employee-username-wrapper
        .copy-text-inner-span
            display: flex

