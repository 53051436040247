@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'


.chat-text-field
    position: relative

    .chat-buttons
        box-shadow: 0 0 6px 0 $black-shade-20

        .button
            background: $tab-fill
            display: inline
            font-size: 1rem
            height: rem(36px)
            width: rem(107px)

            &:hover
                cursor: pointer

            &.selected
                background: $text-blue
                color: $white

        .refresh-icon
            color: $text-blue
            cursor: pointer
            height: rem(24px)
            margin-left: 4px
            padding-bottom: 3px
            vertical-align: middle
            width: rem(24px)

            &:hover
                opacity: 0.75

        & .refresh-button
            .refresh-button-loader
                margin-left: 8px
                position: absolute
                top: 9px

    &__message-input
        position: relative

        .input-field
            border-top: solid $sky-blue

            textarea
                height: rem(61px)
                margin: rem(0 6px)

    .centered-ban-message-red
        bottom: 15px
        position: absolute

    .message-red
        color: $error-red
        text-align: center
        width: 100%
