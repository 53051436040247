@import 'src/modules/shared/_colors.sass'


.attachments-header
    background-color: $gray-blue
    margin-right: 13px
    padding: 10px 0 11px 24px

    .attachments-title
        color: $white
        font-size: 18px
        margin: 0

.uploads-container
    color: $soft-black
    margin: 4px 0 15px -6px

    .attach-file
        position: relative
        top: 5px

    .browse-uploads
        color: $text-blue
        cursor: pointer

        &:hover
            color: darken($text-blue, 10)

    .uploads
        margin-top: 8px
        overflow-y: auto
        padding-left: 6px

    .file-upload
        display: flex
        flex-direction: column
        justify-content: space-between
        padding-bottom: 8px
        padding-right: 12px

        .info
            align-items: center
            display: flex

            > i
                cursor: pointer

        .file-upload-name
            color: $text-blue
            flex: 2 1 auto

            > i
                color: $very-soft-black
                cursor: default
                font-size: 8px
