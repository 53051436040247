@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: input-field

.#{$module-name}
    &__value
        align-items: top
        display: flex

    &__error
        color: $error-red
        font-size: 12px
        margin-top: 4px

    &__wrapper
        width: 100%

    .custom__input-error-icon
        top: 8px

    &--padding-top-bottom
        padding: 9px 0

    &--half
        max-width: 50%

    &--third
        max-width: 33%

    &--sixth
        max-width: 17%

@media screen and (max-width: $small-width)
    .#{$module-name}
        &--half
            max-width: 100%

        &--third
            max-width: 100%

        &--sixth
            max-width: 33%
