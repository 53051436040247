@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/chat-center/shared/_constants.sass'


$module-name: chat-tile

.#{$module-name}
    border: 1px solid $grey-97
    display: grid
    grid-auto-rows: 64px auto minmax(calc(100% - 650px), calc(100% - 170px)) minmax(106px, auto)
    grid-template-areas: 'header header' 'alerts alerts' 'chat-conversation survey-ds-connect' 'chat-text-field chat-text-field'
    grid-template-columns: 50% 50%
    height: calc(100vh - 80px)
    outline: none
    user-select: text

    &--banned
        opacity: 0.5

    &--focused
        outline: 3px solid $very-light-green

    &__header
        grid-area: header

    &__alerts
        border-right: 1px solid $tab-border
        flex: 1 1
        flex-basis: 0
        grid-area: alerts
        position: relative


    &__chat-conversation
        display: flex
        flex: 1 1 auto
        flex-direction: column
        grid-area: chat-conversation
        height: 100%
        overflow-y: auto

    &__survey-ds-connect
        grid-area: survey-ds-connect

    &__chat-text-field
        grid-area: chat-text-field
        min-height: 106px
        transition: height 0.66s ease-out

    &__loading
        align-self: center
        grid-column: span 2
        grid-row: span 3
        justify-self: center

