@import 'src/modules/shared/_colors.sass'


$module-name: chat-survey-and-ds

.#{$module-name}
    height: 100%
    max-height: 100%
    min-height: 100%

    &--small
        .#{$module-name}__tab-name
            display: none

    &__tabs
        display: flex
        font-size: 14px
        line-height: 27px
        text-align: center

    &__tab
        background-color: $table-row-hover
        color: $main-blue
        cursor: pointer
        display: block
        flex: 1
        font-size: inherit
        line-height: 27px
        margin: 0
        max-width: 50%
        outline: 0
        padding: 0

        &--selected
            background-color: $white
            color: $lighter-black
            cursor: default

        &--disabled
            color: $soft-gray
            cursor: not-allowed
            opacity: 0.75

    &__tab-label
        display: flex
        justify-content: center

    &__survey,
    &__schedule,
    &__connect
        height: calc(100% - 27px)
        overflow-y: auto

    &__icon-wrapper
        height: 24px
        padding-top: 3px

    &__custom-icon
        font-size: 16px
        width: 25px

    &__loading
        align-items: center
        display: flex
        height: 100%
        justify-content: center

