@import 'src/modules/shared/_colors.sass'

.app-root > .shortcut-list-header-container
    background-color: $lightgray
    max-width: 100%

.app-root > .shortcut-list-body
    margin-top: 20px

.shortcut-list-header
    align-items: center
    display: flex
    height: 96px
    justify-content: space-between
    margin: 0 auto
    width: 80%

.shortcut-list-table-cell
    color: $black-shade-50
    font-size: 12px
    height: 10px
    line-height: 12px
    padding: 4px 56px 4px 3px

.shortcut-list-header-title
    font-size: 24px
    margin: 0

.shortcut-list-header-controls
    display: flex

    .custom-field__container
        margin-right: 50px

.shortcut-list-search-box
    margin-right: 50px

.shortcut-list-body
    th
        padding-left: 3px

    td
        font-size: 0.8125rem
        height: 40px
        padding: 4px 56px 4px 1px

        &:last-child
            padding-right: 24px

.create-new-shortcut-button
    width: 250px

.button
    color: $main-blue

    &:hover
        color: $main-blue-hover
        cursor: pointer

.shortcut-modal,
.success-modal
    background-color: $modal-gray

    .modal-contents
        background-color: $white
        box-shadow: 0 4px 12px 0 $shadow-gray
        margin: auto
        position: relative
        top: 10%
        width: 50%

    .close-modal-button
        float: right
        position: relative
        right: 15px
        top: 15px

        &:hover
            cursor: pointer

    .button-container
        display: flex
        justify-content: center
        margin-bottom: 10px
        margin-top: 18px

        .primary
            border-color: $main-blue

        button
            box-shadow: none
            margin: 10px
