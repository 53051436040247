// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_modals.sass'


.invite-member-modal
    width: 600px !important

.staff-table
    position: relative

    .cell-root
        font-size: 14px
        padding: 0 48px 0 2px

    .body
        overflow-y: auto

        .staff-tab-list-member,
        .staff-tab-list-pending-invite
            .active-status,
            .manage
                padding: 0
                text-transform: uppercase

                .button
                    position: static

            .active-status
                .button
                    background: $soft-black
                    color: $white

                    &.active
                        background: $error-red

            .cell
                display: flex
                padding: 0
                white-space: nowrap

            .pending
                color: $gray
                cursor: pointer
                font-style: italic

                .resend-pending-invite
                    color: $tab-border
                    font-size: 20px

                    &:hover
                        color: $main-blue
                        cursor: pointer

    .custom__input
        height: 36px

    .custom-field__adornment
        height: 40px
        width: 53px

    .staff-footer
        background: $tab-fill
        border-top: solid thin $tab-border
        display: flex
        height: 62px
        justify-content: center
        margin: 0 -20px -24px

        .button
            font-weight: bold
            line-height: 62px
            text-transform: uppercase

            .add-icon
                display: inline-flex
                padding-right: 2px
                position: relative
                top: -2px
                vertical-align: middle


        &:hover
            background: $main-blue
            cursor: pointer

            .button
                color: $white


    .footer-disabled
        color: $gray
        cursor: not-allowed

        .button
            color: $gray

            &:hover
                cursor: not-allowed

        &:hover
            background: $tab-fill

            .button
                color: $gray

        .loader
            align-items: center
            display: flex

    .header
        background: $white
        border-bottom: solid thin $tab-border
        color: $black
        font-size: 14px
        font-weight: 500
        position: sticky
        text-transform: uppercase
        top: 0

        &:hover
            cursor: pointer

        &.hide-text
            background: $white
            color: $white

        &.no-cursor
            cursor: text

    .last-cell
        z-index: 1

    .staff-paginator-wrapper
        display: flex
        justify-content: center
        padding-bottom: 28px

        .paginator
            margin-top: 24px

    .circular-progress-loader-background
        background-color: $black-shade-12
        height: 175px
        margin: -16px

    .circular-progress-loader
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 25px)

    .circular-progress-spinner
        color: $main-blue

.staff-member-actions-menu
    .active-button
        background: $soft-black
        color: $white

        &:hover
            background: $soft-black
            opacity: 0.75

        &.active
            background: $error-red

            &:hover
                background: $error-red
                opacity: 0.75

    .staff-member-menu-item
        border-radius: 6px
        display: flex
        padding: 6px 16px

.staff-search-bar-wrapper
    padding-bottom: 8px
    padding-top: 8px
