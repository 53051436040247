@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: self-scheduling-webcode-locations-list

.#{$module-name}
    display: flex
    flex-direction: column
    margin-left: 50px

    &__search
        align-items: center
        background-color: $table-row-hover
        border: 1px solid $input-border
        border-radius: $default-border-radius
        display: flex
        font-size: 1rem
        height: 48px
        margin: 16px 0 12px
        padding: 0 8px 0 14px
        width: auto

    & &__search-icon
        color: $soft-gray
        font-size: 24px

        .material-icons
            max-width: 24px
            min-width: 24px

    &__search-field
        background-color: transparent
        border: 0
        color: $soft-black
        display: block
        font-size: 0.9rem
        height: 36px
        margin: 0
        outline: none
        padding: 0 4px
        width: calc(100% - 50px)

    &__search-clear
        color: $soft-gray
        cursor: pointer
        display: flex
        font-size: 16px

    & &__clear-icon
        font-size: 21px

    &__locations-container
        max-height: 245px
        overflow-y: scroll

    & &__not-found
        color: $error-red
        font-size: 16px
        font-style: italic
        font-weight: bold
        line-height: 26px

    &__error
        color: $error-red
        font-size: 12px
        margin-bottom: 25px
        margin-top: 0

.#{$module-name}__transition-container
    margin-bottom: 20px
    width: 100%

    &.exit
        opacity: 1

    &.exit-active
        opacity: 0
        transition: opacity 3000ms

    &.exit-done
        display: none

.#{$module-name}__rsvp-warning
    padding-top: 16px


