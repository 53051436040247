@import 'src/modules/dexvoice/_common.sass'
@import 'src/modules/shared/_widget.sass'
@import 'src/modules/shared/_colors.sass'


.flow-tab
    .back-button
        border: 0
        padding-left: 15px

        &:hover
            cursor: pointer

    .footer
        background: $tab-fill
        border-top: solid thin $tab-border
        height: 45px

        .button
            line-height: 45px
            text-transform: uppercase
            vertical-align: middle

            .add-icon
                display: inline-flex
                padding-left: 20px
                padding-right: 5px
                position: relative
                top: -2px
                vertical-align: middle


        &:hover
            background: $main-blue
            cursor: pointer

    .metrics
        @extend %metric-card
        box-sizing: border-box
        padding: 14px 18px
        width: 260px

        .heading
            position: relative

            .agent-count
                font-size: 24px
                margin-bottom: 16px

            .manage
                color: $dark-alert-blue
                position: absolute
                right: -18px
                top: -14px

    .graphs
        display: flex
        flex-direction: row

        .healthy,
        .unhealthy
            color: $black-shade-87
            flex: 1

            .bar
                border-top: 4px solid $black

            .count
                font-size: 16px

            .label
                font-size: 10px
                padding: 4px 0
                text-transform: uppercase

        .healthy
            .bar
                border-color: $success-green

        .unhealthy
            .bar
                border-color: $error-red

    .table
        .data
            text-align: center

        .header
            padding-right: 15px
            text-transform: uppercase

        .location
            max-width: 200px
            width: 200px

.contents
    &.unbound-agent-modal
        h2
            padding-left: 60px

        .close-modal-button
            height: 24px
            padding: 20px 0 0 20px
            width: 24px

            &:hover
                cursor: pointer

        .header
            color: $black
            font-size: 14px
            font-weight: bold
            padding-right: 15px
            text-transform: uppercase
