.welcome-messages-table
    height: auto
    margin-left: 30px
    overflow-y: auto

    td
        padding: 4px 56px 4px 24px

        &:last-child
            padding-right: 24px

    .message
        width: 62%

.welcome-messages-table-button-meesage
    font-size: 13px

.welcome-messages-table-buttons
    display: flex

.welcome-messages-table-button,
.welcome-messages-table-button-add
    min-width: 88px
    padding: 8px 16px


