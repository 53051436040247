@import 'src/modules/shared/_modals.sass'
@import 'src/modules/shared/_core.sass'
@import 'src/modules/shared/_buttons.sass'

$vyne-modal-wrapper-padding: 20px

.vyne-modal-wrapper
    @extend %new-item-modal
    background: $white
    border: 0
    border-radius: 6px
    overflow: visible

    .vyne-modal-title
        align-items: center
        border-bottom: 1px $input-border solid
        display: flex
        justify-content: space-between
        padding: 15px 20px

        h2
            color: $dark-gray
            font-size: 24px
            font-weight: bold
            margin: 0

        .close-modal
            align-items: center
            align-self: flex-end
            background-color: transparent
            border-radius: 6px
            display: flex
            font-size: 24px
            padding: 6px
            position: relative
            right: auto
            top: auto

            &:hover
                background-color: $grey-f5
                box-shadow: none

    .vyne-modal-success,
    .vyne-modal-error
        border: 1px $clear-green solid
        box-sizing: border-box
        color: $dark-gray
        display: flex
        font-size: 16px
        margin: 20px 20px 0
        padding: 16px 20px

        .info-payment-icon
            color: $clear-green
            margin-right: 12px
            vertical-align: middle

        span
            line-height: 26px

    .vyne-modal-error
        border-color: $alert-red
        color: $alert-red

        .info-payment-icon
            color: $alert-red
            font-weight: 900

    .vyne-modal-content
        padding: $vyne-modal-wrapper-padding

    .vyne-modal-buttons
        display: flex
        justify-content: center
        padding-bottom: $vyne-modal-wrapper-padding

        .vyne-modal-left-btn
            @extend %vyne-button-white-background
            margin-right: 20px

        .vyne-modal-main-btn
            @extend %vyne-button-blue-default
            position: relative

            &--loading
                .vyne-modal-main-btn-text
                    visibility: hidden

            .vyne-modal-main-btn-loading
                bottom: 0
                color: $white
                font-size: 16px
                left: 0
                margin: auto
                position: absolute
                right: 0
                top: 0
