@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_headers.sass'
@import 'src/modules/shared/_mixins.sass'


.amplify-management
    .filter-select
        background-color: $black-shade-87
        display: flex
        padding: 10px 16px

    .paginator-wrapper
        display: flex
        justify-content: center

    .amplify-management-table
        +safari-horizontal-scroll

        display: block
        white-space: nowrap

    .amplify-management-table-cell
        padding: 4px 32px 4px 20px

    .table-head-cell
        box-shadow: inset 0 -2px 0 $tab-border
        color: $label-gray
        font-size: 0.75rem
        font-weight: 500
        line-height: 48px
        padding: 4px 32px 4px 20px

    .circular-progress-loader
        bottom: 50%
        left: 40%
        position: absolute

.amplify-management-select-location,
.amplify-management-select-days
    margin-right: 15px
    width: 250px

.amplify-management-search-box
    width: 400px

.amplify-management__production-values
    background-color: $dark-white
    display: flex
    justify-content: space-between
    padding: 16px 80px 16px 35px

.amplify-management__production-value
    color: $black

.amplify-management__production-value-label
    font-size: 14px
    font-weight: bold

.amplify-management__production-value-sum
    font-size: 40px
