@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_headers.sass'
@import 'src/modules/shared/_modals.sass'


.chat-center-archive
    margin-top: 0

    .filter-select
        background-color: $black-shade-87
        display: flex
        padding: 10px 16px

    .selection-header
        @extend %selection-header

    .statistics-subheader
        @extend %statistics-subheader

    .archived-amplify-chat-list
        .archived-amplify-chat
            .chat-id
                color: $soft-gray
                font-size: 14px
                white-space: nowrap
                width: 10%

            .patient-name
                color: $soft-black
                font-size: 18px
                line-height: 20px

            .select-chat-transcript
                border: 1px solid $main-blue
                color: $main-blue
                line-height: 1.4em
                padding: 8px 16px

    .paginator-wrapper
        display: flex
        justify-content: center

    .archive-table
        margin-top: 0

        td
            font-size: 13px
            padding: 4px 0 4px 24px
            width: 270px

        th
            font-size: 12px
            line-height: 48px
            padding: 4px 0 4px 24px
            width: 270px

    .table-head-cell
        box-shadow: inset 0 -2px 0 $tab-border
        color: $label-gray

.chat-center-archive-select-website,
.chat-center-archive-select-days
    margin-right: 15px
    width: 250px

.chat-center-archive-search-box
    width: 400px

.chat-center-archive-modal
    @extend %new-item-modal

    .chat-center-archive-message-history
        display: flex
        flex-direction: column

        .content
            flex: 1 1 auto
            padding: 50px

.circular-progress-loader
    left: 50%
    position: absolute
    top: 50%
