@import 'src/modules/shared/_colors.sass'

$module-name: customize-direct-scheduling

.#{$module-name}
    display: flex
    flex-direction: column
    height: 100%
    padding: 12px 24px 10px

    h3
        color: $soft-black
        font-size: 18px
        font-weight: 500
        margin-top: 0
