@import 'src/modules/shared/_colors.sass'

.patient-details
    margin-bottom: 0

    &__dob
        color: $label-gray
        padding-left: 16px

    &__header
        align-items: center
        border-bottom: 1px solid $tab-border
        display: flex
        justify-content: flex-start
        margin: -16px -16px 8px
        padding-bottom: 4px

        .btn-back
            color: $black
            margin-right: 4px

        .patient-name
            margin: 0

        .patient-practice-location-name
            font-size: 1.4em
            padding-left: 12px

    &__header-text
        align-items: baseline
        display: flex

    &__table
        display: flex
        padding-top: 8px

    &__cell
        width: 14.2%

    &__cell-title
        color: $label-gray
        font-size: 12px
        font-weight: 500
        padding-left: 0

    &__cell-value,
    &__cell-value-bottom
        color: $dark-gray
        font-size: 16px
        font-weight: 400
        padding-bottom: 16px
        padding-left: 0
        padding-top: 2px

    &__cell-value-bottom
        padding-bottom: 0

    &--bold
        font-weight: bold

    .circular-progress-loader
        left: 45%
        position: absolute
        top: 40%

    .circular-progress-spinner
        color: $main-blue
