@import 'src/modules/shared/_colors.sass'


// TODO: change px to rem
.shortcut-container
    bottom: 100%
    display: flex
    flex-direction: column
    max-height: 405px
    overflow: hidden
    position: absolute
    width: 100%
    z-index: 10

    .shortcut-title
        background-color: $very-soft-gray
        height: 50px

        .shortcut-title-text
            float: left
            padding-left: 18px

        .shortcut-title-icon
            float: right
            height: 24px
            margin-right: 18px
            margin-top: 12px
            padding: 0

            &:hover
                box-shadow: 0 0 2px 0 $black-shade-50

    .shortcuts
        background-color: $shortcuts-white
        flex-grow: 1
        font-size: 14px
        overflow-y: auto
        padding-bottom: 19px
