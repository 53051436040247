@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$opacity: rgba(0, 0, 0, 0.4)

.code-modal-wide
    max-height: 90vh
    overflow: hidden

    .bottom-save-button
        background: $tab-fill
        bottom: 0
        color: $dark-grey
        cursor: pointer
        left: 0
        position: sticky

.webcode-confirmation-modal
    background-color: $white
    border-radius: $default-border-radius
    color: $dark-gray
    display: flex
    flex-direction: column
    left: 50%
    outline: none
    padding: 10px 10px 24px
    position: absolute
    top: 50%
    transform: translate(-50%, -50%) !important
    width: 480px

    > .close-modal-button
        cursor: pointer
        display: flex
        justify-content: flex-end
        z-index: 1

        .material-icons
            &:hover
                box-shadow: 0 0 2px 0 $black-shade-50

.web-code-form
    overflow: auto
    position: relative

    &__survey-monkey-id-wrapper
        font-size: 16px
        line-height: 21px
        margin-bottom: 8px

    &__survey-monkey-id
        font-weight: bold

    .name-header-wrapper
        display: flex
        justify-content: space-between

    .name-header
        font-size: 28px
        font-weight: bold
        margin-bottom: 20px
        margin-top: 0

    &__delete-button
        align-items: center

    .top-action-btn
        color: text-blue
        position: absolute
        right: 40px
        top: 50px

    .web-code-form__error
        color: $error-red
        font-size: 12px
        margin-bottom: 25px
        margin-top: 0

    .web-code-form__container
        padding: 51px 25px 0

    .web-code-form__subtitle
        color: $dark-gray
        font-weight: 500
        margin-bottom: 8px

    .web-code-form__pending
        align-items: center
        background: $opacity
        bottom: 0
        display: flex
        justify-content: center
        left: 0
        position: absolute
        right: 0
        top: 0
        z-index: 9

    .web-code-form__url
        color: $dark-gray
        font-size: 14px

    .practice-specialty-selector
        margin-bottom: 20px
        width: 150px

    .row
        display: flex
        justify-content: flex-end

        .copy-text-inner-span
            display: flex

        div
            &:first-child
                margin-right: 45px

        .copy
            color: $soft-gray
            font-size: 14px
            font-weight: lighter
            letter-spacing: 0.5px
            position: relative
            text-transform: uppercase

            &.active
                color: $dark-gray
                font-weight: bolder

        .field
            border: solid 1px $soft-gray
            min-height: 50px
            width: 100%

            pre
                color: $soft-gray
                font-family: monospace
                margin-top: 5px
                white-space: pre-wrap

            &.amplify
                border: 0
                margin-bottom: 20px
                overflow-y: auto

        .paste
            bottom: 3px
            font-size: 14px
            letter-spacing: 0.5px
            margin-right: 49px
            position: relative
            text-transform: uppercase

            &.active
                color: $dark-gray
                font-weight: bolder

                &:hover
                    cursor: pointer

        .title
            font-size: 18px
            height: 32px
            width: 140px

    .survey-surveys
        padding-bottom: 25px
        padding-top: 0

    .survey-surveys-webcode-url
        padding-bottom: 0
        padding-top: 32px

        .survey-question__label
            align-items: center
            color: $dark-gray-43
            display: flex
            font-size: 14px
            font-weight: bold
            padding-bottom: 8px

    .survey-surveys-webcode-url-add

        @media screen and (min-width: $medium-width)
            flex-basis: 50%
            flex-grow: 0
            max-width: 50%

    .survey-section
        padding-top: 12px

    .survey-response-id
        padding-bottom: 31px

    .url
        padding-bottom: 8px

    .web-code-key
        color: $gray
        font-size: 80%

    .custom__input-error-icon
        top: 7px

    .custom-tooltip__children
        height: 17px

    .custom-tooltip__popover--top-left
        &::after
            right: calc(17% - 6px)
