// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'


.staff-form-notification-phone-number-modal
    max-width: 600px

    &.contents
        padding: 20px 20px 30px

    .buttons-container
        margin-top: 24px

    .title
        color: $black-shade-87
        font-size: 24px
        font-weight: 600
        margin-top: 12px
        padding: 12px 0

    .instruction
        font-size: 16px
        margin-bottom: 50px

    .phone-number-field
        margin: 0 auto
        max-width: 336px

    .custom-field__label
        line-height: 24px

    .custom__input-error-icon
        top: 32px

    .error-message__container
        color: $error-red
        padding-left: 2px
