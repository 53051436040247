@import 'src/modules/shared/_colors.sass'


%selection-header
    background-color: $black-shade-87
    padding: 10px 16px

    .select
        display: inline-block
        margin-right: 28px

        .dropdown
            background: $white
            padding-left: 8px
            padding-right: 4px


%statistics-subheader
    background-color: $dark-white

    .statistic
        display: inline-block
        margin: 10px 0 10px 28px
        padding: 6px 0 6px 7px

        .label
            font-size: 14px
            font-weight: bold

        .count
            font-size: 40px
