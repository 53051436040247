@import 'src/modules/shared/_core.sass'

.admin-tools-content
    &--input
        .admin-tools-row
            display: flex

            &:first-child
                margin-bottom: 20px

            > .custom-field__container,
            > .custom-datepicker
                &:first-child
                    margin-right: 24px

            &.admin-tools-row--custom-date-wide
                > .custom-datepicker
                    margin-right: 12px
                    width: 80%

                .custom-field__container
                    &:nth-child(2)
                        margin-right: 15px

            &.admin-tools-row--narrow
                margin: 0 auto 5px
                width: 60%

                .custom-field__container
                    margin-right: 2px

            .admin-tools-or-text
                align-self: flex-end
                bottom: 11px
                left: -10px
                position: relative
                text-transform: uppercase

    &--loaded
        .admin-tools-text
            color: $dark-gray
            font-size: 16px
            margin: 0 0 11px

        .admin-tools-details
            display: flex
            flex-direction: column

            .admin-tools-details-row
                align-items: flex-start
                display: flex
                flex-direction: row
                justify-content: center
                margin-bottom: 10px

                .admin-tools-details-label
                    color: $dark-gray-43
                    font-size: 16px
                    font-weight: bold
                    margin-right: 22px
                    text-align: right
                    width: 180px

                    &--wide
                        width: 240px

                .admin-tools-details-value
                    color: $dark-gray-43
                    font-size: 16px
                    text-align: left
                    width: 180px

                    &--uppercase
                        text-transform: uppercase

                    &--wide
                        width: 240px

                    .admin-tools-card-list
                        color: $dark-gray
                        margin-bottom: 5px

                    .admin-tools-capitalize
                        text-transform: capitalize

                .admin-tools-details-large-bold
                    color: $dark-gray
                    font-size: 24px
                    font-weight: bold

        .admin-tools-select-wrapper
            margin: 5px auto 10px
            width: 400px

        .admin-tools-radio-checkbox-list
            display: flex
            flex-direction: row
            flex-wrap: wrap
            list-style: none
            margin: 0
            padding: 0

            .active-patient-radio-checkbox
                margin-left: 4px
                padding: 5px

            li
                width: 50%

                .active-patient-radio-checkbox-label
                    color: $dark-gray
                    font-size: 16px
                    text-transform: capitalize
