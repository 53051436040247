@import 'src/modules/shared/_colors.sass'

$module-name: custom-date-range

.#{$module-name}
    position: relative

    &:hover
        .#{$module-name}__dropdown-button
            border-color: $main-blue

        label
            color: $main-blue

    .masked-datepicker__error
        margin-left: 0
