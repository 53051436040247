=bg-strike($color)
    display: block
    overflow: hidden
    text-align: center
    white-space: nowrap

    > .text-between-lines
        display: inline-block
        position: relative

        &::before,
        &::after
            background-color: $color
            content: ''
            height: 1px
            position: absolute
            top: 50%
            width: 450px

        &::before
            margin-right: 15px
            right: 100%

        &::after
            left: 100%
            margin-left: 15px

=primary-button($min-width: 110px)
    background-color: $link-blue
    border: 1px solid $link-blue
    border-radius: 4px
    color: $white
    cursor: pointer
    font-size: 16px
    font-weight: 500
    margin: 0
    min-width: $min-width
    padding: 8px
    text-transform: uppercase
    white-space: nowrap

    &:hover
        background-color: $link-blue
        border: 1px solid $link-blue
        color: $white

    &:disabled
        background-color: $light-gray
        border: 1px solid $light-gray
        color: $white
        cursor: default

=secondary-button($min-width: 110px)
    align-items: center
    background-color: $white
    border: 1px solid $grey-97
    color: $link-blue
    cursor: pointer
    display: flex
    font-size: 16px
    font-weight: 500
    justify-content: center
    margin: 0
    min-width: $min-width
    padding: 8px
    text-transform: uppercase
    white-space: nowrap

    &:hover
        background-color: $white
        border: 1px solid $link-blue
        color: $link-blue

    &:disabled
        background-color: $light-gray
        border: 1px solid $light-gray
        color: $white
        cursor: default

=long-word-break
    hyphens: auto
    overflow-wrap: break-word
    white-space: pre-wrap
    word-break: break-word
    word-wrap: break-word

=safari-horizontal-scroll
    overflow-x: auto

    &::-webkit-scrollbar
        appearance: none
        height: 7px

    &::-webkit-scrollbar-thumb
        background-color: $black-shade-50
        border-radius: 4px
        box-shadow: 0 0 1px $white-shade-50
