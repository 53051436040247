@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-button-style

.#{$module-name}
    margin-bottom: 30px

    .radio-root
        height: 30px
        padding: 0
        width: 30px

    &__wrapper,
    &__label-wrapper,
    label
        align-items: center
        display: flex

    &__wrapper
        margin-bottom: 5px

    label
        cursor: pointer

    &__label-text
        font-size: 16px
        line-height: 16px
