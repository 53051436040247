@import 'src/modules/shared/_colors.sass'

$module-name: 'referral-additional-patient'

// sass-lint:disable no-important
// sass-lint:disable class-name-format
.#{$module-name}
    &__form
        background-color: $very-soft-gray
        padding: 16px 15px 19px 21px

    &__field-static
        margin-top: 10px !important
        padding-left: 3px

    &__field-label
        color: $label-gray
        font-size: 12px
        line-height: 18px

    &__field-text
        color: $dark-gray
        font-size: 16px

    &__delete-button
        font-size: 14px
        letter-spacing: normal !important
        padding: 8px 16px !important
        position: relative
        top: 20px

    &__delete-icon
        font-size: 16px
        position: relative

    &__datepicker-wrapper
        margin-right: 13px

        &:hover
            .MuiInputLabel-root
                color: $main-blue

            .Mui-error
                color: $error-red

            .MuiInput-formControl
                border-color: $main-blue

                &.Mui-error
                    border-color: $error-red

            .masked-datepicker
                &__masked-input
                    border-color: $main-blue

                    &--error
                        border-color: $error-red

    &__delete-button-text
        color: $text-blue
        display: flex
        line-height: 18px

        &--disabled
            opacity: 0.5

        &:hover
            color: darken($text-blue, 10)
