@import 'src/modules/shared/_modals.sass'
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.app-root
    > .admin-list
        max-width: 100%

.admin-list
    .bar
        background: $darker-white

        .invite-button
            position: absolute
            right: 10px
            top: 18px
            z-index: 1

        .button
            background: $main-blue

    .accounts-header
        background: $darker-white
        padding: 9px 10%
        width: 80%

        @media screen and (max-width: $critical-width)
            max-width: 90%
            padding: 9px 5% 0


    .accounts-statistics
        display: flex
        justify-content: space-between

    .manage-shortcuts
        align-self: left
        border: 1px solid $darker-white
        color: $text-blue
        display: flex
        margin-right: -15px
        padding: 8px 16px 6px

        &:hover
            border: 1px solid $link-blue
            border-radius: 6px
            cursor: pointer

        .shortcuts-icon
            margin-right: 5px

    .container
        margin: 30px auto 0
        max-width: 80%
        position: relative

        @media screen and (max-width: $critical-width)
            max-width: 90%


        .admin-table
            display: table
            margin-top: 28px
            width: 100%

            .list
                display: table-row-group

            .headers
                display: table-row-group
                font-size: 14px
                font-weight: bold
                letter-spacing: 0.5px
                text-transform: uppercase

                .header-select
                    border-bottom: 1px solid $black-shade-14
                    border-collapse: unset
                    display: table-cell
                    font-weight: 700
                    line-height: 1rem
                    padding: 16px 0

    .rw-multiselect
        margin-right: 30px
        width: 350px

    .rw-widget-input
        border-color: $field-border
        border-radius: 6px

    .paginator-wrapper
        display: flex
        justify-content: center
        margin-bottom: 50px

.admin-list-invite-modal
    @extend %new-item-modal

