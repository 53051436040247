@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module: availability-weekly-view
$slot-height: 49px
$slot-width: 14.3%
$header-height: 48px
$timeslot-width: 90px
$linear-gradient-gray-white: linear-gradient(180deg, $shadow-gray-c7 0, $black-transparent 100%)
$linear-gradient-white-gray: linear-gradient(180deg, $black-transparent 0, $shadow-gray-c7 100%)

.#{$module}
    background: $light-gray-blue-ting
    color: $weekly-view-dark-blue
    font-size: 14px
    margin-bottom: 24px
    padding-bottom: 7px
    padding-left: 0
    position: relative
    user-select: none

    &--disabled
        opacity: 0.5

    &::before
        background: $linear-gradient-gray-white
        content: ''
        height: 7px
        left: 0
        position: absolute
        right: 0
        top: 0
        z-index: 3

    &::after
        background: $linear-gradient-white-gray
        bottom: 0
        content: ''
        height: 7px
        left: 0
        position: absolute
        right: 0
        z-index: 3

    &__arrow
        align-items: center
        background-color: $soft-black
        color: $white
        cursor: pointer
        display: flex
        font-size: 2em
        height: 95px
        justify-content: center
        position: absolute
        top: 50%
        transform: translateY(-50%)
        width: 40px

        .material-icons
            font-size: 1.5em

        &--right
            border-radius: 10px 0 0 10px
            right: 0

        &--left
            border-radius: 0 10px 10px 0
            left: 106px

    &__month-name-header
        align-items: center
        border-bottom: 1px solid $black-shade-12
        display: flex
        height: 40px
        justify-content: center
        padding-top: 4px

    &__month-name
        font-size: 20px
        text-transform: uppercase

    &__today-button-wrapper
        padding-right: 22px

    &__today-button
        height: 30px

    &__header
        height: $header-height
        overflow: hidden
        width: 100%

    &__table
        align-items: flex-start
        display: flex
        overflow-y: hidden
        width: 100%

    &__time-slots
        border-right: 1px solid $black-shade-12
        box-sizing: border-box
        max-width: $timeslot-width
        min-width: $timeslot-width
        width: $timeslot-width

    &__time-wrapper
        box-sizing: border-box
        width: $timeslot-width

    &__time-slot
        border-bottom: 1px solid $black-shade-12
        box-sizing: border-box
        display: flex
        height: $slot-height
        justify-content: center
        padding: 12px 10px
        width: $timeslot-width

    &__body
        display: block
        overflow-x: auto
        width: calc(100% - #{$timeslot-width})

    &__row-container
        display: flex
        width: 100%

    &__row
        display: flex
        width: calc(100% - 75px)

    &__row-no-scroll-bar
        display: flex
        width: calc(100% - 90px)

    &__row-header
        display: flex
        width: 100%

    &__cell
        border-bottom: 1px solid $black-shade-12
        border-right: 1px solid $black-shade-12
        box-sizing: border-box
        display: table-cell
        height: $slot-height
        max-width: $slot-width
        min-width: $slot-width
        overflow: hidden
        position: relative
        vertical-align: top
        width: $slot-width

        &--header
            height: $header-height
            vertical-align: bottom
            width: 14.3%

        &--today
            background-color: $link-blue
            color: $white

        &--back
            align-content: center
            background: $soft-black
            cursor: pointer
            display: flex
            justify-content: center
            max-width: $timeslot-width
            min-width: $timeslot-width
            padding-top: 6px
            position: relative
            width: $timeslot-width
            z-index: 2

        &--empty
            width: 90px

        &--back-disabled,
        &--back-empty
            align-content: center
            display: flex
            justify-content: center
            max-width: $timeslot-width
            min-width: $timeslot-width
            padding-top: 6px
            position: relative
            width: $timeslot-width
            z-index: 2

        &--forward
            background: $soft-black
            cursor: pointer
            max-width: $timeslot-width
            min-width: $timeslot-width
            position: relative
            width: $timeslot-width
            z-index: 2

    &__cell-label
        align-items: center
        box-sizing: border-box
        display: flex
        flex-direction: column
        justify-content: center
        line-height: 20px
        max-height: $header-height
        overflow: hidden
        padding: 4px 8px
        width: 100%

    &__back-arrow
        color: $white
        font-size: 33px

    &__slot
        box-sizing: border-box
        height: 4px
        left: 1px
        position: relative
        width: calc(100% - 2px)

        &:last-child
            height: 4px

        &--unavailable
            background: $unavailable-slot-color

        &--available
            background: $free-slot-color

        &--invalid
            background: $invalid-slot-color

        &--selected
            background: $selected-slot-color

        &--hover
            border-left: 1px solid $link-blue
            border-right: 1px solid $link-blue

            &.#{$module}__slot--starting
                border-top: 1px solid $link-blue

            &.#{$module}__slot--ending
                border-bottom: 1px solid $link-blue

        &--starting
            border-top-left-radius: $default-border-radius
            border-top-right-radius: $default-border-radius
            height: 6px
            margin-top: 1px

        &--ending
            border-bottom-left-radius: $default-border-radius
            border-bottom-right-radius: $default-border-radius
            height: 4px
            margin-bottom: 1px

            &:last-child
                height: 6px

            &.#{$module}__slot--starting
                height: 6px

                &:last-child
                    height: 6px

    &__no-data
        align-items: center
        background: $white
        border: 1px solid $link-blue
        color: $dark-gray
        display: flex
        font-size: 16px
        justify-content: flex-start
        left: 25%
        margin: 0 11px 60px 0
        padding: 18px 22px
        position: absolute
        top: 40%

    &__loading
        align-items: center
        background: $white
        border: 1px solid $link-blue
        color: $dark-gray
        display: flex
        font-size: 16px
        justify-content: flex-start
        left: 43.9%
        margin: 0 11px 60px 0
        padding: 18px 22px
        position: absolute
        top: 40%

    &__no-data-icon
        color: $link-blue
        margin-right: 14px
        min-width: 24px
        width: 24px

        svg
            height: auto
            width: 100%

    &__no-data-message
        h3
            color: $no-data-red
            font-size: 20px
            font-weight: bold
            line-height: 26px
            margin: 0 0 5px

        p
            margin: 0

    &__pending
        align-items: center
        display: flex
        justify-content: center
        padding: 30px
