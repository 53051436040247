@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'


$module-name: success-message

.#{$module-name}
    align-items: center
    background: $white
    border: 1px solid $success-green
    color: $very-soft-black
    display: flex
    font-size: 16px
    height: auto
    justify-content: flex-start
    margin-bottom: 16px
    opacity: 1
    padding: 18px 23px
    transition: opacity 0.4s, padding 0.5s, margin-bottom 0.5s, height 0.4s

    &--hidden
        height: 0
        margin-bottom: 0
        opacity: 0
        overflow: hidden
        padding: 0 23px

    .material-icons
        color: $success-green
        margin-right: 10px
