@import 'src/modules/shared/_colors.sass'

$module-name: masked-inline-datepicker

.#{$module-name}
    position: relative

    &__masked-input
        background-color: $white
        border: 0
        border-right: 0
        color: $soft-black
        display: flex
        font-size: 1rem
        height: 25px
        left: 2px
        outline: none
        padding-left: 10px
        position: absolute
        top: 25px
        width: calc(100% - 39px)

        &:disabled
            color: $soft-black

        &::placeholder
            color: $label-gray
            opacity: 1

        &--error
            border-color: $error-red

    &__calendar-icon
        background: $white
        cursor: pointer
        pointer-events: none
        position: absolute
        right: 7px
        top: 26px
        z-index: 2

        &--disabled
            opacity: 0.5

    &__error
        color: $error-red
        font-size: 12px
        margin: 5px
