// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'


.manage-admin-modal
    width: 756px !important

    .manage-admin
        display: flex
        flex-direction: column

        .content
            flex: 1 1 auto
            padding: 24px 24px 0

            .fields
                display: flex
                flex: 0

                .email,
                .name
                    flex: 1
                    padding-right: 16px

                    .label
                        color: $charcoal
                        font-size: 14px
                        margin-bottom: 10px

            .name-title
                flex: 0
                font-size: 24px
                font-weight: 500
                margin-bottom: 18px

            .email-error
                color: $error-red
                font-size: 10px

            .password-reset
                display: flex
                flex: 0
                justify-content: flex-end
                padding-top: 8px
                text-align: right

                .send-reset
                    color: $link-blue
                    font-size: 14px
                    font-weight: normal
                    margin-right: 8px

            .account-types,
            .permission-types
                color: $charcoal
                flex: 1
                font-size: 14px
                margin: 0 0 20px

                .info-icon
                    color: $main-blue
                    cursor: pointer
                    font-size: 20px
                    line-height: 32px
                    vertical-align: middle

                    &:hover
                        color: $main-blue-hover

                .permissions
                    display: flex
                    flex-direction: row
                    flex-wrap: wrap
                    margin-left: 8px
                    margin-top: 4px
                    max-height: 400px
                    overflow-y: auto
                    width: 100%

                    .permission
                        align-items: center
                        display: flex
                        width: 50%

                        .custom-field__container
                            padding-right: 16px
                            width: 180px

                .types
                    width: 47%

                .label-wrapper
                    align-items: center
                    display: flex

                .select-all
                    margin-left: 16px

                .label
                    color: $black-shade-60
                    font-size: 12px
                    font-weight: 500

            .account-types
                align-items: center
                display: flex
                margin: 32px 0 20px

                .custom-tooltip
                    display: inline

                    &__popover--top-left
                        transform: translateX(-76%)

            .with-reset-password
                margin: -32px 0 20px

            .tooltip-simplifeye-staff
                left: 4px
                position: relative
                top: 10px

.manage-admin__list-item-text
    padding: 0 6px
