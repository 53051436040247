@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.cancel-reschedule-settings-locations-list
    position: relative

    &__container
        .info-message
            margin: 0
            width: 100%

    &__table-item,
    &__table-item-first
        border-bottom: 1px solid $border-gray
        display: flex
        font-size: 16px
        font-weight: normal
        line-height: 19px
        padding: 22px 24px 22px 0

        .custom-multiselect__label
            font-weight: bold
            line-height: 16px
            margin: 0

    &__info-msg
        padding: 17px 0

    &__table-item-checkbox
        align-items: center
        display: flex
        justify-content: center
        padding: 8px 24px 0 0

    &__table-item-first
        align-items: center
        color: $main-blue
        display: flex
        font-size: 18px
        font-weight: 500
        padding: 12px 24px 12px 0

    & &__size22
        flex-basis: 22%
        max-width: 22%

        @media (max-width: $medium-width)
            flex-basis: 50%
            max-width: 50%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    & &__size20
        flex-basis: 20%
        max-width: 20%

        @media (max-width: $medium-width)
            flex-basis: 50%
            max-width: 50%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    & &__size18
        flex-basis: 18.5%
        max-width: 18.5%

        @media (max-width: $large-width)
            flex-basis: 22%
            max-width: 22%

        @media (max-width: $medium-width)
            flex-basis: 50%
            max-width: 50%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    & &__size15
        flex-basis: 14%
        max-width: 14%

        @media (max-width: $medium-width)
            flex-basis: 46%
            max-width: 46%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    & &__size13
        flex-basis: 13%
        max-width: 13%

        @media (max-width: $large-width)
            flex-basis: 16%
            max-width: 16%

        @media (max-width: $medium-width)
            flex-basis: 46%
            max-width: 46%

        @media (max-width: $small-width)
            flex-basis: 100%
            max-width: 100%


    &__table-header-item
        color: $label-gray
        font-size: 14px
        font-weight: 500
        line-height: 14px
        padding: 14px 0 14px 2px

    &__table-header-item-details
        justify-content: space-between
        width: 100%

    &__table-row,
    &__list-row
        border-bottom: 1px solid $border-gray

    &__error-list-row
        border-bottom: 0
        height: 88px

    &__table-pagination
        align-items: center
        display: flex
        justify-content: center
        width: 100%

    &__circular-progress-loader-body
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 28px)

    &__circular-progress-spinner
        color: $main-blue

    &__paginator-wrapper
        display: flex
        justify-content: center
        margin-bottom: 50px

    &__no-records
        margin-top: 24px

    &__role-picker
        width: 100%

    &__apply-global-settings-checkbox
        margin-left: 15px !important //sass-lint:disable-line no-important

        .custom-multiselect__label-hovered
            color: $main-blue

    &__checkbox
        align-items: flex-start
        border-bottom: 1px solid $border-gray
        display: flex
        flex-direction: column
        padding: 8px 4px 22px 0

        .custom-multiselect__label-hovered
            color: $main-blue

        .custom-multiselect__label
            align-items: flex-start
            font-weight: bold
            line-height: 16px
            margin: 0

        @media (max-width: $medium-width)
            align-items: flex-start
