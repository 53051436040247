// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'

$module-name: search-location-input

.#{$module-name}
    margin-right: 15px
    margin-top: 8px

    &__label
        color: $label-gray
        font-size: 12px
        margin: 5px

        &:hover
            color: $main-blue

    &__field
        border: 1px solid $input-border
        border-radius: 6px
        color: $soft-black
        display: flex
        font-size: 1rem
        height: 36px
        padding-left: 10px
        width: calc(100% - 8px)

        &:focus-within
            border-color: $input-border
            box-shadow: 0 1px 1px 0 $black-shade-25

        &:hover
            border-color: $main-blue
            box-shadow: 0 1px 6px 0 $black-shade-6

        & input
            border: 0

            &:focus
                outline: 0

    &__helper-text
        color: $label-gray
        display: flex
        font-size: 12px
        margin: 5px

.search-location-input--error
    .#{$module-name}__helper-text,
    .#{$module-name}__label
        color: $error-red

    .#{$module-name}__field
        border-color: $error-red

.pac-container
    border: 1px solid $input-border
    border-radius: 0 0 8px 8px
    box-shadow: 0 1px 2px 0 $black-shade-25
    z-index: 10000

    &::after
        background-image: none !important
        height: 0

.pac-target-input
    &:focus
        outline: none

.pac-item
    border: 0
    padding-left: 8px

.pac-item-query
    font-family: 'Roboto', sans-serif
    font-size: 14px
    line-height: 30px
