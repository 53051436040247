@import 'src/modules/shared/_colors.sass'


.link-expired-container
    align-items: center
    display: flex
    justify-content: center

.link-expired
    display: flex
    flex-direction: column
    margin-top: 100px
    max-width: 700px
    text-align: center
