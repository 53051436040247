@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_headers.sass'


.dexvoice-diagnostics
    .selection-header
        @extend %selection-header

    .statistics-subheader
        @extend %statistics-subheader

        .enabled
            background-color: $error-red

        .disabled
            background-color: $soft-black

        .download-license,
        .toggle-product
            float: right
            margin-right: 10px

