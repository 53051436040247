@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-button-colors

.#{$module-name}

    &__reset-colors
        color: $text-blue
        line-height: 32px
        text-decoration: none

        &:hover
            opacity: 0.75

