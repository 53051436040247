@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'

$title-height: 44px
$list-padding: 32px

.chat-transcript
    padding: 50px 16px 0

    .title
        color: $medium-black
        font-size: 24px
        padding-left: 8px

    .fields
        border-bottom: 1px solid $grey-97
        display: flex
        flex-wrap: wrap
        margin-left: -16px
        margin-right: -16px
        padding: 13px 24px 0
        width: calc(100% + 32px)

        .field
            padding-bottom: 16px

            .label
                font-size: 12px

            .field-data
                color: $medium-black
                font-size: 16px
                padding-top: 5px

            &--right
                align-content: flex-end

    .data-container
        max-height: 500px
        padding-top: 16px

        .title
            background-color: $main-blue
            color: $white
            font-size: 16px
            font-weight: 500
            line-height: 18px
            padding: 12px

    .transcript-container,
    .alerts-container,
    .survey-container
        max-height: 480px

        .custom__textarea
            border-color: $input-border
            border-radius: 0
            max-height: 400px
            width: calc(100% - 22px)

    .alerts-list,
    .survey-list
        border: 1px solid $input-border
        height: calc(100% - #{$title-height})

        .survey-component
            max-height: 420px

    .alerts-list
        height: calc(100% - #{$title-height} - #{$list-padding})
        overflow-y: auto
        padding: 16px

    .alerts-no-records
        padding: 0

    .btn-container
        justify-content: center
        padding: 22px 0
        text-align: center

    .send-to-referral-center-button
        font-weight: normal
        height: 52px
        padding: 18px 20px

    .alerts-loader
        align-items: center
        display: flex
        justify-content: center
        padding: 10px 0

    .alert-icon
        color: $main-blue

    .alert-wrapper
        display: flex
        padding: 4px 0

    .alert-text
        +long-word-break
        flex-basis: calc(100% - 24px)
        line-height: 16px
        max-width: calc(100% - 24px)
        padding-left: 8px
        width: 100%

        code
            white-space: pre-wrap

    .alert-label
        p
            margin: 4px 0
