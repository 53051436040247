@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'

$module-name: chat-alert-task

.#{$module-name}
    display: flex

    & &__form-control-root
        align-items: flex-start
        margin: 0 1px 0 -5px

        .#{$module-name}__checkbox-root
            color: $link-blue
            height: 24px
            width: 24px

    & &__form-control-label
        +long-word-break
        padding-top: 1px

    & &__checkbox-root
        color: $link-blue
        height: 24px
        padding: 0
        width: 24px

    &__label
        padding-top: 4px

    &__text
        & p,
        & pre
            margin: 0 4px
            white-space: normal

        &--marked
            color: $softer-gray
