@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.unclaimed-chats
    flex-grow: 0
    flex-shrink: 0
    min-height: 100px
    overflow-y: auto

    &:nth-child(2)
        padding-top: 5px
