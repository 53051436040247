@import 'src/modules/shared/_colors.sass'


.disconnect-modal
    > .contents
        &.contents
            top: 100px
            width: 900px

.disconnect-interface-message-container
    text-align: center

.disconnect-interface-warning-icon
    color: $error-red
    font-size: 48px
    margin-top: 36px

.disconnect-interface-error-message
    color: $error-red
    font-size: 32px
    margin-bottom: 12px
    margin-top: 4px

.disconnect-interface-images
    display: flex
    margin-bottom: 80px
    margin-top: 55px

    img
        display: block
        height: auto
        max-width: 100%

.disconnect-interface-left-socket
    align-self: center
    display: flex
    flex-basis: 45%
    flex-shrink: 0
    justify-content: flex-start

.disconnect-interface-lightnings
    align-items: center
    display: flex
    flex-basis: 10%
    flex-direction: column
    flex-shrink: 0

    .disconnect-interface-up-lightning
        flex-basis: 48%
        flex-shrink: 0

    .disconnect-interface-down-lightning
        flex-basis: 48%
        flex-shrink: 0

.disconnect-interface-right-socket
    align-self: center
    display: flex
    flex-basis: 45%
    flex-shrink: 0
    justify-content: flex-end
