@import 'src/modules/shared/_colors.sass'

$btn-border: #a4a4a4

.color-picker-button-wrapper
    display: flex
    margin-bottom: 10px
    position: relative

    .color-picker-button
        border: 1px $btn-border solid
        border-radius: 6px
        cursor: pointer
        display: flex
        flex-direction: row
        height: 30px
        outline: none
        overflow: hidden
        padding: 0
        position: relative
        width: 54px

        .current-color
            height: 28px
            width: 27px

        .arrow-drop-down
            position: relative
            top: 1px

    .color-picker-label
        cursor: pointer
        font-size: 14px
        font-weight: 500
        line-height: 29px
        padding-left: 10px

.show-picker
    .color-picker-button-wrapper
        .color-picker-button
            z-index: 1
