@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$main-blue-transparent: rgba($main-blue, 0.5)
$error-red-transparent: rgba($error-red, 0.5)

.referral-card
    background-color: $white
    border: 1px solid $border-gray
    border-radius: $default-border-radius
    margin: 17px 12px 0 11px

    &:hover
        cursor: pointer

.referral-card-header
    align-items: center
    border-top-left-radius: $default-border-radius
    border-top-right-radius: $default-border-radius
    color: $white
    display: flex
    height: 35px
    justify-content: flex-start
    padding: 0 6px 0 9px

    .unattended
        font-size: 16px
        margin-right: 4px


.referral-card-header--unclaimed
    animation: flash-alert 2s step-end infinite
    background-color: $main-blue-transparent

.referral-card-header--claimed
    background-color: $blue-gray

.referral-card-header--unattended
    background-color: $error-red

.referral-card-header--unattended--unclaimed
    animation: flash-alert-red 2s step-end infinite
    background-color: $error-red-transparent

.referral-card-header--finished
    background-color: $light-green

.referral-card-writter-chatter
    display: flex
    flex: 1 1 0
    justify-content: space-between

.referral-card-header-close-button
    &:hover
        color: $darker-white
        cursor: pointer

.referral-card-body
    color: $soft-black
    display: flex
    flex-direction: column
    font-size: 14px
    height: 90px
    justify-content: space-between
    margin: 4px 12px 6px 10px

    p
        margin: 0 0 3px

.referral-card-body__patient-name
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%

.referral-claim-button
    align-self: center

.referral-flagged-for-review
    color: $error-red
    text-align: right
