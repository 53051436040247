@import 'src/modules/shared/_colors.sass'

$hover-btn-bg: darken($new-alert-red, 10)

.location-confirmation-container
    background-color: $white
    border-radius: 4px
    height: 205px
    margin: 10% auto
    text-align: center
    width: 697px

    .confirmation-inner-container
        padding-top: 20px

        .title

            .text
                color: $black
                font-size: 24px
                font-weight: bold
                line-height: 32px
                padding-top: 2px
                width: calc(100% - 32px)

            .material-icons
                font-size: 50px
                padding-right: 15px
                vertical-align: middle

                &.red
                    color: $new-alert-red

                &.green
                    color: $lightgreen

        .buttons-container
            padding-top: 24px

            .confirm-btn
                &.red
                    background-color: $new-alert-red
                    border: 1px solid $new-alert-red
                    box-shadow: none
                    color: $white
                    font-size: 16px
                    font-weight: 500
                    height: 36px
                    padding: 6px 21px

                    &:hover
                        background-color: $hover-btn-bg

            .cancel-button
                background-color: $white
                border: 1px solid $grey-d9
                box-shadow: none
                color: $black

                &:hover
                    background-color: $header-gray
                    color: $black

        .staff-member-name
            white-space: nowrap

    .circular-progress-loader
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 25px)
