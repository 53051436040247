@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/shared/_mixins.sass'

$module-name: scheduling-new-patient-form

.#{$module-name}
    color: $dark-gray

    &--hidden
        display: none

    &__field-columns
        display: grid
        grid-gap: 0 9px
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
        width: 100%

        &--with-date
            grid-template-columns: repeat(auto-fit, minmax(88px, max-content))

    &__field
        margin-bottom: 3px

        &--datepicker
            position: relative

    &__masked-input
        background-color: $white
        border: 1px solid $input-border
        border-radius: $default-border-radius 0 0 $default-border-radius
        border-right: 0
        color: $soft-black
        display: flex
        font-size: 1rem
        height: 34px
        outline: none
        padding: 2px 0 2px 10px
        position: absolute
        top: 18px
        width: calc(100% - 39px)
        z-index: 2

        &::placeholder
            color: $label-gray
            opacity: 1

    &__calendar-icon
        cursor: pointer
        pointer-events: none
        position: absolute
        right: 7px
        top: 28px
        z-index: 2

    &__label
        color: $label-gray
        font-size: 12px
        margin: 5px

    &__value
        margin: 5px

    &__submit
        align-items: center
        display: flex
        justify-content: center
        margin: 24px 0

    & &__submit-button
        +primary-button(100px)
        line-height: 1.4em
        white-space: normal
        width: 215px

    &__progress
        padding: 20px
        text-align: center

        &--large
            align-items: center
            display: flex
            height: 145px
            justify-content: center
            padding: 70px

    &__appointment
        color: $very-soft-black
        padding-top: 10px
        text-align: center

    &__booked
        font-weight: 500

    &__calendar
        font-size: 56px
        margin: 16px auto 0
        text-align: center

    &__datetime
        font-size: 32px
        font-weight: bold
        line-height: 38px
        margin-bottom: 19px

    &__error
        color: $error-red
        font-size: 12px
        margin: 5px

    &__shared
        align-items: center
        display: flex
        flex-direction: column

    &__shared-label
        align-items: center
        color: $clear-green
        display: flex
        font-weight: bold
        margin-bottom: 20px

        .material-icons
            font-size: 20px
            margin-right: 5px
