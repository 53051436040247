@import 'src/modules/shared/_colors.sass'


.copy-text-inner-span
    cursor: pointer

    .custom-icon
        color: $soft-gray
        font-size: inherit
        padding-left: 4px
