@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/shared/_mixins.sass'

$module-name: end-chat-modal

.#{$module-name}
    align-items: center
    color: $lighter-black
    display: flex
    height: 100%
    justify-content: center

    &__loading-overlay
        align-items: center
        background-color: $black-shade-38
        display: flex
        height: 100%
        justify-content: center
        position: absolute
        width: 100%
        z-index: 1000

    &__modal
        background: $white
        max-height: 96vh
        overflow: auto
        position: relative
        width: 60%

    &__modal-header
        align-items: center
        display: flex
        flex-direction: row
        justify-content: space-between

        > button
            border-radius: 0
            color: $lighter-black
            height: 24px
            position: absolute
            right: 8px
            top: 8px
            width: 24px

            &:hover
                background-color: transparent
                box-shadow: 0 0 2px 0 $black-shade-50

    &__modal-content
        padding: 10px 0

    &__modal-actions-header
        margin-top: 0
        text-align: center

    &__modal-actions
        display: flex
        flex-direction: row
        justify-content: center
        margin-bottom: 35px

        // Modal action button component
        & .status-button
            border: 1px solid $input-border
            border-radius: 4px
            box-shadow: 3px 3px 5px $black-shade-6
            color: $lighter-black
            margin: 0 15px
            max-width: 200px
            padding: 11px
            width: 200px

            &--default
                &:hover
                    border-color: $text-blue

            &--successful
                background-color: $clear-green
                border-color: $clear-green
                color: $white

                &:hover
                    background-color: $clear-green

            &--unsuccessful
                background-color: $alert-red
                border-color: $alert-red
                color: $white

                &:hover
                    background-color: $alert-red

            &__content
                align-items: center
                display: flex
                flex-direction: column
                vertical-align: middle

            &__icon
                display: block
                font-size: 5em
                margin-bottom: 2px

            &__label
                display: block
                font-size: 1.2em
                line-height: 20px
                text-transform: uppercase

            &--disabled
                opacity: 0.5

    &__close-reasons
        background-color: $shortcuts-white
        margin-bottom: 10px
        padding: 20px 24px 10px

    &__reason-text
        font-weight: bold

        &.disabled
            opacity: 0.5

    &__reason-container
        display: flex
        flex-direction: row
        margin-left: -3px
        padding: 10px 0

    &__modal-col
        display: flex
        flex-direction: column
        width: 50%

    & &__reason-checkbox
        height: 31px
        margin-left: 4px
        width: 36px

    &__template-info
        max-width: calc(100% - 80px)
        padding: 24px 25px
        width: 100%

        h3
            font-weight: bold
            margin: 0 0 0.5em
            padding: 0
            text-align: left

        p
            margin: 0
            padding: 0

    &__template-container
        margin-top: -12px
        padding: 0 24px
        text-align: center

    &__template
        background-color: $sky-blue-light
        border-radius: 6px
        box-shadow: 0 1px 5px 0 $black-shade-20, 0 2px 2px 0 $black-shade-14, 0 3px 1px -2px $black-shade-12
        display: inline-block
        margin: 10px auto

    &__trello-card
        background-color: $white
        border-radius: 6px
        display: flex
        flex-direction: row
        justify-content: space-between
        margin: 0
        max-width: 350px
        min-width: 300px
        padding: 6px 12px 6px 23px

        pre
            text-align: left
            white-space: pre-line

    &__trello-copy,
    &__trello-copied
        align-self: baseline
        margin-top: 2px

    &__other-text
        padding-bottom: 8px

    &__close-chat
        align-items: center
        align-self: center
        display: flex
        justify-content: center
        padding: 8px 0

    & &__close-chat-button
        background-color: $main-blue
        border-radius: 4px
        color: $white
        line-height: 20px
        margin: 0 10px
        padding: 8px 16px

        &--primary
            background-color: $white
            border: 1px solid $main-blue
            color: $main-blue

        &--disabled
            background-color: $black-shade-12
            color: $black-shade-26

    & &__form-control-label
        font-size: 0.875rem



