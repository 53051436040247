@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_mixins.sass'
@import 'src/modules/chat-center/shared/_constants.sass'


.chat-tile-conversation
    display: flex
    flex: 1 1 auto
    flex-direction: column
    height: 100%
    overflow-y: auto
    position: relative

    .messages
        align-items: flex-start
        border-right: 1px solid $tab-border
        display: flex
        flex: 1 1 auto
        flex-direction: column
        height: 100%
        overflow-y: auto

        .message
            +long-word-break

            color: $very-soft-black
            font-size: rem(14px)
            letter-spacing: 0.5px
            margin: rem(7px 6px 3px 6px)
            padding: rem(5px) rem(9px)

        .practice-message
            align-self: flex-end
            background: $lightskyblue
            border: solid $lightskyblue
            border-radius: rem(19px 19px 4px)
            max-width: 100%
            width: fit-content

        .patient-message
            background: $lightgreen
            border: solid $lightgreen
            border-radius: rem(19px 19px 19px 4px)
            max-width: 100%
            width: fit-content

        .system-message
            font-size: rem(12px)
            line-height: rem(12px)
            margin: rem(2px 0)
            padding: rem(3px) rem(4px)

        .whisper-message
            align-self: center
            background: $lightgrey
            height: auto
            text-align: center
            width: 90%

        .timestamp
            color: $darkest-gray
            font-size: rem(12px)
            margin: rem(0 6px)

        .practice-timestamp
            align-self: flex-end
            margin: rem(0 6px)

        .bottom-anchor
            height: rem(1px)
            width: 100%

    .message-preview
        +long-word-break

        background-color: $ghostwhite
        border: 1px solid $grey-97
        color: $label-gray
        font-size: rem(14px)
        font-style: italic
        font-weight: lighter
        letter-spacing: 0.5px
        line-height: 16px
        max-height: 50%
        overflow: auto
        padding: 10px 5px 5px 10px
        text-align: left
