@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.staff-member-form
    .form
        padding: 10px 0

        .form-overlay-container
            position: relative

            .overlay
                background-color: $white
                height: 100%
                left: 0
                opacity: 0.7
                position: absolute
                top: 0
                width: 100%
                z-index: 1000

            .role-picker
                margin-top: 4px
                min-width: 160px

        .account-id,
        .created
            color: $black-shade-70
            padding-bottom: 5px

        .top-left-container
            flex-basis: 60%
            max-width: 60%

        .top-action-btn-container
            flex-basis: 40%
            max-width: 40%
            padding: 5px

        .top-action-btn
            align-items: center
            color: $text-blue
            cursor: pointer
            display: inline-flex
            margin-right: 24px

            &:hover
                color: lighten($text-blue, 10)

            .material-icons
                font-size: 19px
                vertical-align: middle

            .icon-trash
                margin-top: -3px

            .top-action-btn-name
                display: inline-block
                padding-left: 2px
                vertical-align: middle

                &--right
                    padding-right: 32px

        .account-deleted-info-box
            .text-container
                border: 1px solid $border-gray
                margin-bottom: 15px
                padding: 15px

                .material-icons
                    color: $red
                    vertical-align: middle

                .text
                    display: inline-block
                    padding-left: 5px
                    vertical-align: middle

            .btns-container
                display: flex
                justify-content: center
                margin-bottom: 25px


        .title
            color: $lighter-black
            font-size: 24px
            padding: 25px 0 12px

        .subtitle
            color: $lighter-black
            font-size: 20px
            padding: 25px 0 12px

        .notifications-column
            padding-right: 20px

        .notifications-subtitle
            >div
                display: inline-block

            .btn
                align-items: center
                color: $text-blue
                cursor: pointer
                display: inline-flex
                font-size: 16px
                padding-left: 20px

                &:hover
                    color: lighten($text-blue, 10)

                .material-icons
                    font-size: 15px

                .label
                    display: inline-block
                    padding-left: 3px

            .btn-disabled
                opacity: 0.5
                pointer-events: none

        .notifications
            .heading
                color: $gray
                font-size: 13px
                padding-bottom: 10px

        .select-all-chk
            display: inline-block
            padding-left: 20px

        .custom-field__container
            padding-top: 5px

        .permissions-container
            max-width: 500px

            .permission-checkbox
                height: 35px
                min-width: 200px

        .buttons-container
            padding-top: 40px

            .primary
                border: $text-blue

        .error-text-wrapper
            align-items: center
            color: $error-red
            display: flex
            margin: 8px 0

        .error-text
            padding-left: 4px

    .staff-circular-progress-loader
        bottom: calc(50% - 25px)
        left: calc(50% - 25px)
        position: fixed

.staff-member-modal
    .close-staff-form-modal-button-container
        display: flex
        justify-content: flex-end
        width: 100%

    .close-staff-form-modal-button
        cursor: pointer
        height: 24px
        width: 24px
        z-index: 1000

        &:hover
            box-shadow: 0 0 2px 0 $black-shade-50

.staff-member-confirmation-modal
    background-color: $white
    border-radius: $default-border-radius
    color: $dark-gray
    display: flex
    flex-direction: column
    left: 50%
    outline: none
    padding: 10px 10px 24px
    position: absolute
    top: 50%
    transform: translate(-50%, -50%) !important // sass-lint:disable-line no-important
    width: 678px

    .close-staff-form-modal-button-container
        display: flex
        justify-content: flex-end
        width: 100%

    .close-staff-form-modal-button
        cursor: pointer
        height: 24px
        width: 24px
        z-index: 1000

        &:hover
            box-shadow: 0 0 2px 0 $black-shade-50


