@import 'src/modules/shared/_colors.sass'

.staff-table-body
    overflow-x: auto
    position: relative

    .table-item,
    .narrower-table-item,
    .username-table-item
        font-size: 16px
        font-weight: 500
        line-height: 38px
        padding: 0 48px 0 2px

    .narrower-table-item
        padding: 0 24px 0 2px

    .username-table-item
        padding: 0 12px 0 2px

    .table-header-item
        color: $label-gray
        font-size: 14px
        font-weight: 500

    .table-first-item
        padding-left: 0

    .table-last-item
        padding-right: 0

    .table-row
        border-bottom: 1px solid $border-gray

    .table-pagination
        align-items: center
        display: flex
        justify-content: center
        width: 100%

    .circular-progress-loader
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 25px)

    .circular-progress-loader-body
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 78px)

    .circular-progress-spinner
        color: $main-blue
