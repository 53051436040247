// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.web-code-manager
    margin: 0 -8px 0 -12px

    .back-button
        background-color: $tab-fill
        border: 0
        color: $dark-gray
        height: 40px
        margin-left: 6px
        margin-right: 30px
        padding-left: 12px
        padding-right: 12px
        text-align: center

        &:hover
            cursor: pointer

    .back-button-wrapper
        display: flex
        margin-bottom: 16px
        margin-top: 8px
        width: 100%

    .loader
        align-items: center
        background-color: transparent
        display: flex
        height: 100%
        justify-content: center
        width: 100%

        &--global
            height: 148px

        &--list
            position: absolute

        .loader-spinner
            color: $main-blue

    .card-container
        display: flex
        flex-wrap: wrap
        margin-top: 10px
        position: relative

        .code-card
            background: $tab-fill-light
            border: 0
            border-top: 5px solid $primary-lime
            display: inline-block
            height: 260px
            margin: 6px
            position: relative
            width: 23.8%

            @media (max-width: $large-width)
                width: 23.6%

            @media (max-width: $medium-width)
                width: 47%


            &__wrapper
                display: flex
                flex-direction: column
                height: 188px

            &__container
                display: flex
                flex-direction: column
                max-height: 90%

                .code
                    color: $black-shade-38
                    font-size: 11px
                    overflow: auto

            &__header
                display: flex
                height: 54px
                margin-bottom: 5px

            &__heading,
            &__web-code-heading
                align-items: center
                color: $dark-gray
                display: flex
                font-size: 14px
                font-weight: bold
                justify-content: space-between
                letter-spacing: 0.5px
                margin-bottom: 3px

            &__web-code-heading
                margin: 4px 0 0

            &__web-code-key
                color: $lighter-black
                font-size: 13px
                line-height: 24px

            &__text
                color: $charcoal
                font-size: 14px
                font-weight: bold
                letter-spacing: 0.5px
                margin-top: 2px
                text-transform: uppercase

                &:active
                    color: $main-blue

            &__amp-webcode
                display: flex
                font-size: 14px
                font-weight: bold

            .edit
                bottom: 18px
                color: $dark-gray
                font-size: 14px
                font-weight: bold
                position: absolute
                right: 14px
                text-transform: uppercase

                &:hover
                    cursor: pointer

            .customize
                bottom: 18px
                color: $dark-gray
                font-size: 14px
                font-weight: bold
                left: 14px
                position: absolute
                text-transform: uppercase

                &:hover
                    cursor: pointer

            .switch
                margin-right: -10px

            .url
                color: $black-shade-38
                font-size: 18px
                overflow-wrap: break-word
                overflow-y: auto

                &.active-code
                    color: $dark-gray

            &__actions
                padding-right: 0
                padding-top: 4px

            &__buttons-space-between
                display: flex
                justify-content: space-between
                padding-left: 4px
                width: 100%

            &--code-inactive
                border-top: 5px solid $primary-red

    &__footer
        background: $tab-fill
        border-top: solid thin $tab-border
        display: flex
        height: 62px
        justify-content: center
        margin: 0 -24px -24px

        .button
            font-weight: bold
            line-height: 62px
            text-transform: uppercase

            .add-icon
                display: inline-flex
                padding-right: 2px
                position: relative
                top: -2px
                vertical-align: middle


        &:hover
            background: $main-blue
            cursor: pointer

            .button
                color: $white

    &__paginator-wrapper
        display: flex
        justify-content: center
        margin-bottom: 18px
        width: 100%

        .paginator
            margin-top: 24px

.contents
    &.code-modal
        box-sizing: border-box
        width: 727px !important

    &.code-modal-wide
        box-sizing: border-box
        width: 823px !important

.search-container
    display: flex
    flex-direction: column

