@import 'src/modules/shared/_colors.sass'

.amplify-referral-item
    padding: 16px

    .actions
        width: 48px

    .data
        .patient-name
            color: $soft-black
            font-size: 18px
            line-height: 20px

            .test-referral
                color: $soft-gray
                font-size: 14px

        .id
            color: $soft-gray
            font-size: 14px
            white-space: nowrap


    .inbound-handler,
    .referral-creator
        white-space: nowrap

        &.missing,
        &.legacy
            color: $missing-item

    .last-edit
        white-space: nowrap

    .amplify-referral-table-cell
        padding: 4px 32px 4px 20px

    .amplify-referral-table-cell-body
        font-size: 13px
        font-weight: 400

        .time-since
            color: $soft-gray
            font-size: 12px
            line-height: 20px

.referral-actions
    .amplify-referral__download-btn
        color: $main-blue
        letter-spacing: 0.5px
        min-width: 88px
        padding: 8px 16px

.amplify-referral-item__lifetime-value-container
    align-items: center
    display: flex
    justify-content: flex-start
    min-width: 80px
    position: relative

    .group-icon
        color: $grey-54
        font-size: 18px
        left: -20px
        position: absolute

    .amplify-referral-item__lifetime-value
        position: absolute

    .refresh-icon
        color: $link-blue
        cursor: pointer
        font-size: 14px
        position: absolute
        right: 0

        &:hover
            color: $sky-blue

.amplify-referral-form
    height: 100%

.amplify-referral-form__loading-overlay
    align-items: center
    background-color: $black-shade-38
    display: flex
    height: 100%
    justify-content: center
    position: absolute
    width: 100%
    z-index: 1000
