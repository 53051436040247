@import 'src/modules/shared/_colors.sass'

.survey-modal
    background: $white
    border-radius: 6px
    box-shadow: 0 0 20px $black-shade-50
    box-sizing: border-box
    color: $lighter-black
    margin: 50px auto
    max-width: 1210px
    position: relative

    &__loading-overlay
        background-color: $black-shade-20
        bottom: 0
        left: 0
        position: absolute
        right: 0
        top: 0
        z-index: 10

    &__loading
        left: 45%
        position: absolute
        top: 45%
        z-index: 10000

    &__main-title
        color: $lighter-black
        font-size: 32px
        font-weight: bold
        margin: 10px 0

        &--underlined
            border-bottom: 1px solid $tab-border

    &__header
        align-items: flex-start
        border-bottom: 1px solid $grey-b9
        box-sizing: border-box
        display: flex
        padding: 16px 20px
        width: 100%

    &__back
        cursor: pointer
        display: inline-block
        margin-right: 8px
        position: relative
        top: 3px

    &__title
        font-size: 24px
        padding-top: 4px

    &__actions
        margin-left: auto

    & &__edit-button
        margin-left: 16px

    & &__save
        margin-left: 10px

    & &__edit-icon
        display: inline-block
        font-size: 1.3em
        padding-left: 8px

    &__body
        max-height: calc(100vh - 200px)
        overflow-x: hidden
        overflow-y: auto
        padding: 20px

    .survey-section__header
        font-size: 18px
        margin: 19px 0 12px

    .survey-section__header-error
        color: $red
