@import 'src/modules/dexvoice/_common.sass'
@import 'src/modules/shared/_widget.sass'
@import 'src/modules/shared/_colors.sass'


.dexvoice-agent-list-item
    @extend %metric-card
    display: flex
    padding: 14px 16px

    &:last-child
        border-bottom: 0

    .info
        flex: 1

        .agent-id
            color: $charcoal
            font-size: 12px
            line-height: 20px
            text-align: left

        .room-name
            font-size: 18px


    .status
        flex: 0
        font-size: 24px
        padding-right: 10px

        .error
            color: $error-red

        .ok
            color: $success-green
