@import 'src/modules/shared/_colors.sass'

.notification-manager-modal
    .contents
        width: 900px

.notification-manager
    color: $dark-gray-43
    padding: 50px 32px 0

    > .header
        display: flex
        flex-direction: row
        padding-bottom: 16px
        padding-right: 16px

        > .locations
            flex: 0 1 auto
            min-width: 250px
            width: 15%

        > .title
            color: $soft-black
            flex: 1 1 auto
            font-family: 'Roboto', sans-serif
            font-size: 24px
            font-weight: 500
            padding-left: 16px

    > .subscriber-list
        .header,
        .subscriber
            .actions
                padding: 0
                text-align: center
                width: 15em

                .button
                    padding: 8px

            .active
                padding: 0
                width: 4em

            .id
                padding-right: 0
                width: 2em

            .title
                color: $dark-gray-43
                font-size: 14px
                font-weight: bold
                text-transform: uppercase

        .subscriber-list-row
            border-bottom: 1px solid $input-border
            font-size: 14px

            .list-cell
                padding: 10px 16px

    .circular-progress-loader
        left: 50%
        position: absolute
        top: 50%

    .notifications-paginator-wrapper
        display: flex
        justify-content: center
        padding-bottom: 28px

        .paginator
            margin-top: 24px

    .notifications-footer
        background: $tab-fill
        border-top: solid thin $tab-border
        display: flex
        font-size: 16px
        height: 62px
        justify-content: center
        margin: 0 -32px

        .button
            font-weight: bold
            line-height: 62px
            text-align: center
            text-transform: uppercase
            width: 100%

            .add-icon
                display: inline-flex
                font-size: 20px
                padding-left: 20px
                padding-right: 0
                position: relative
                top: -2px
                vertical-align: middle


            &:hover
                background: $main-blue
                color: $white
                cursor: pointer

            &.disabled
                color: $soft-gray

                &:hover
                    background: $tab-fill
                    pointer-events: none

    .circular-progress-loader-body
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 78px)

    .circular-progress-spinner
        color: $main-blue
