// sass-lint:disable no-important
// sass-lint:disable no-color-hex

@import 'src/modules/shared/_colors.sass'

$module-name: chatter-alert-expiry-date-notification

.#{$module-name}__transition-container
    margin-bottom: 20px
    width: 100%

    &.exit
        opacity: 1

    &.exit-active
        opacity: 0
        transition: opacity 3000ms

    &.exit-done
        display: none

.#{$module-name}__container
    align-items: center
    background-color: $white
    border: solid 1px $clear-green
    color: $lighter-black
    display: flex
    font-weight: 500
    margin-top: 16px
    padding: 18px

.#{$module-name}--red-container
    border: solid 1px $error-red

.#{$module-name}__status
    text-transform: uppercase

.#{$module-name}__remove-icon
    color: $error-red
    padding-right: 6px

.#{$module-name}__check-mark-icon
    color: $clear-green
    padding-right: 6px

    circle
        stroke: $white



