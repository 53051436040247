@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$error-red-transparent: rgba($error-red, 0.05)
$hover-btn-bg: darken($main-blue, 10)

$module-name: custom-atom-toggle

.#{$module-name}
    margin-bottom: 4px

    &__tabs
        display: flex

    &__tab
        align-items: center
        background-color: $white
        border: 1px solid $input-border
        color: $link-blue
        cursor: pointer
        display: flex
        height: 40px
        justify-content: center
        text-align: center
        text-transform: uppercase
        width: 50%

        &:last-child
            border-radius: 0 $default-border-radius $default-border-radius 0

        &:first-child
            border-radius: $default-border-radius 0 0 $default-border-radius

        &:hover
            background-color: $grey-e8
            color: $main-blue

        &--selected
            background: $link-blue
            color: $white

            &:hover
                background-color: $hover-btn-bg
                color: $white

        &--error
            background: $error-red-transparent

            &:hover
                background: $error-red-transparent

    &__error-message
        color: $error-red
        font-size: 12px
        margin-top: 5px
