@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-enable-custom-confirmation-page

.#{$module-name}

    &__title
        color: $grey-47
        font-size: 18px
        font-weight: 500
        margin-bottom: 0
        margin-top: 0

    &__switch
        padding: 10px 0 16px

    & &__switch-root
        margin-left: -8px

    & &__switch-root &__switch--checked
        color: $text-blue

    & &__switch-and-input
        align-items: center
        display: flex
        padding: 8px 0 8px 4px

    & &__url-wrapper
        width: 100%
