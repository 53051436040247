@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: self-scheduling-web-code-form

.#{$module-name}
    position: relative

    &__container
        padding: 25px 51px 21px

    &__header
        align-items: center
        display: flex
        flex-direction: row
        justify-content: space-between

    &__name-header-wrapper
        align-items: center
        display: flex
        justify-content: space-between
        margin: 16px 0 20px

    &__name-header
        font-size: 28px
        font-weight: bold
        margin-top: 0

    &__delete-button
        align-items: center

    .top-action-btn
        color: text-blue
        position: absolute
        right: 40px
        top: 50px

    &__code-header
        font-size: 18px
        font-weight: 500

    &__key
        color: $gray
        font-size: 80%

    &__error
        color: $error-red
        font-size: 12px
        margin-bottom: 25px
        margin-top: 0

    &__copy-code-wrapper
        display: flex
        justify-content: space-between
        margin: 20px 0 10px

    &__embed-code-wrapper
        display: flex
        justify-content: flex-end
        margin: 10px 0

    &__copy-code-button
        color: $soft-black
        font-size: 14px
        letter-spacing: 0
        position: relative
        text-transform: uppercase

        &.active
            color: $dark-gray
            font-weight: bolder

    &__embed-code
        max-height: 300px
        min-height: 50px
        overflow: auto
        padding: 5px
        width: 100%

        pre
            color: $soft-black
            font-family: monospace
            margin: 0 0 4px

    &__embed-link
        min-height: 20px
        overflow: auto
        padding: 5px 0 0
        width: 100%

        pre
            color: $soft-black
            font-family: monospace
            margin: 0

    .custom__input-error-icon
        top: 7px

    .custom-tooltip__children
        height: 17px

    .custom-tooltip__popover--top-left
        &::after
            right: calc(17% - 6px)

.self-scheduling-confirmation-modal
    background-color: $white
    border-radius: $default-border-radius
    color: $dark-gray
    display: flex
    flex-direction: column
    left: 50%
    outline: none
    padding: 10px 10px 24px
    position: absolute
    top: 50%
    transform: translate(-50%, -50%) !important
    width: 480px

    > .close-modal-button
        cursor: pointer
        display: flex
        justify-content: flex-end
        z-index: 1

        .material-icons
            &:hover
                box-shadow: 0 0 2px 0 $black-shade-50
