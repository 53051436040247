@import 'src/modules/shared/_colors.sass'

$color-picker-top: 155px
$vertical-spacing-diff: 10px
$box-shadow-black1: rgba($black, 0.12)
$box-shadow-black2: rgba($black, 0.24)

.color-picker
    background-color: $white
    border: 1px $lightgray solid
    box-shadow: 0 1px 3px $box-shadow-black1, 0 1px 2px $box-shadow-black2
    box-sizing: border-box
    cursor: default
    left: 71px
    margin-bottom: $vertical-spacing-diff
    padding: 10px
    position: absolute
    top: -$color-picker-top
    width: 220px
    z-index: 2

    &::before
        border: 13px transparent solid
        border-left-width: 0
        border-right-color: $lightgray
        content: ''
        left: -13px
        position: absolute
        top: $color-picker-top

    &::after
        border: 13px transparent solid
        border-left-width: 0
        border-right-color: $white
        content: ''
        left: -11px
        position: absolute
        top: $color-picker-top

    .saturation-picker
        height: 150px
        margin-bottom: $vertical-spacing-diff
        position: relative
        width: 200px

        > div
            height: 100%
            width: 100%

    .hue-picker
        height: 15px
        margin-bottom: $vertical-spacing-diff
        position: relative
        width: 200px

        > div
            height: 100%
            width: 100%

    .hue-horizontal
        > div
            > div
                padding-bottom: 5px

    .picker-actions
        display: flex
        justify-content: space-between
        margin-top: $vertical-spacing-diff

.hex-field,
.rgb-fields
    margin-bottom: $vertical-spacing-diff
    position: relative

    > div
        position: relative

        > span
            color: $gray
            font-size: 11px
            left: 0
            position: absolute
            text-transform: capitalize
            top: 0

    input
        border: 0
        border-bottom: 1px solid $lightgray
        color: $black
        font-size: 15px
        height: 30px
        margin-top: 0
        outline: none
        padding: 0
        width: 100%

        &:focus
            border-bottom-color: $brand-teal

.rgb-fields
    display: flex
    margin-bottom: 0

    > div
        margin-left: 10px

        &:first-child
            margin-left: 0

        > input
            margin-top: 12px
            width: 100%
