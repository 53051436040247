@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-button-customization-options

.#{$module-name}
    display: flex
    height: 100%
    justify-content: left
    margin-left: 50px
    padding: 15px 4px

    h3
        color: $black
        font-size: 16px
        font-weight: 500
        margin-top: 0

    &__left,
    &__right
        display: flex
        flex-basis: 50%
        flex-direction: column
        height: 100%

        .color-picker-button
            &:hover
                border: 1px solid $main-blue
