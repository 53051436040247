@import 'src/modules/shared/_colors.sass'


.web-code-customization-wrapper
    display: flex
    flex-direction: column
    height: 100%

    .web-code-customization
        height: calc(100% - 56px)
        overflow-x: auto
        padding: 25px 51px

    .header
        align-items: center
        font-weight: bold

    .webcode-button
        background: $tab-fill
        color: $black
        cursor: pointer
        display: block
        font-size: 14px
        font-weight: bold
        padding: 20px
        text-align: center
        text-transform: uppercase
        width: 100%

        &:not(:disabled)
            &:hover
                background: $primary-hover-bg
                color: $white

        &:disabled
            cursor: default

        &.can-save
            background: $main-blue
            color: $white
            cursor: pointer

    .name-header
        font-size: 32px
        line-height: 42px
        width: 100%

    .url-header
        font-size: 20px
        line-height: 32px
        overflow-wrap: break-word
        overflow-y: auto
        padding-bottom: 16px
        width: 100%

    .message
        .field
            width: 100%

    .table-action
        background: $tab-fill

        .button
            border-top: solid thin $tab-border
            color: $black
            font-weight: 500
            line-height: 45px
            text-transform: uppercase

            &:hover
                box-shadow: 0 0 0 1px $main-blue inset
                cursor: pointer

            &.disabled
                &:hover
                    box-shadow: 0
                    pointer-events: none

            .add-icon,
            .close-icon
                display: inline-flex
                padding-left: 20px
                padding-right: 10px
                position: relative
                top: -2px
                vertical-align: middle

            .close-icon
                display: none

            &.open
                background-color: $white

                .add-icon
                    display: none

                .close-icon
                    display: inline-flex

                &:hover
                    background: $white

    .welcome-messages
        .title
            margin-bottom: 8px
