@import 'src/modules/shared/_colors.sass'

.pms-writeback-settings-practice-header
    display: flex

    .info
        flex: 1 1 auto
        max-width: 85%

        .created,
        .db-id
            color: $charcoal
            font-size: 14px
            line-height: 24px

        .title
            display: flex
            flex-direction: row
            font-size: 24px
            line-height: 32px

        .practice-header__text
            color: $black-shade-87
            width: unset

    .pms-writeback-settings-title
        padding-right: 4px

    .status
        align-items: center
        color: $charcoal
        display: flex
        flex-basis: 30%
        height: 18px
        justify-content: flex-end
        text-transform: uppercase

        .company-logo-icon
            cursor: pointer
            display: flex

        .dot
            background: $grass-green
            border-radius: 99rem
            display: inline-block
            height: 18px
            margin-left: 9px
            width: 18px

        .inactive
            background: $error-red

        .label
            font-size: 12px
            height: 18px
            line-height: 18px
            margin-left: 8px
            text-transform: uppercase

        .settings
            color: $black
            cursor: pointer
            font-size: 12px
            margin-right: 10px
