@import 'src/modules/shared/_colors.sass'

$module-name: amplify-tab

.#{$module-name}
    position: relative

    &__back-button
        background-color: $white
        cursor: pointer
        margin-left: -16px
