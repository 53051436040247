@import 'src/modules/shared/_core.sass'

$products-width: calc(50% - 2px)
$integration-type-width: calc(50% - 3px)

.create-practice
    display: flex
    flex-direction: column

    .content
        flex: 1 1 auto
        margin-top: 30px
        max-height: 68vh
        overflow-y: auto
        padding: 0 50px

        .search-location-input
            margin-right: 0
            margin-top: 0

        // TODO this is badly being shared across CreatePracticeSearch and CreatePracticeForm
        .create-search
            margin-top: 8px

        .search-results
            margin: 15px 0
            max-height: 210px
            min-height: 80px
            overflow-y: auto
            padding: 2px 5px 2px 0

            .potential-dupe
                align-items: center
                display: flex
                flex-direction: row
                justify-content: space-between
                margin-bottom: 6px

                &:last-child
                    margin-bottom: 0

                .name
                    flex: 1 0 auto

                .view-practice
                    flex: 0 0 auto

                .view-practice-button
                    min-width: 88px

        .address-street
            &:hover
                .search-location-input
                    &__label
                        color: $main-blue

                    &__field
                        background-color: $shortcuts-white
                        border-color: $main-blue

        .row
            display: flex

            &:last-child
                padding-bottom: 25px

            > div
                margin: 0 5px 5px

                &:first-child
                    margin-left: 0

                &:last-child
                    margin-right: 0

            .address-city
                flex: 2

            .address-name
                flex: 1

            .address-state
                flex: 2

            .address-street
                flex: 4

            .address-suite
                flex: 1

            .address-title
                flex: 1
                font-size: 18px
                margin-top: 40px

                button
                    margin-left: 8px

            .address-zip
                flex: 1

            .address-country,
            .address-timezone
                width: 380px

            .admin-email
                flex: 1

            .admin-name
                flex: 1

            .admin-role
                flex: 1

            .integration-types
                margin-left: 2px
                max-width: $integration-type-width
                min-width: $integration-type-width

            .multi-locations
                flex: 1
                margin-top: 8px

            .phone-number
                flex: 1

            .practice-name
                flex: 1

            .practice-user-count
                flex: 1

            .practice-specialty-type,
            .products
                max-width: $products-width
                min-width: $products-width

    .save
        background-color: $darker-white
        color: $charcoal
        display: block
        flex: 0 1 auto
        font-size: 14px
        letter-spacing: 0.5px
        padding: 16px 0
        text-align: center
        text-transform: uppercase

        &.active
            color: $white

            &:hover
                cursor: pointer
                font-weight: bold

        &.can-save
            background: $main-blue

    .field--position
        align-items: flex-end
        display: flex

    .field-position__helper
        display: inline-block
        font-size: 1.2em
        margin-left: 6px
        padding-bottom: 8px
        text-align: right
        width: 23px
