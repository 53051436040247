@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'


$module-name: scheduling-search-existing-patient

.#{$module-name}
    &__field-columns
        display: grid
        grid-gap: 0 9px
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
        width: 100%

    &__field
        margin-bottom: 3px

    &__submit
        align-items: center
        display: flex
        justify-content: center
        margin: 24px 0

    & &__submit-button
        +primary-button(100px)
        line-height: 1.4em
        white-space: normal
        width: 215px

    &__error
        align-items: flex-start
        background: $error-red
        color: $white
        display: flex
        font-size: 16px
        font-weight: bold
        margin: 20px 0 30px
        padding: 10px

    &__message
        max-width: 85%
        padding-left: 5px
