// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: self-scheduling-web-code-manager

.#{$module-name}
    margin: 0 -8px 0 -12px

    &__back-button
        background-color: $tab-fill
        border: 0
        color: $dark-gray
        height: 40px
        margin-left: 6px
        margin-right: 30px
        padding-left: 12px
        padding-right: 12px
        text-align: center

        &:hover
            cursor: pointer

    &__back-button-wrapper
        display: flex
        margin-bottom: 16px
        margin-top: 8px
        width: 100%

    &__card-container
        display: flex
        flex-wrap: wrap
        margin-top: 10px
        position: relative

    &__paginator-wrapper
        display: flex
        justify-content: center
        margin-bottom: 18px
        width: 100%

        .paginator
            margin-top: 24px

    .loader
        align-items: center
        background: transparent
        display: flex
        height: 100%
        justify-content: center
        width: 100%

        &--global
            min-height: 148px

        &--list
            position: absolute

        .loader-spinner
            color: $main-blue

    &__footer
        background: $tab-fill
        border-top: solid thin $tab-border
        display: flex
        height: 62px
        justify-content: center
        margin: 0 -24px -24px

        .button
            font-weight: bold
            line-height: 62px
            text-transform: uppercase

            .add-icon
                display: inline-flex
                padding-right: 2px
                position: relative
                top: -2px
                vertical-align: middle

                &:hover
                    background: $main-blue
                    cursor: pointer

                .add-icon
                    color: $dark-gray
                    font-size: 50px
                    opacity: 0.55
                    padding-left: 7px

        &:hover
            background: $main-blue
            cursor: pointer

            .button
                color: $white

    &__new-practice-button
        font-size: 28px
        height: 194px
        line-height: 220px
        max-width: 330px
        text-align: center
        vertical-align: middle
        width: calc(33% - 14px)

        &:hover
            background: $main-blue
            cursor: pointer

        @media (max-width: $large-width)
            width: 330px


    &__add-icon
        color: $black
        font-size: 50px
        opacity: 0.55
        padding-left: 7px

    &__code-modal
        box-sizing: border-box
        position: relative
        width: 750px !important

.search-container
    display: flex
    width: 100%

