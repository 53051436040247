
@import 'src/modules/shared/_colors.sass'


.unbound-agent-modal
    .close-modal-button
        height: 24px
        padding: 20px 0 0 20px
        width: 24px

        &:hover
            cursor: pointer

    .header
        color: $black
        font-size: 14px
        font-weight: bold
        padding-left: 60px
        padding-right: 15px
        text-transform: uppercase
