@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.nav-toolbar
    align-items: center
    background-color: $black-shade-87
    box-shadow: 0 0 2px 0 $black-shade-12, 0 2px 2px 0 $black-shade-24
    color: $tab-fill-light
    display: flex
    height: 57px
    width: 100%

    &__mobile-menu
        display: none
        padding: 0 10px

        @media screen and (max-width: $medium-width)
            display: block


    &__desktop-menu
        align-items: center
        display: flex

        @media screen and (max-width: $medium-width)
            display: none


    &__navigation
        display: flex
        height: 38px
        justify-content: space-between
        margin: 0
        padding: 0 25px 0 70px
        width: 100%

        @media screen and (max-width: $medium-width)
            padding-left: 16px


    &__quick-menu
        padding-bottom: 15px
        position: absolute
        transform: translate(-90%, 4px)

        @media screen and (max-width: $medium-width)
            padding-bottom: 0
            position: relative
            transform: translate(0, 4px)


    &__menu-list &__menu-item
        display: flex
        justify-content: left
        padding: 8px 16px

    &__menu-icon
        color: $white
        position: relative
        top: -3px

    &__page-link,
    &__page-link-yext
        cursor: pointer
        flex: 0 0 auto
        font-size: 14px
        margin-right: 8px
        padding: 10px 8px
        position: relative
        text-transform: uppercase
        user-select: none

        &--selected
            color: $main-blue
            cursor: default

    &__page-link-yext
        margin-right: 20px

    &__launch-icon
        font-size: 18px
        padding-left: 4px
        position: absolute
        top: 9px

    &__badge
        align-items: center
        background: $error-red
        border-radius: 50%
        color: $white
        display: inline-flex
        font-size: 10px
        height: 18px
        justify-content: center
        left: 3px
        position: relative
        top: -9px
        width: 18px

    &__slot
        flex: 1
        padding-right: 16px
