@import 'src/modules/shared/_colors.sass'

.banned-ip-addresses-table
    height: auto
    margin-left: 50px
    overflow-y: auto

    .table-head-row
        height: 30px

    .table-head-cell
        padding: 8px 8px 2px

    .table-cell
        color: $black
        padding: 8px

        .banned-by-div
            color: $dark-gray
            padding-top: 6px

    .table-cell-action
        padding: 0
        width: 32px

        .action-button
            font-size: 12px
            min-height: 28px
            min-width: 40px
            padding: 8px 4px

        .action-buttons-wrapper
            display: flex
            flex-direction: row

            .icon-action
                color: $main-blue
                font-size: 18px
                padding-left: 8px

            .icon-button
                &:hover
                    opacity: 0.75

.add-new-ip-address-div
    color: $main-blue
    cursor: pointer
    display: flex
    flex-direction: row
    padding-top: 12px

    &:hover
        opacity: 0.75

    .add-ip-address-text
        font-size: 12px
        line-height: 18px
        padding-left: 6px

    .icon-button
        font-size: 18px

.ban-div
    align-items: center
    color: $error-red
    cursor: pointer
    display: flex
    flex-direction: row
    float: right
    height: 36px
    width: 68px

    .ban-label
        padding-left: 6px



