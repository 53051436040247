@import 'src/modules/shared/_colors.sass'


$module-name: self-scheduling-additional-features-and-fields-switch

.#{$module-name}

    &__title
        color: $grey-47
        font-size: 18px
        font-weight: 500
        margin-bottom: 0
        margin-top: 0

    &__switch
        padding: 10px 0 22px

    & &__switch-root &__switch--checked
        color: $text-blue

