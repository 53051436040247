@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_modals.sass'


.referral__modal
    @extend %new-item-modal

.loading-overlay
    background-color: $black-shade-20
    bottom: 0
    left: 0
    position: absolute
    right: 0
    top: 0
    z-index: 10

.loading
    left: 45%
    position: absolute
    top: 45%
    z-index: 10000
