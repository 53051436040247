@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/shared/_mixins.sass'

$module-name: cancel-reschedule-settings

.app-root > .#{$module-name}
    margin-top: 30px
    padding: 0 70px

.#{$module-name}
    .detached-button
        color: $black
        cursor: pointer
        height: 24px
        position: absolute
        transform: translateX(-200%)

    &__title
        color: $lighter-black
        font-size: 24px
        font-weight: 500
        letter-spacing: 0
        line-height: 28px
        margin-top: 20px

    &__subtitle-wrapper
        border-bottom: 1px solid $black-shade-12
        display: flex
        justify-content: space-between
        margin-top: 8px
        padding-bottom: 10px

    &__subtitle
        color: $lighter-black
        font-size: 16px
        line-height: 24px

    &__global-settings-button
        align-items: center
        color: $text-blue
        cursor: pointer
        display: inline-flex

        &:hover
            color: lighten($text-blue, 10)

    &__global-settings-icon
        font-size: 18px
        margin-left: 4px
        margin-top: 2px

    .search-bar-container
        padding-top: 12px
