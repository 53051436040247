@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'
@import '../../shared/_constants.sass'


$module-name: confirm-modal-one-button

.#{$module-name}
    background-color: $white
    border-radius: $default-border-radius
    box-shadow: 0 4px 12px 0 $shadow-gray
    margin: auto
    max-width: 696px
    position: relative
    top: 10%
    width: 90%

    &__header
        border-bottom: 1px solid $black-shade-12
        color: $dark-gray-43
        font-size: 24px
        font-weight: 600
        padding: 10px 15px 9px 19px

    &__close
        position: absolute
        right: 0
        top: 0

    &__close-icon
        color: $soft-black

    &__content
        background-color: transparent
        color: $dark-gray-43
        font-size: 24px
        font-weight: 600
        padding: 20px 16px 16px
        text-align: center

        & > .material-icons
            color: $text-blue
            font-size: 48px

    &__children
        margin: 10px 0

    &__footer
        display: flex
        justify-content: center
        padding: 16px 17px 24px

    & &__button
        border: 1px solid $text-blue
        border-radius: 6px
        color: $text-blue
        font-size: 16px
        font-weight: 500
        height: 36px
        min-width: 112px
        padding: 7px 27px

        &--primary
            color: $text-blue

        &--alert
            background-color: $alert-red
            border: 0
            color: $white

            &:hover
                background-color: $alert-red
                color: $white
