@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: availability-filters

.#{$module-name}
    padding-top: 4px

    .custom-multiselect__dropdown-item
        // sass-lint:disable class-name-format
        .MuiFormControlLabel-root
            margin-right: 0

    &__submit-button-wrapper
        display: flex
        justify-content: center
        padding-bottom: 24px
