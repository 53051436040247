@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: question-select-multi

.#{$module-name}
    &__error
        color: $error-red
        font-size: 12px

    .custom__input-error-icon
        top: 8px

    &--columns-2
        .#{$module-name}__options
            column-count: 2
            column-gap: 25px
            display: block

        .#{$module-name}__checkbox
            break-inside: avoid-column
            display: flex

    &--columns-3
        .#{$module-name}__options
            column-count: 3
            column-gap: 25px
            display: block

        .#{$module-name}__checkbox
            break-inside: avoid-column
            display: flex

    &--toggle
        .#{$module-name}__checkbox
            align-items: center
            border-bottom: 1px solid $tab-border
            border-top: 1px solid $tab-border
            display: flex
            margin-top: -1px
            padding: 12px 0

        .#{$module-name}__toggle-label
            color: $grey-47
            padding-left: 4px
