@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.practice-header
    display: flex

    .info
        flex: 1 1 auto
        max-width: 85%

        .created,
        .db-id
            color: $charcoal
            font-size: 14px
            line-height: 20px

        .title
            display: flex
            flex-direction: row
            font-size: 24px
            line-height: 32px

        .practice-header__text
            width: unset

    .cog-icon-settings
        color: $main-blue
        margin-top: 2px

        &:hover
            color: $main-blue-hover

    .status
        align-items: center
        color: $charcoal
        display: flex
        flex-basis: 25%
        height: 18px
        justify-content: flex-end
        text-transform: uppercase

        .company-logo-icon
            cursor: pointer
            display: flex

        .dot
            background: $grass-green
            border-radius: 99rem
            display: inline-block
            height: 18px
            margin-left: 9px
            min-width: 18px
            width: 18px

        .inactive
            background: $error-red

        .label
            font-size: 12px
            height: 18px
            line-height: 18px
            margin-left: 8px
            text-transform: uppercase

        .settings
            color: $black
            cursor: pointer
            font-size: 12px
            margin-left: 8px

    .circular-progress-loader
        bottom: 50%
        left: 40%
        position: absolute

    &__non-editable-text
        font-size: 24px
        line-height: 32px


@media screen and (max-width: $medium-width)
    .practice-header
        .status
            flex-basis: 35%

@media screen and (max-width: $small-width)
    .practice-header
        .status
            flex-basis: 50%
