@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.config-logs-list
    overflow-x: auto
    position: relative

    .table-item
        font-size: 16px
        font-weight: normal
        line-height: 19px
        padding: 24px 48px 24px 2px

    .table-header-item
        color: $label-gray
        font-size: 14px
        font-weight: 500
        line-height: 14px
        padding: 14px 0 14px 2px

    .table-header-item-details
        display: flex
        justify-content: space-between

    .download-icon
        font-size: 16px

    .csv-download-button
        color: $main-blue
        cursor: pointer
        font-size: 16px
        font-weight: 500

        &:hover
            color: $main-blue-hover

    .csv-download-button-disabled
        color: $tab-border

        &:hover
            color: $border-gray
            cursor: default

    .csv-button-export
        align-content: center
        display: flex

    .table-row,
    .list-row
        border-bottom: 1px solid $border-gray

    .error-list-row
        border-bottom: 0
        height: 88px

    .table-pagination
        align-items: center
        display: flex
        justify-content: center
        width: 100%

    .circular-progress-loader-body
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 28px)

    .circular-progress-spinner
        color: $main-blue

    .config-logs-paginator-wrapper
        display: flex
        justify-content: center
        margin-bottom: 50px

    .no-records
        margin-top: 24px

    .csv-error-message
        position: absolute
        top: 64px
        width: 100%
