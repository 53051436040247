// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'

$module-name: chatter-alert-manager
$cell-basis: 90px
$padding-between-cells: 8px

$exp-date-cell-additional-width: 36px
$small-cells-total-width: 5 * $cell-basis + $exp-date-cell-additional-width
$large-cell-basis: calc(100% - #{$small-cells-total-width})

$primary-button-hover-bg: darken($main-blue, 10)

.#{$module-name}-modal
    .contents
        width: 900px

.#{$module-name}
    height: 80vh
    padding: 48px 16px 24px 24px

    .copy-text-inner-span
        margin-top: 4px

        .custom-icon
            padding-left: 0

    &__header
        align-items: center
        display: flex
        flex-direction: row
        margin-bottom: 15px
        width: 100%

    &__title
        color: $soft-black
        flex: 1 1 auto
        font-family: 'Roboto', sans-serif
        font-size: 24px
        font-weight: 500
        text-align: left

    &__add-button
        background-color: $main-blue !important

        &:hover
            background-color: $primary-button-hover-bg !important

    &__notifications-container
        padding-top: 16px

    &__edit-alert
        display: flex
        flex-direction: column
        margin: 15px 0 10px
        width: 100%

    &__edit-buttons-container
        display: flex
        justify-content: flex-end
        padding: 16px 0 8px

    &__table
        margin-top: 10px

    &__table-header
        border-bottom: 1px solid $light-gray
        box-sizing: border-box
        color: $label-gray
        display: flex
        flex-wrap: wrap
        font-size: 14px
        margin: 0
        text-transform: uppercase
        width: 100%

    &__table-header-cell
        box-sizing: border-box
        flex-basis: $cell-basis
        flex-grow: 0
        margin: 0
        max-width: $cell-basis
        padding: $padding-between-cells

        &--center
            display: flex
            justify-content: center

        &--exp-date-cell
            display: flex
            flex-basis: calc($cell-basis + $exp-date-cell-additional-width)
            justify-content: center
            max-width: calc($cell-basis + $exp-date-cell-additional-width)

        &--large
            flex-basis: $large-cell-basis
            max-width: $large-cell-basis

    &__circular-progress-loader
        bottom: calc(50% - 25px)
        left: calc(50% - 25px)
        position: absolute

    &__table-body-cell--websites
        min-width: 360px
        width: 35%

    &__table-body
        color: $dark-gray
        display: flex
        flex-direction: column

    &__table-body-cell
        box-sizing: border-box
        display: flex
        flex: 0 0 $cell-basis
        max-width: $cell-basis
        padding: 0 $padding-between-cells
        position: relative

        &--center
            justify-content: center
            margin-top: 13px

        &--exp-date-cell
            flex-basis: calc($cell-basis + $exp-date-cell-additional-width)
            justify-content: center
            margin-top: 13px
            max-width: calc($cell-basis + $exp-date-cell-additional-width)

        &--large
            flex-basis: $large-cell-basis
            max-width: $large-cell-basis

    &__table-row
        border-bottom: 1px solid $light-gray
        display: flex
        flex-wrap: wrap
        justify-content: flex-end
        margin: 0
        min-height: 55px
        outline: none
        padding-bottom: 10px
        user-select: none

        &:hover
            background-color: $table-row-hover

    &__row--added
        box-sizing: border-box
        display: flex
        margin: 20px 0
        width: 100%

    &__add-alert-cell
        margin-left: 2 * $cell-basis

    &__buttons-cell
        flex-basis: 3 * $cell-basis
        max-width: 3 * $cell-basis

    &__row--edited
        background-color: $table-row-hover

    &__add-new-buttons-container
        align-items: center
        display: flex
        justify-content: flex-end

    &__action-button
        height: 36px
        margin-left: 20px !important

    &__save-button
        width: 120px

    &__cancel-button
        background-color: $white !important
        border: 1px solid $text-blue !important
        color: $lighter-black !important
        width: 120px

    &__add-icon
        font-size: 16px
        padding-bottom: 1px
        padding-right: 4px

    &__expiration-action
        text-align: center !important

    &__render-exp-date
        display: flex
        width: calc($cell-basis + $exp-date-cell-additional-width)

    &__remove-icon
        color: $main-blue
        cursor: pointer
        font-size: 18px
        padding-left: 2px
        padding-top: 2px

        &:hover
            color: $main-blue-hover

    &__datepicker
        cursor: pointer
        height: 24px
        opacity: 0
        position: absolute !important
        width: 40px
        z-index: 1

        & input
            padding: 0

        &:hover + .hoverable
            color: $main-blue-hover

    &__datepicker-set
        left: 37px
        margin-left: -32px !important
        margin-top: -16px !important
        opacity: 0
        top: 15px
        width: 86px !important

        &:hover
            color: $main-blue-hover

    &__alert-text
        +long-word-break
        padding-top: 2px
        width: 100%

        &--collapsed
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap

        & pre
            margin: 0
            white-space: normal

    &__date
        align-items: flex-start
        cursor: pointer
        display: flex
        width: 0


    &__date-wrapper
        align-items: center
        display: flex
        justify-content: center

        &:hover
            opacity: 0.75

    &__date--blue
        color: $text-blue

    &__date--red
        color: $alert-red

    &__date--not-set
        padding: 11px 20px 0 0 !important

    &__date--set
        padding: 16px 0 0 !important

    &__action-icon
        color: $text-blue
        cursor: pointer
        font-size: 22px
        height: 24px

        &:hover
            opacity: 0.75

    &__expire-icon
        color: $text-blue
        cursor: pointer
        height: 24px

        &:hover
            opacity: 0.75

    &--inactive
        opacity: 0.75

        > .title
            flex: 1 1 auto
            font-family: 'Roboto', sans-serif
            font-size: 24px

    > .alerts-list
        .header,
        .chatter-alert
            .actions
                padding: 0
                text-align: center
                width: 15em

                .button
                    padding: 8px

            .id
                padding-right: 0
                width: 2em

            .title
                color: $soft-black
                font-size: 14px
                font-weight: bold
                text-transform: uppercase

            .alert
                white-space: pre-line

    .footer
        background: $tab-fill
        border-top: solid thin $tab-border
        height: 45px

        .button
            line-height: 45px
            text-transform: uppercase

            .add-icon
                display: inline-flex
                padding-left: 20px
                padding-right: 5px
                position: relative
                top: -2px
                vertical-align: middle

            &:hover
                background: $main-blue
                cursor: pointer

            &.disabled
                &:hover
                    background: $tab-fill
                    pointer-events: none

    &__icon--red
        color: $alert-red
        font-size: 14px
        width: 15px

.confirm-modal-v2
    &__close-button
        height: 24px
        width: 24px

        &:hover
            box-shadow: 0 0 2px 0 $black-shade-50

