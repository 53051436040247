@import 'src/modules/shared/_colors.sass'

.customize-widget
    display: flex
    height: 100%
    margin-left: 30px
    padding: 10px 24px

    h3
        color: $grey-47
        font-size: 18px
        font-weight: 500
        margin-bottom: 16px
        margin-top: 0

    .widget-colors
        .color-picker-button
            &:hover
                border: 1px solid $main-blue

.customize-widget-2
    display: flex
    flex-direction: column
    height: 100%

    h3
        color: $grey-47
        font-size: 18px
        font-weight: 500
        margin-bottom: 16px
        margin-left: 0
        margin-top: 0

.customize-widget__reset-colors
    color: $text-blue
    line-height: 32px
    text-decoration: none

    &:hover
        opacity: 0.75
