@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'


%field
    font-size: 14px
    line-height: 20px
    padding: 10px

.referral-form
    .transcript-container
        padding: 15px 11px 10px

    .patient-info-container
        padding: 20px 0 0 40px

        .custom-field,
        .phone-custom-field
            margin-right: 16px

    .referral-review-flag-container
        padding-left: 0

    .referral-production-values-container
        padding: 0 0 40px 40px

    .referral-attachment-container
        padding: 10px 0 0 40px

    .button-container
        display: flex
        justify-content: center
        margin-bottom: 34px
        padding: 6px 16px

        button
            background-color: $main-blue
            border-radius: 4px
            color: $white
            height: 52px
            letter-spacing: normal
            min-width: 88px
            padding: 6px 16px

            &:disabled
                background-color: $light-gray

            &:hover
                background-color: $primary-hover-bg

    .add-patient-container
        margin: 0 8px 14px 42px

        .custom-field
            margin-right: 16px

    .add-patient
        +bg-strike($light-gray)
        color: $main-blue
        font-size: 15px
        margin-bottom: 24px
        margin-top: 5px

        .add-patient-button
            &:hover
                color: darken($main-blue, 10)
                cursor: pointer

            .person-add-icon
                font-size: 15px
                position: relative
                top: 2px

    .activity-log
        padding: 16px 10px 16px 40px

.referral-form-alerts__container
    height: 350px
    margin: 10px

.referral-form-alerts
    display: flex
    flex-direction: column
    height: 100%

.referral-form-alerts__header-container
    display: flex
    flex: 0
    flex-direction: column

.referral-form-alerts__header
    align-items: center
    background-color: $main-blue
    color: $white
    display: flex
    height: 44px
    padding: 0 1vh

.referral-form-alerts__body-container
    display: flex
    flex: 1 1
    flex-basis: 0
    flex-direction: column

.referral-form-survey__container
    height: 580px
    margin: 10px

    .survey
        height: 100%

