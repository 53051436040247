@import 'src/modules/shared/_colors.sass'


.file-upload-dropzone
    position: relative

.file-upload-drop-hover-element
    align-items: center
    background: $black
    border-color: $softer-gray
    border-style: dashed
    border-width: 2px
    bottom: -2px
    color: $white
    display: flex
    font-family: 'Roboto', sans-serif
    font-size: 36px
    justify-content: center
    left: 0
    margin: 0
    opacity: 0.2
    position: absolute
    right: 12px
    text-align: center
    top: -8px
    z-index: 1
