@import 'src/modules/shared/_colors.sass'

.location-logo-heading
    font-size: 18px
    font-weight: 500
    line-height: 26px
    margin-top: 20px

.location-logo
    display: flex

    .location-logo-wrapper
        display: flex
        flex-direction: column
        margin-right: 15px

        label
            color: $very-soft-black
            font-size: 14px
            margin-bottom: 5px
            text-align: center

        .custom-toggle-wrapper
            display: flex
            justify-content: center
            width: 240px

            .custom-toggle
                min-width: 75px

    .location-logo-info-wrapper
        margin-top: 16px

        .location-logo-info-text
            line-height: 1.6

        .location-logo-tooltip
            display: inline-block
            line-height: 1.24
            margin: 0
            padding: 0

            .custom-tooltip
                display: inline-block

            .custom-tooltip__popover--top-left
                transform: translateX(-74%)

            .custom-tooltip__children
                display: inline-block

            .tooltip-icon
                display: inline-block
                line-height: 1.24

        .location-logo-buttons
            margin-top: 16px

            button
                background: transparent
                border: 0
                color: $link-blue
                cursor: pointer
                font-size: 16px
                outline: 0

                span
                    vertical-align: middle

                .material-icons
                    font-size: 16px

                &:hover
                    opacity: 0.75

    .logo-error
        background: $error-red
        box-sizing: border-box
        color: $white
        margin-bottom: 10px
        padding: 3px
        text-align: center
        width: 100%


    .location-logo-display
        .location-logo-bg-top
            align-items: center
            border: 1px transparent solid
            box-sizing: border-box
            display: flex
            height: 145px
            justify-content: center
            margin-bottom: 16px
            margin-top: 10px
            width: 240px

            img
                height: auto
                max-height: 80%
                max-width: 80%
                width: auto

            &--dark
                background-color: $soft-black
                border-color: $soft-black

                h2
                    color: $white

            &--neutral
                background-color: $grey-66
                border-color: $grey-66

                h2
                    color: $white

            &--light
                background-color: $white
                border-color: $light-gray
