@import 'src/modules/shared/_colors.sass'

$module-name: survey-alert-container

.#{$module-name}
    align-items: center
    display: flex
    flex: 1
    height: 100%
    justify-content: center
    min-height: 100px

    &__alert
        align-items: flex-start
        background-color: $dark-gray
        color: $white
        display: flex
        justify-content: center
        max-width: 92%
        padding: 12px
        width: auto

    &__icon-red
        color: $error-red

    &__message
        margin-left: 3px
