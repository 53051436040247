// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'

$extra-large: 1400px
$large: 1200px
$medium: 992px
$small: 767px
$extra-small: 480px
$minimum: 1400px // not necessarily used
$outer-padding-width: 24px

$module-name: survey-procedures-tabs

.#{$module-name}
    position: relative

    &__header
        background: $main-blue
        color: $white
        display: block
        height: 41px
        left: 0
        line-height: 45px
        position: absolute
        right: 0
        top: 0
        z-index: 9999

    &__slide-btn
        background-color: $soft-black
        border: 0
        bottom: 0
        color: $white
        cursor: pointer
        height: 40px
        outline: 0
        overflow: hidden
        position: absolute
        top: -3px
        width: 39px
        z-index: 2

        span
            bottom: 0
            font-size: 32px
            left: 50%
            margin: auto
            position: absolute
            right: 0
            text-shadow: 0 0 $very-light-green
            top: 0
            transform: translateX(-50%)

        &--left
            border-radius: 0 10px 10px 0
            left: -#{$outer-padding-width}

            &:hover
                span
                    @media (min-width: $extra-small)
                        left: calc(50% - 5px)
                        text-shadow: 5px 0 $very-light-green


        &--right
            border-radius: 10px 0 0 10px
            right: -#{$outer-padding-width}

            span
                left: 54%

                &:hover
                    span
                        @media (min-width: $extra-small)
                            left: calc(54% + 5px)
                            text-shadow: -5px 0 $very-light-green


    &__tab-header
        background: $main-blue
        color: $white
        display: flex
        height: 46px
        line-height: 25px
        list-style: none
        margin-bottom: 0
        width: 100%

        div
            color: $white-shade-70
            cursor: pointer
            display: inline
            font-size: 16px
            letter-spacing: 0
            line-height: 30px
            overflow: hidden
            padding: 3px 12px
            white-space: nowrap

            &.active
                border-bottom: 4px solid $very-light-green
                color: $white
                height: 24px

            &:hover
                color: $white-shade-90

    &__tab-content
        background: $light-gray-blue-ting
        height: max-content
        position: relative
        width: 100%

    &__tile
        align-items: center
        background-color: $white
        border: 1px solid $grey-97
        border-radius: 6px
        color: $dark-gray
        display: flex
        font-size: 12px
        height: 51px
        justify-content: center
        line-height: 16px
        padding: 0 8px
        text-align: center

    &--clickable
        cursor: pointer

        &:hover
            background-color: $very-light-green
            color: $dark-gray !important
            font-weight: 400 !important

    &--selected
        background-color: $clear-green
        color: $white
        font-weight: bold

    & &__grid-item
        padding-bottom: 0 !important
        padding-right: 12px
