@import '../../shared/_colors.sass'

$module-name: 'enhanced-table-head'

.#{$module-name}
    & &__table-head
        height: 28px

    & &__table-cell
        border-bottom: 1px solid $light-gray
        color: $soft-gray
        font-size: 12px
        font-weight: 400
        padding: 4px 56px 4px 0
