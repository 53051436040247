@import 'src/modules/shared/_modals.sass'
@import 'src/modules/shared/_colors.sass'



.admin-item-manage-modal
    @extend %new-item-modal

.admin-list-row
    display: table-row

    .cell
        border-bottom: 1px solid $black-shade-14
        border-collapse: unset
        display: table-cell
        font-size: 1rem
        line-height: 1.2
        padding: 16px 0
        vertical-align: top

    .name,
    .username,
    .type,
    .permissions,
    .created
        padding-top: 24px

    .permission
        padding-bottom: 4px
