@mixin inline-center
    align-items: center
    display: inline-flex

@import 'src/modules/shared/_colors.sass'

$transparent-main-blue: rgba($main-blue, 0.5)

$module-name: picker-input

.#{$module-name}__container
    display: flex

.#{$module-name}
    border: 1px solid $input-border
    border-radius: 6px
    color: $soft-black
    cursor: pointer
    display: flex
    height: 38px
    position: relative
    width: 100%

    &:hover
        border-color: $main-blue

    &__icon
        @include inline-center
        position: absolute
        right: 10px
        top: 50%
        transform: translateY(-50%)

    &__text
        border: 0
        cursor: pointer
        font-size: 16px
        margin-left: 3px
        max-width: 70px
        outline: none
        padding-left: 8px

        &:disabled
            -webkit-text-fill-color: $soft-black // sass-lint:disable-line class-name-format no-vendor-prefixes
            background: $white
            color: $soft-black
            opacity: 1

    &--selected
        border-color: $main-blue

.picker
    display: inline-block
    position: relative
    width: 100%

    &__container
        position: absolute
        z-index: 100

    &__label
        color: $label-gray
        font-size: 12px
        margin: 5px

        &--selected
            color: $main-blue

    &.portal
        left: 50%
        position: fixed
        top: 50%
        transform: translateX(-50%) translateY(-50%)

.time-container
    align-items: center
    background: $white
    box-shadow: 0 0 5px 0 $transparent-main-blue
    display: block
    font-family: 'Roboto'
    font-size: 16px
    height: 112px
    width: 242px

    &__time-div
        background-color: $main-blue
        display: flex
        padding: 9px 14px 4px 12px

    &__colon
        color: $white
        font-size: 16px
        margin: 20px 4px 20px 2px

    &__type
        display: flex
        flex-direction: column
        margin-left: 10px

    &__buttons-div
        background-color: $white
        color: $dark-gray
        display: inline-flex
        justify-content: space-between
        padding: 9px 14px 12px 20px
        width: calc(100% - 34px)

        &__button-left
            cursor: pointer
            line-height: 21px
            text-align: left
            width: 86px

        &__button-right
            cursor: pointer
            line-height: 21px
            text-align: right
            width: 37px

    &__am-pm
        color: $white
        cursor: pointer
        font-size: 16px
        line-height: 24px
        margin: 5px 0 5px 20px

        &__button
            opacity: 0.5

        &--selected
            opacity: 1

.time-input
    background-color: $main-blue
    color: $white
    display: flex
    overflow: hidden
    width: 80px

    &__icon
        border: 0
        color: $white
        cursor: pointer
        height: 24px
        outline: none
        position: relative

    &--up,
    &--down
        top: 2px

    &__text
        box-sizing: border-box
        font-size: 48px
        text-align: right
        width: 100%



