@import 'src/modules/shared/_core.sass'
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_mixins.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: survey-operation-hours

.#{$module-name}
    padding-top: 48px

    &__switch
        width: 48px

    &__day
        width: 170px

    &__open
        width: 170px

    &__time-picker
        width: 170px

    .switch-cell
        padding-right: 8px

@media screen and (max-width: $small-width)
    .#{$module-name}
        display: block
