@import 'src/modules/shared/_colors.sass'


.referrals-dashboard-column
    margin: 8px
    width: calc(25% - 16px)

.referrals-dashboard-column-header
    display: flex
    justify-content: space-between

.referrals-dashboard-column-title
    display: flex
    font-size: 20px
    margin: 15px
    text-transform: capitalize

    strong
        margin-right: 2px

.referrals-dashboard-column-sort-load
    align-items: center
    display: flex
    font-size: 20px
    justify-content: center

    .referrals-dashboard-column-sort-load-button
        color: $main-blue
        letter-spacing: 0.5px
        padding: 6px 8px

.referrals-dashboard-column-body
    background-color: $header-gray
    min-height: 100vh
    padding: 1px 0 20px
