// sass-lint:disable no-vendor-prefixes
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'
@import 'src/modules/chat-center/shared/_constants.sass'

$indicator-height: 36px
$indicator-width: 150px

$module-name: new-message-indicator

.#{$module-name}
    bottom: 0
    height: calc(#{$indicator-height} + 16px)
    left: calc(50% - #{$indicator-width}/2 - 2px)
    overflow: hidden
    position: absolute
    width: calc(#{$indicator-width} + 4px)

    &-enter
        -moz-animation: slideIn 0.5s ease
        -webkit-animation: slideIn 0.5s ease forwards
        animation: slideIn 0.5s ease
        opacity: 0.5
        transition: opacity 500ms

    &-enter-done,
    &-exit
        opacity: 1
        transition: opacity 500ms

    &-exit-done,
    &-exit-active
        opacity: 0
        transition: opacity 500ms

    &__box
        align-items: center
        background-color: $soft-black
        border: 1px solid $field-border
        border-radius: 21.5px
        box-shadow: 0 0 4px 1px $black-shade-50
        box-sizing: border-box
        cursor: pointer
        display: flex
        height: $indicator-height
        justify-content: center
        margin: 2px
        position: absolute
        top: 0
        width: $indicator-width

    &__text
        color: $white
        font-family: Roboto
        font-size: 14px
        height: 19px
        line-height: 19px
        text-align: right

    &__arrow-downward-icon
        color: $white
        font-family: 'Material Icons'
        font-size: 16px
        font-style: normal
        height: 16px
        line-height: 16px
        text-align: right
        width: 16px

    @keyframes slideIn
        0%
            opacity: 0
            transform: translate(0, 16px)

        50%
            opacity: 0.5
            transform: translate(0, 8px)

        90%
            transform: translate(0, 0)

        100%
            opacity: 1
