// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_constants.sass'


$card-color: rgb(247, 247, 247)
$button-color: rgb(118, 214, 255)
$primary-tab-hover: darken($main-blue, 10)

$module-name: survey-component

.#{$module-name}
    display: flex
    flex: 2 1 auto
    flex-direction: column
    max-height: 100%
    min-height: 25%
    user-select: text

    &__new-content
        height: calc(100% - 100px)
        overflow-y: auto

    &__new-header
        background-color: $main-blue
        padding: 8px 16px 0 !important

    &__new-header-title
        color: $white

    &__header
        align-items: center
        background-color: $white
        color: $white
        display: flex
        flex-direction: row
        height: rem(44px)
        justify-content: space-between
        min-height: rem(44px)
        padding-bottom: 0
        padding-top: 0

    &__wrapped-header
        align-items: left
        background-color: $main-blue
        display: flex
        flex-direction: row
        height: rem(44px)
        justify-content: space-between
        padding-top: 8px

    &__search-bar
        width: 100%

        .custom__input
            border-right-color: $very-soft-gray
            height: 32px

        .custom-field__adornment
            background-color: $very-soft-gray
            border: 1px solid $input-border
            height: 34px
            width: 58px

        .custom-field__adornment-icon
            color: $label-gray

    &__content
        border: 20px
        flex-direction: column
        flex-wrap: nowrap
        margin: 0
        min-height: 150px
        overflow-y: auto
        width: auto

    &__qa-card
        align-items: flex-start
        background-color: $white
        display: flex
        justify-content: space-between
        padding: 4px 8px 0

        &--darker
            background-color: $grey-e8

    &__qa-content
        color: $lighter-black
        display: flex
        flex-direction: column
        font-size: 14px

    &__copy-button
        align-items: center
        border-radius: $default-border-radius
        display: flex
        justify-content: center
        min-height: 36px
        min-width: 36px

        &:hover
            background-color: $black-shade-8
            cursor: pointer

        &--disabled
            background-color: $softer-gray
            opacity: 0.5
            pointer-events: none

    &__custom-icon
        color: $lighter-black
        font-size: 16px

    ul
        list-style-type: none
        margin: 10px 0
        padding: 0

    li
        margin-bottom: 5px

    .custom-multiselect__container
        padding: rem(8px 11px)
        width: auto

    &__loading
        align-items: center
        display: flex
        flex: 1
        height: 100%
        justify-content: center

    &__message-red
        color: $error-red
        text-align: center
        width: 100%

        i
            font-size: 19px
            vertical-align: middle

    &__survey-switch-container

        .custom-multiselect__container
            display: flex
            flex: 1

    &__survey-switch
        padding: 8px 0 8px 8px
        width: 190px

        .buttongroup-selector
            &__button
                font-size: 15px
                letter-spacing: normal
                padding: 5px 6px
                text-transform: capitalize
                width: auto

                &:not(buttongroup-selector__button--disabled)
                    &:hover
                        background-color: $grey-e8

                &--disabled
                    color: $black-shade-26

                &--active
                    &:not(buttongroup-selector__button--disabled)
                        &:hover
                            background-color: $primary-tab-hover

    &__locations-dropdown
        flex: 1
