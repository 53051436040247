@import 'src/modules/shared/_modals.sass'
@import 'src/modules/shared/_colors.sass'


.practice-list
    display: flex
    flex-direction: column
    position: relative
    top: 32px

    .create-button
        position: absolute
        right: -5%
        top: -60px
        z-index: 1

    .practice-create-button
        background-color: $main-blue
        border-radius: 50%

    .icon
        color: $black

    .list
        flex: 1

    .paginator-wrapper
        display: flex
        justify-content: center
        margin-bottom: 100px

.practice-list-circular-progress-loader
    bottom: calc(50% - 50px)
    left: calc(50% - 50px)
    position: absolute

.practice-list-create-modal
    @extend %new-item-modal

