@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'


.clear-search
    bottom: 8px
    color: $soft-grey
    cursor: pointer
    position: absolute
    right: 8px

.custom-field__container
    display: flex
    position: relative
    width: 100%

.custom-field
    position: relative
    width: 100%

    .custom-tooltip
        position: absolute
        right: 0
        top: 18px

    .custom-tooltip__popover--top-right
        bottom: 13px
        transform: translateX(-28%)


    &:hover
        .custom__input,
        .custom__textarea,
        .custom__select
            background-color: $shortcuts-white
            border-color: $main-blue

        .custom-field__label
            color: $main-blue

    ::placeholder
        color: $label-gray
        opacity: 1


.custom__input,
.custom__textarea,
.custom__select
    background-color: $white
    border: 1px solid $input-border
    border-radius: $default-border-radius
    color: $soft-black
    display: flex
    font-size: 1rem
    outline: none

.custom__input
    height: 36px
    padding-left: 10px
    width: calc(100% - 10px)

.custom__input-error-icon
    color: $error-red
    position: absolute
    right: 5px
    top: 27px

.custom__select
    appearance: none
    height: 40px
    padding-left: 10px
    padding-right: 35px
    width: calc(100% + 3px)

.custom__select + .custom__dropdown-arrow
    float: right
    font-size: 38px
    margin-top: -40px
    pointer-events: none

.custom__textarea
    padding: 10px
    resize: none
    width: calc(100% - 20px)

.custom-field__label
    color: $label-gray
    font-size: 12px
    margin: 5px

.custom-field__helper-text
    color: $label-gray
    display: flex
    font-size: 12px
    margin: 5px

.custom-field--active
    .custom__input,
    .custom__textarea,
    .custom__select
        border-color: $main-blue

    .custom-field__label
        color: $main-blue

.custom-field--error
    .custom__input,
    .custom__textarea,
    .custom__select
        border-color: $error-red

    .custom-field__label,
    .custom-field__helper-text
        color: $error-red

.custom-field--disabled
    pointer-events: none

    .custom__input,
    .custom__textarea,
    .custom__select
        background-color: $white
        border-color: $very-soft-gray
        color: $label-gray

    .custom__dropdown-arrow
        color: $very-soft-gray

.custom-field__adornment
    align-items: center
    background-color: $main-blue
    display: flex
    height: 40px
    justify-content: center
    top: 0
    width: 53px

    &:hover
        cursor: default

.custom-field__adornment--right
    border-bottom-right-radius: 6px
    border-top-right-radius: 6px

.custom-field__adornment--left
    border-bottom-left-radius: 6px
    border-top-left-radius: 6px

.custom-field__adornment-icon
    color: $white

.with-adornment--left
    .custom__input,
    .custom__textarea
        border-radius: 0 6px 6px 0

.with-adornment--right
    .custom__input,
    .custom__textarea
        border-radius: 6px 0 0 6px
