@import 'src/modules/shared/_colors.sass'

$module-name: 'chat-list'

.#{$module-name}
    &__container
        align-items: stretch
        display: flex
        flex-direction: column
        height: 100%
        max-height: calc(100vh - 80px)

    &__unclaimed-section
        display: flex
        flex-direction: column
        height: 50%
        justify-content: flex-start
        overflow-x: hidden
