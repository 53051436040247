@import 'src/modules/shared/_colors.sass'


// TODO: change px to rem
.shortcut
    padding: 13px 29px 13px 19px

    &.active
        background-color: $shortcut-hover

    &:hover
        background-color: $shortcut-hover
        cursor: pointer

    span
        color: $text-blue

    p
        margin-bottom: 0
        margin-top: 2px
