@import 'src/modules/shared/_core.sass'
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_rem.scss'
@import 'src/modules/shared/_mixins.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: survey-doctors

.#{$module-name}
    &__first-name
        width: 247px

    &__last-name
        width: 247px

    &__suffix-name
        width: 247px

    &__suffix-name-optional
        font-weight: 100

    &__delete
        width: calc(100% - 494px)

        button
            &:hover
                opacity: 0.75

        .material-icons
            color: $link-blue

    &__add-wrapper
        padding: 16px 0 14px

    &__add
        color: $link-blue
        cursor: pointer
        font-size: 16px

        &:hover
            opacity: 0.75

    & .custom__input-error-icon
        top: 8px

    &__error
        color: $error-red
        font-size: 12px

@media screen and (max-width: $small-width)
    .#{$module-name}
        &__first-name,
        &__last-name
            max-width: 100%
            width: 50%

        & &__delete
            max-width: 16px
            padding: 0
