@import '../../shared/_colors.sass'
@import '../../shared/_mixins.sass'


$module-name: scheduling-search-patients-results

.#{$module-name}
    color: $lighter-black

    &__cards
        display: block
        margin-bottom: 16px
        max-height: 55vh
        overflow: auto

    &__patient
        border: 1px solid $input-border
        border-radius: 4px
        box-shadow: 0 1px 3px 0 $black-shade-16
        cursor: pointer
        display: grid
        grid-gap: 8px
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr))
        margin-bottom: 16px
        padding: 10px
        user-select: none

        &--selected
            background: $clear-green
            border-color: $clear-green
            box-shadow: none
            color: $white
            cursor: default

            .#{$module-name}__checkbox
                color: $white

    &__checkbox
        align-items: flex-start
        display: flex
        grid-column: span 1
        justify-content: center
        justify-self: center
        min-width: 60px
        width: 25%

        .material-icons
            font-size: 54px

    &__unchecked
        border: 2px solid $input-border
        border-radius: 50%
        display: block
        height: 48px
        width: 48px

    &__patient-data
        grid-column: span 2

    &__name
        font-size: 16px
        font-weight: bold
        margin-bottom: 6px

    &__details
        display: grid
        grid-gap: 5px 9px
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr))

    &__detail
        font-size: 16px

        label
            font-size: 12px

    &__footer
        display: grid
        grid-gap: 6px
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
        justify-content: space-between

    & &__back
        +secondary-button(97px)

    & &__next
        +primary-button(97px)

