@import 'src/modules/shared/_colors.sass'


.test-referral-type-selector
    .practice-title
        display: flex
        flex-direction: row
        padding: 50px 50px 0

        .title
            flex: 1 1 auto
            font-size: 24px

    .test-referral-type-selector__inner-container
        align-items: center
        display: flex
        flex-direction: column
        height: 50vh
        justify-content: center
        padding: 50px

        .title
            color: $lighter-black
            font-size: 20px
            text-align: center

        .test-referral-option-btn
            color: $lighter-black
            text-transform: none

            &:hover
                background-color: $main-blue
                color: $white

            .test-referral-option-btn__inner
                align-items: center
                display: flex
                flex-direction: column

                .img-container
                    display: flex
                    min-height: 58px

            .test-referral-option-label
                line-height: 1.4em

        .btn-container
            button
                border-color: $black-shade-24
                margin: 40px
                min-width: 120px
                padding: 8px 16px
