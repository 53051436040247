// sass-lint:disable no-important
@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$module-name: self-scheduling-web-code-card

.#{$module-name}
    background: $tab-fill-light
    border: 0
    border-top: 5px solid $primary-lime
    display: inline-block
    height: 250px
    margin: 6px
    position: relative
    width: 23.8%

    @media (max-width: $large-width)
        width: 23.6%

    @media (max-width: $medium-width)
        width: 47%


    &__wrapper
        display: flex
        flex-direction: column
        height: 180px

    &__header
        display: flex
        height: 60px
        margin-bottom: 0

    & &__switch
        color: $dark-gray
        margin-right: -10px
        z-index: 0

    & &__switch &__switch--checked
        color: $text-blue

    & &__card-content
        padding: 10px 14px 14px

    &__url
        color: $black-shade-38
        font-size: 20px
        overflow-wrap: break-word
        overflow-y: auto

        &--active
            color: $dark-gray

    &__container
        display: flex
        flex-direction: column
        max-height: 90%

    &__heading,
    &__ds-heading,
    &__web-code-heading
        align-items: center
        color: $lighter-black
        display: flex
        font-size: 14px
        font-weight: bold
        justify-content: space-between
        letter-spacing: 0
        margin: 4px 0

    &__web-code-heading
        margin: 12px 0 0

    &__text
        color: $dark-gray
        font-size: 14px
        font-weight: 500
        letter-spacing: 0
        text-transform: uppercase

        &:active
            color: $main-blue

    &__code
        color: $dark-gray
        font-size: 12px
        margin: 8px 0
        overflow: auto

    &__ds-code
        color: $dark-gray
        font-size: 12px
        margin: 8px 0
        word-wrap: break-word

    &__actions
        padding-right: 0

    &__buttons-space-between
        display: flex
        justify-content: space-between
        padding-left: 4px
        width: 100%

    &__web-code-key
        color: $lighter-black
        font-size: 13px
        line-height: 24px

    &--code-inactive
        border-top: 5px solid $primary-red
