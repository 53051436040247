@import 'src/modules/shared/_colors.sass'


.transcript
    .transcript-label
        color: $soft-black
        display: block
        font-size: 18px
        margin-bottom: 3px

    .transcript-edit-icon
        color: $main-blue
        cursor: pointer
        font-size: 20px
        padding-left: 4px
        position: absolute

        &:hover
            color: $main-blue-hover

    .custom-field
        margin-right: 0

        .textarea
            border-radius: 0

    .text
        max-height: 900px
        overflow-wrap: break-word
        overflow-x: hidden
        overflow-y: auto

    &--chatter
        color: $main-blue

    &--patient
        color: $green-2a
