@import 'src/modules/shared/_colors.sass'

.production-values

    &__title
        color: $soft-black
        font-size: 24px
        font-weight: 500
        margin-bottom: 8px

    &__table

        .table-item
            font-size: 16px

        .table-header-item
            font-size: 14px
            font-weight: 500

        .table-first-item
            padding-left: 0

        .table-header-row
            border-bottom: 1px solid $border-gray
