@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_mixins.sass'

$module-name: self-scheduling-web-code-location

.#{$module-name}
    align-items: center
    border-bottom: 1px solid $input-border
    display: flex
    justify-content: space-between
    margin-left: 2px

    &__location-section
        align-items: center
        display: flex
        width: calc(100% - 120px)

    &__rsvp-section
        align-items: center
        display: flex
        padding-right: 18px

        .custom-tooltip-with-portal
            height: 21px
            margin-right: 10px
            width: 21px

    &__rsvp-all-section
        align-items: center
        display: flex

        .custom-tooltip
            height: 21px
            margin-right: 10px
            width: 21px

    & &__rsvp-all-text
        width: 91px

    & &__form-control-root
        .#{$module-name}__checkbox-root
            padding-top: 12px

    & &__form-control-label
        +long-word-break
        padding-top: 1px

    & &__switch
        margin-right: -14px

    & &__rsvp-info-icon
        color: $text-blue
        cursor: pointer
        font-size: 21px
        vertical-align: middle
        z-index: 5

    &__label
        padding-top: 4px

        &--checkbox
            margin-left: -18px

    &__text
        color: $dark-gray
        margin: 0 2px
        white-space: normal

    &--text-bold
        font-weight: bold



