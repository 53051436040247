@import 'src/modules/shared/_colors.sass'


.confirmation-container
    background-color: $white
    height: 100%
    text-align: center
    width: 100%

    .confirmation-inner-container
        display: inline-block

        .title

            .text
                color: $grey-47
                font-size: 24px
                font-weight: bold
                line-height: 32px
                padding-top: 2px
                width: calc(100% - 32px)

            .material-icons
                font-size: 50px
                padding-right: 15px
                vertical-align: middle

                &.red
                    color: $error-red

                &.green
                    color: $primary-lime

        .buttons-container
            padding-top: 30px

            .confirm-btn
                &.red
                    background-color: $alert-red
                    border: 1px solid $alert-red
                    box-shadow: none
                    color: $white
                    font-size: 16px
                    font-weight: 500
                    height: 36px
                    padding: 6px 21px

                    &:hover
                        background-color: $alert-red

        .staff-member-name
            white-space: nowrap

    .circular-progress-loader
        left: calc(50% - 25px)
        position: absolute
        top: calc(50% - 25px)
