@import 'src/modules/dexvoice/_common.sass'
@import 'src/modules/shared/_widget.sass'
@import 'src/modules/shared/_colors.sass'


.dexvoice-commands
    @extend %metric-card
    flex: 2
    padding: 14px 16px
    position: relative

    .count
        font-size: 24px
        margin: 14px 0

    .details
        bottom: 0
        position: absolute
        right: 0

        .link
            color: $base-blue
            font-weight: bold
            text-align: right

    .range
        color: $black-shade-70
        font-size: 14px

    .title
        color: $black-shade-87
        font-size: 24px
        margin-bottom: 8px
